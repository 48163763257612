export default {
  US: {
    AL: '阿拉巴马',
    AK: '阿拉斯加州',
    AS: '美属萨摩亚',
    AZ: '亚利桑那',
    AR: '阿肯色州',
    AA: '美国武装部队',
    AE: '欧洲武装力量',
    AP: '太平洋武装部队',
    CA: '加利福尼亚',
    CO: '科罗拉多',
    CT: '康涅狄格',
    DE: '特拉华州',
    DC: '哥伦比亚特区',
    FM: '密克罗尼西亚联邦',
    FL: '佛罗里达',
    GA: '乔治亚州',
    GU: '关岛',
    HI: '夏威夷',
    ID: '爱达荷州',
    IL: '伊利诺伊州',
    IN: '印第安纳',
    IA: '爱荷华',
    KS: '堪萨斯',
    KY: '肯塔基',
    LA: '路易斯安那',
    ME: '缅因州',
    MH: '马绍尔群岛',
    MD: '马里兰',
    MA: '马萨诸塞州',
    MI: '密歇根',
    MN: '明尼苏达',
    MS: '密西西比',
    MO: '密苏里',
    MT: '蒙大拿',
    NE: '内布拉斯加州',
    NV: '内华达',
    NH: '新罕布什尔',
    NJ: '新泽西州',
    NM: '新墨西哥',
    NY: '纽约',
    NC: '北卡罗来纳',
    ND: '北达科他州',
    MP: '北马里亚纳群岛',
    OH: '俄亥俄',
    OK: '俄克拉荷马',
    OR: '俄勒冈',
    PW: '帕劳',
    PA: '宾夕法尼亚州',
    PR: '波多黎各',
    RI: '罗德岛',
    SC: '南卡罗来纳',
    SD: '南达科他州',
    TN: '田纳西州',
    TX: '德克萨斯',
    UM: '美国本土外小岛屿',
    UT: '犹他州',
    VT: '佛蒙特',
    VI: '维尔京群岛',
    VA: '弗吉尼亚',
    WA: '华盛顿',
    WV: '西弗吉尼亚',
    WI: '威斯康星',
    WY: '怀俄明',
  },
  CA: {
    AB: '阿尔伯塔',
    BC: '不列颠哥伦比亚省',
    MB: '马尼托巴',
    NB: '新不伦瑞克',
    NL: '纽芬兰',
    NT: '西北地区',
    NS: '新斯科舍省',
    NU: '努纳武特',
    ON: '安大略省',
    PE: '爱德华王子岛',
    QC: '魁北克',
    SK: '萨斯喀彻温',
    YT: '育空',
  },
  CN: {
    AH: '安徽',
    BJ: '北京',
    CQ: '重庆',
    FJ: '福建',
    GS: '甘肃',
    GD: '广东',
    GX: '广西',
    GZ: '贵州',
    HI: '海南',
    HE: '河北',
    HL: '黑龙江',
    HA: '河南',
    HK: '中国香港',
    HB: '湖北',
    HN: '湖南',
    JS: '江苏',
    JX: '江西',
    JL: '吉林',
    LN: '辽宁',
    MO: '中国澳门',
    NM: '内蒙古',
    NX: '宁夏',
    QH: '青海',
    SN: '陕西',
    SD: '山东',
    SH: '上海',
    SX: '山西',
    SC: '四川',
    TW: '中国台湾',
    TJ: '天津',
    XJ: '新疆',
    XZ: '西藏',
    YN: '云南',
    ZJ: '浙江',
  },
};
