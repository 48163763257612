export default {
  AD: '安道尔',
  AF: '阿富汗',
  EG: '埃及',
  EH: '西撒哈拉',
  FO: '法罗群岛',
  GA: '加蓬',
  GD: '格林纳达',
  GI: '直布罗陀',
  GM: '冈比亚',
  AG: '安提瓜和巴布达',
  AI: '安圭拉',
  AL: '阿尔巴尼亚',
  AN: '荷属安的列斯群岛',
  AO: '安哥拉',
  AQ: '南极洲',
  AR: '阿根廷',
  AU: '澳大利亚',
  AW: '阿鲁巴',
  AZ: '阿塞拜疆',
  BA: '波斯尼亚和黑塞哥维那',
  BB: '巴巴多斯',
  BF: '布基纳法索',
  BG: '保加利亚',
  BH: '巴林',
  BI: '布隆迪',
  BJ: '贝宁',
  BM: '百慕大',
  BS: '巴哈马',
  BV: '布维岛',
  BW: '博茨瓦纳',
  BZ: '伯利兹',
  CA: '加拿大',
  CF: '中非',
  CG: '刚果',
  CI: '科特迪瓦',
  CK: '库克群岛',
  CN: '中国',
  CU: '古巴',
  CV: '佛得角',
  DE: '德国',
  EC: '厄瓜多尔',
  GR: '希腊',
  IR: '伊朗',
  IT: '意大利',
  JM: '牙买加',
  JO: '约旦',
  JP: '日本',
  KE: '肯尼亚',
  KM: '科摩罗',
  KP: '朝鲜',
  KR: '韩国',
  LB: '黎巴嫩',
  LS: '莱索托',
  LU: '卢森堡',
  LV: '拉脱维亚',
  MD: '摩尔多瓦',
  GU: '关岛',
  HK: '中国香港',
  HM: '赫德群岛和麦克唐纳群岛',
  HT: '海地',
  MK: '马其顿',
  ML: '马里',
  MN: '蒙古',
  HU: '匈牙利',
  ID: '印度尼西亚',
  IE: '爱尔兰',
  IL: '以色列',
  IN: '印度',
  IQ: '伊拉克',
  MT: '马耳他',
  MV: '马尔代夫',
  MX: '墨西哥',
  NC: '新喀里多尼亚',
  NI: '尼加拉瓜',
  NZ: '新西兰',
  PE: '秘鲁',
  PL: '波兰',
  PT: '葡萄牙',
  PW: '帕劳',
  CR: '哥斯达黎加',
  CZ: '捷克共和国',
  DZ: '阿尔及利亚',
  ET: '埃塞俄比亚',
  FR: '法国',
  RO: '罗马尼亚',
  RU: '俄罗斯',
  SA: '沙特阿拉伯',
  SB: '所罗门群岛',
  SC: '塞舌尔',
  SD: '苏丹',
  SE: '瑞典',
  SG: '新加坡',
  SI: '斯洛文尼亚',
  SJ: '斯瓦尔巴群岛和扬马延群岛',
  SK: '斯洛伐克',
  SL: '塞拉利昂',
  SN: '塞内加尔',
  SO: '索马里',
  SR: '苏里南',
  ST: '圣多美和普林西比',
  SV: '萨尔瓦多',
  SZ: '斯威士兰',
  TC: '特克斯和凯科斯群岛',
  TD: '乍得',
  TF: '法属南部领地',
  TG: '多哥',
  TH: '泰国',
  TK: '托克劳',
  TM: '土库曼斯坦',
  TN: '突尼斯',
  TP: '东帝汶',
  TT: '特立尼达和多巴哥',
  UA: '乌克兰',
  US: '美国',
  UZ: '乌兹别克斯坦',
  VA: '梵蒂冈',
  VE: '委内瑞拉',
  VG: '英属维尔京群岛',
  VU: '瓦努阿图',
  YE: '也门',
  YT: '马约特',
  YU: '南斯拉夫',
  ZA: '南非',
  ZW: '津巴布韦',
  GB: '英国',
  BO: '玻利维亚',
  BT: '不丹',
  BY: '白俄罗斯',
  CC: '科科斯（基林）群岛',
  CL: '智利',
  CO: '哥伦比亚',
  TW: '中国台湾',
  CY: '塞浦路斯',
  DK: '丹麦',
  DO: '多米尼加共和国',
  ES: '西班牙',
  FI: '芬兰',
  FK: '马尔维纳斯群岛（福克兰群岛）',
  GE: '格鲁吉亚',
  GL: '格陵兰',
  GN: '几内亚',
  GS: '南乔治亚岛和南桑威奇群岛',
  KW: '科威特',
  KZ: '哈萨克斯坦',
  LT: '立陶宛',
  LY: '利比亚',
  MH: '马绍尔群岛',
  MO: '中国澳门',
  MU: '毛里求斯',
  MY: '马来西亚',
  NA: '纳米比亚',
  NU: '纽埃',
  OM: '阿曼',
  PK: '巴基斯坦',
  PR: '波多黎各',
  AT: '奥地利',
  BD: '孟加拉国',
  CH: '瑞士',
  CS: '圣诞岛',
  FM: '密克罗尼西亚',
  IO: '英属印度洋领地',
  KI: '基里巴斯',
  LC: '圣卢西亚',
  MA: '摩洛哥',
  MM: '缅甸',
  MS: '蒙特塞拉特',
  NG: '尼日利亚',
  PH: '菲律宾',
  RE: '留尼汪',
  WS: '西萨摩亚',
  AE: '阿拉伯联合酋长国',
  AS: '美属萨摩亚',
  BE: '比利时',
  BN: '文莱达鲁萨兰国',
  CM: '喀麦隆',
  DJ: '吉布提',
  ER: '厄立特里亚',
  GF: '法属圭亚那',
  GW: '几内亚比绍',
  HR: '克罗地亚',
  IS: '冰岛',
  KH: '柬埔寨',
  KY: '开曼群岛',
  LR: '利比里亚',
  MQ: '马提尼克岛',
  MZ: '莫桑比克',
  PA: '巴拿马',
  PN: '皮特凯恩群岛集团',
  RW: '卢旺达',
  SH: '圣赫勒拿',
  SM: '圣马里昂',
  SY: '叙利亚',
  TJ: '塔吉克斯坦',
  TZ: '坦桑尼亚',
  EE: '爱沙尼亚',
  LI: '列支敦士登',
  NF: '诺福克岛',
  PY: '巴拉圭',
  GP: '瓜德罗普',
  UM: '美国杂项委员会',
  NP: '尼泊尔',
  PM: '圣皮埃尔和密克隆',
  GQ: '赤道几内亚',
  GY: '圭亚那',
  KG: '吉尔吉斯斯坦',
  KN: '圣基茨和尼维斯',
  LA: '老挝',
  LK: '斯里兰卡',
  MC: '摩纳哥',
  MG: '马达加斯加',
  MR: '毛里塔尼亚',
  MW: '马拉维',
  NE: '尼日尔',
  NO: '挪威',
  NR: '瑙鲁',
  PG: '巴布亚新几内亚',
  PS: '巴勒斯坦',
  QA: '卡塔尔',
  RS: '塞尔维亚',
  MP: '北马里亚纳群岛',
  NL: '荷兰',
  PF: '法属波利尼西亚',
  AM: '亚美尼亚',
  BR: '巴西',
  DM: '多米尼克',
  HN: '洪都拉斯',
  TO: '汤加',
  TR: '土耳其',
  TV: '图瓦卢',
  UG: '乌干达',
  UY: '乌拉圭',
  VC: '圣文森特和格林纳丁斯',
  VI: '美属维尔京群岛',
  WF: '瓦利斯和富图纳群岛',
  ZM: '赞比亚',
  FJ: '斐济',
  GH: '加纳',
  GT: '危地马拉',
  VN: '越南',
  ZR: '扎伊尔',
};
