export default {
  US: {
    AL: '阿拉巴馬',
    AK: '阿拉斯加州',
    AS: '美屬薩摩亞',
    AZ: '亞利桑那',
    AR: '阿肯色州',
    AA: '美國武裝部隊',
    AE: '歐洲武裝力量',
    AP: '太平洋武裝部隊',
    CA: '加利福尼亞',
    CO: '科羅拉多',
    CT: '康涅狄格',
    DE: '特拉華州',
    DC: '哥倫比亞特區',
    FM: '密克羅尼西亞聯邦',
    FL: '佛羅里達',
    GA: '喬治亞州',
    GU: '關島',
    HI: '夏威夷',
    ID: '愛達荷州',
    IL: '伊利諾伊州',
    IN: '印第安納',
    IA: '愛荷華',
    KS: '堪薩斯',
    KY: '肯塔基',
    LA: '路易斯安那',
    ME: '緬因州',
    MH: '馬紹爾群島',
    MD: '馬里蘭',
    MA: '馬薩諸塞州',
    MI: '密歇根',
    MN: '明尼蘇達',
    MS: '密西西比',
    MO: '密蘇里',
    MT: '蒙大拿',
    NE: '內布拉斯加州',
    NV: '內華達',
    NH: '新罕布什爾',
    NJ: '新澤西州',
    NM: '新墨西哥',
    NY: '紐約',
    NC: '北卡羅來納',
    ND: '北達科他州',
    MP: '北馬里亞納群島',
    OH: '俄亥俄',
    OK: '俄克拉荷馬',
    OR: '俄勒岡',
    PW: '帕勞',
    PA: '賓夕法尼亞州',
    PR: '波多黎各',
    RI: '羅德島',
    SC: '南卡羅來納',
    SD: '南達科他州',
    TN: '田納西州',
    TX: '德克薩斯',
    UM: '美國本土外小島嶼',
    UT: '猶他州',
    VT: '佛蒙特',
    VI: '維爾京群島',
    VA: '弗吉尼亞',
    WA: '華盛頓',
    WV: '西弗吉尼亞',
    WI: '威斯康星',
    WY: '懷俄明',
  },
  CA: {
    AB: '阿爾伯塔',
    BC: '不列顛哥倫比亞省',
    MB: '馬尼托巴',
    NB: '新不倫瑞克',
    NL: '紐芬蘭',
    NT: '西北地區',
    NS: '新斯科舍省',
    NU: '努納武特',
    ON: '安大略省',
    PE: '愛德華王子島',
    QC: '魁北克',
    SK: '薩斯喀徹溫',
    YT: '育空',
  },
  CN: {
    AH: '安徽',
    BJ: '北京',
    CQ: '重慶',
    FJ: '福建',
    GS: '甘肅',
    GD: '廣東',
    GX: '廣西',
    GZ: '貴州',
    HI: '海南',
    HE: '河北',
    HL: '黑龍江',
    HA: '河南',
    HK: '中國香港',
    HB: '湖北',
    HN: '湖南',
    JS: '江蘇',
    JX: '江西',
    JL: '吉林',
    LN: '遼寧',
    MO: '中國澳門',
    NM: '內蒙古',
    NX: '寧夏',
    QH: '青海',
    SN: '陝西',
    SD: '山東',
    SH: '上海',
    SX: '山西',
    SC: '四川',
    TW: '中國臺灣',
    TJ: '天津',
    XJ: '新疆',
    XZ: '西藏',
    YN: '雲南',
    ZJ: '浙江',
  },
};
