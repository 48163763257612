export default {
  US: {
    AL: 'アラバマ',
    AK: 'アラスカ',
    AS: '米領サモア',
    AZ: 'アリゾナ',
    AR: 'アーカンソー',
    AA: '軍隊アメリカ大陸',
    AE: '軍隊ヨーロッパ',
    AP: '軍隊太平洋',
    CA: 'カリフォルニア',
    CO: 'コロラド',
    CT: 'コネチカット',
    DE: 'デラウェア',
    DC: 'コロンビア特別区',
    FM: 'ミクロネシア連邦',
    FL: 'フロリダ',
    GA: 'ジョージア',
    GU: 'グアム',
    HI: 'ハワイ',
    ID: 'アイダホ',
    IL: 'イリノイ',
    IN: 'インディアナ',
    IA: 'アイオワ',
    KS: 'カンザス',
    KY: 'ケンタッキー',
    LA: 'ルイジアナ',
    ME: 'メイン',
    MH: 'マーシャル諸島',
    MD: 'メリーランド',
    MA: 'マサチューセッツ',
    MI: 'ミシガン',
    MN: 'ミネソタ',
    MS: 'ミシシッピ',
    MO: 'ミズーリ',
    MT: 'モンタナ',
    NE: 'ネブラスカ',
    NV: 'ネバダ',
    NH: 'ニューハンプシャー',
    NJ: 'ニュージャージー',
    NM: 'ニューメキシコ',
    NY: 'ニューヨーク',
    NC: 'ノースカロライナ州',
    ND: 'ノースダコタ',
    MP: '北マリアナ諸島',
    OH: 'オハイオ',
    OK: 'オクラホマ',
    OR: 'オレゴン',
    PW: 'パラオ',
    PA: 'ペンシルベニア',
    PR: 'プエルトリコ',
    RI: 'ロードアイランド',
    SC: 'サウスカロライナ',
    SD: 'サウス・ダコタ',
    TN: 'テネシー',
    TX: 'テキサス',
    UM: '米国小離島',
    UT: 'ユタ',
    VT: 'バーモント',
    VI: 'ヴァージン諸島',
    VA: 'バージニア',
    WA: 'ワシントン',
    WV: 'ウェストバージニア',
    WI: 'ウィスコンシン',
    WY: 'ワイオミング',
  },
  CA: {
    AB: 'アルバータ',
    BC: 'ブリティッシュコロンビア',
    MB: 'マニトバ',
    NB: 'ニューブランズウィック',
    NL: 'ニューファンドランド',
    NT: 'ノースウェスト準州',
    NS: 'ノバスコシア',
    NU: 'ヌナブト準州',
    ON: 'オンタリオ',
    PE: 'プリンス・エドワード島',
    QC: 'ケベック',
    SK: 'サスカチュワン',
    YT: 'ユーコン',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
