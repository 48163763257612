export default {
  AD: 'Andorra',
  AF: 'Afghanistan',
  EG: 'Egypten',
  EH: 'Västra Sahara',
  FO: 'Färöarna',
  GA: 'Gabon',
  GD: 'Grenada',
  GI: 'Gibraltar',
  GM: 'Gambia',
  AG: 'Antigua och Barbuda',
  AI: 'Anguilla',
  AL: 'Albanien',
  AN: 'Nederländska Antillerna',
  AO: 'Angola',
  AQ: 'Antarktis',
  AR: 'Argentina',
  AU: 'Australien',
  AW: 'Aruba',
  AZ: 'Azerbajdzjan',
  BA: 'Bosnien och Hercegovina',
  BB: 'Barbados',
  BF: 'Burkina Faso',
  BG: 'Bulgarien',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BS: 'Bahamas',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BZ: 'Belize',
  CA: 'Kanada',
  CF: 'Centralafrika',
  CG: 'Kongo',
  CI: 'Cote d\'Ivoire',
  CK: 'Cooköarna',
  CN: 'Kina',
  CU: 'Kuba',
  CV: 'Cap Verde',
  DE: 'Tyskland',
  EC: 'Ecuador',
  GR: 'Grekland',
  IR: 'Iran',
  IT: 'Italien',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KM: 'Komorerna',
  KP: 'Nordkorea',
  KR: 'Sydkorea',
  LB: 'Libanon',
  LS: 'Lesotho',
  LU: 'Luxemburg',
  LV: 'Lettland',
  MD: 'Moldova',
  GU: 'Guam',
  HK: 'Hong Kong',
  HM: 'Heard Islands och Mc Donald Islands',
  HT: 'Haiti',
  MK: 'Makedonien',
  ML: 'Mali',
  MN: 'Mongoliet',
  HU: 'Ungern',
  ID: 'Indonesien',
  IE: 'Irland',
  IL: 'Israel',
  IN: 'Indien',
  IQ: 'Irak',
  MT: 'Malta',
  MV: 'Maldiverna',
  MX: 'Mexiko',
  NC: 'Nya Kaledonien',
  NI: 'Nicaragua',
  NZ: 'Nya Zeeland',
  PE: 'Peru',
  PL: 'Polen',
  PT: 'Portugal',
  PW: 'Palau',
  CR: 'Costa Rica',
  CZ: 'Tjeckien',
  DZ: 'Algeriet',
  ET: 'Etiopien',
  FR: 'Frankrike',
  RO: 'Rumänien',
  RU: 'Ryssland',
  SA: 'Saudiarabien',
  SB: 'Salomonöarna',
  SC: 'Seychellerna',
  SD: 'Sudan',
  SE: 'Sverige',
  SG: 'Singapore',
  SI: 'Slovenien',
  SJ: 'Svalbard och Jan Mayen Islands',
  SK: 'Slovakien',
  SL: 'Sierra Leone',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Surinam',
  ST: 'Sao Tomé och Principe',
  SV: 'El Salvador',
  SZ: 'Swaziland',
  TC: 'Turks- och Caicosöarna',
  TD: 'Tchad',
  TF: 'Franska sydterritorierna',
  TG: 'Togo',
  TH: 'Thailand',
  TK: 'Tokelau',
  TM: 'Turkmenistan',
  TN: 'Tunisien',
  TP: 'Östtimor',
  TT: 'Trinidad och Tobago',
  UA: 'Ukraina',
  US: 'Förenta staterna',
  UZ: 'Uzbekistan',
  VA: 'Vatikanen',
  VE: 'Venezuela',
  VG: 'Brittiska Jungfruöarna',
  VU: 'Vanuatu',
  YE: 'Jemen',
  YT: 'Mayotte',
  YU: 'Jugoslavien',
  ZA: 'Sydafrika',
  ZW: 'Zimbabwe',
  GB: 'Storbritannien',
  BO: 'Bolivia',
  BT: 'Bhutan',
  BY: 'Belarus',
  CC: 'Cocos(Keeling) Islands',
  CL: 'Chile',
  CO: 'Colombia',
  TW: 'Taiwan, Kina',
  CY: 'Cypern',
  DK: 'Danmark',
  DO: 'Dominikanska republiken',
  ES: 'Spanien',
  FI: 'Finland',
  FK: 'Malvinas öar (Falklandsöarna)',
  GE: 'Georgien',
  GL: 'Grönland',
  GN: 'Guinea',
  GS: 'Sydgeorgien och södra Sandwichöarna',
  KW: 'Kuwait',
  KZ: 'Kazakstan',
  LT: 'Litauen',
  LY: 'Libyen',
  MH: 'Marshallöarna',
  MO: 'Macau',
  MU: 'Mauritius',
  MY: 'Malaysia',
  NA: 'Namibia',
  NU: 'Niue',
  OM: 'Oman',
  PK: 'Pakistan',
  PR: 'Puerto Rico',
  AT: 'Österrike',
  BD: 'Bangladesh',
  CH: 'Schweiz',
  CS: 'Julön',
  FM: 'Micronesien',
  IO: 'Brittiska territoriet i Indiska oceanen',
  KI: 'Kiribati',
  LC: 'Saint Lucia',
  MA: 'Marocko',
  MM: 'Myanmar',
  MS: 'Montserrat',
  NG: 'Nigeria',
  PH: 'Filippinerna',
  RE: 'Återförening',
  WS: 'Västra Samoa',
  AE: 'Förenade arabemiraten',
  AS: 'Amerikanska Samoa',
  BE: 'Belgien',
  BN: 'Brunei Darussalam',
  CM: 'Kamerun',
  DJ: 'Djibouti',
  ER: 'Eritrea',
  GF: 'Franska Guyana',
  GW: 'Guine-bissau',
  HR: 'Kroatien',
  IS: 'Island',
  KH: 'Kambodja',
  KY: 'Caymanöarna',
  LR: 'Liberia',
  MQ: 'Martinique',
  MZ: 'Moçambique',
  PA: 'Panama',
  PN: 'Pitcairn Islands Group',
  RW: 'Rwanda',
  SH: 'Saint Helena',
  SM: 'San Marion',
  SY: 'Syrien',
  TJ: 'Tadzjikistan',
  TZ: 'Tanzania',
  EE: 'Estland',
  LI: 'Liechtenstein',
  NF: 'Norfolk Island',
  PY: 'Paraguay',
  GP: 'Guadeloupe',
  UM: 'United States diverse Pac',
  NP: 'Nepal',
  PM: 'Saint Pierre och Miquelon',
  GQ: 'Ekvatorialguinea',
  GY: 'Guyana',
  KG: 'Kirgizistan',
  KN: 'Saint Kitts och Nevis',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Monaco',
  MG: 'Madagaskar',
  MR: 'Mauretanien',
  MW: 'Malawi',
  NE: 'Niger',
  NO: 'Norge',
  NR: 'Nauru',
  PG: 'Papua Nya Guinea',
  PS: 'Palestina',
  QA: 'Qatar',
  RS: 'Serbien',
  MP: 'Norra Marianer',
  NL: 'Nederländerna',
  PF: 'Franska Polynesien',
  AM: 'Armenien',
  BR: 'Brasilien',
  DM: 'Dominica',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Kalkon',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UY: 'Uruguay',
  VC: 'Saint Vincent och Grenadinerna',
  VI: 'USA:s Jungfruöar',
  WF: 'Wallis och Futuna Islands',
  ZM: 'Zambia',
  FJ: 'Fiji',
  GH: 'Ghana',
  GT: 'Guatemala',
  VN: 'Viet Nam',
  ZR: 'Zaire',
};
