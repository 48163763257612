export default {
  AD: 'アンドラ',
  AF: 'アフガニスタン',
  EG: 'エジプト',
  EH: '西サハラ',
  FO: 'フェロー諸島',
  GA: 'ガボン',
  GD: 'グレナダ',
  GI: 'ジブラルタル',
  GM: 'ガンビア',
  AG: 'アンティグアバーブーダ',
  AI: 'アンギラ',
  AL: 'アルバニア',
  AN: 'オランダ領アンティル',
  AO: 'アンゴラ',
  AQ: '南極大陸',
  AR: 'アルゼンチン',
  AU: 'オーストラリア',
  AW: 'アルバ',
  AZ: 'アゼルバイジャン',
  BA: 'ボスニア・ヘルツェゴビナ',
  BB: 'バルバドス',
  BF: 'ブルキナファソ',
  BG: 'ブルガリア',
  BH: 'バーレーン',
  BI: 'ブルンジ',
  BJ: 'ベニン',
  BM: 'バミューダ',
  BS: 'バハマ',
  BV: 'ブーベット島',
  BW: 'ボツワナ',
  BZ: 'ベリーズ',
  CA: 'カナダ',
  CF: '中央アフリカ',
  CG: 'コンゴ',
  CI: 'コートジボワール',
  CK: 'クック諸島',
  CN: '中国',
  CU: 'キューバ',
  CV: 'カーボベルデ',
  DE: 'ドイツ',
  EC: 'エクアドル',
  GR: 'ギリシャ',
  IR: 'イラン',
  IT: 'イタリア',
  JM: 'ジャマイカ',
  JO: 'ヨルダン',
  JP: '日本',
  KE: 'ケニア',
  KM: 'コモロ',
  KP: '北朝鮮',
  KR: '韓国',
  LB: 'レバノン',
  LS: 'レソト',
  LU: 'ルクセンブルク',
  LV: 'ラトビア',
  MD: 'モルドバ',
  GU: 'グアム',
  HK: '香港',
  HM: 'ハード島とマクドナルド諸島',
  HT: 'ハイチ',
  MK: 'マケドニア',
  ML: 'マリ',
  MN: 'モンゴル',
  HU: 'ハンガリー',
  ID: 'インドネシア',
  IE: 'アイルランド',
  IL: 'イスラエル',
  IN: 'インド',
  IQ: 'イラク',
  MT: 'マルタ',
  MV: 'モルディブ',
  MX: 'メキシコ',
  NC: 'ニューカレドニア',
  NI: 'ニカラグア',
  NZ: 'ニュージーランド',
  PE: 'ペルー',
  PL: 'ポーランド',
  PT: 'ポルトガル',
  PW: 'パラオ',
  CR: 'コスタリカ',
  CZ: 'Czech Repoublic',
  DZ: 'アルジェリア',
  ET: 'エチオピア',
  FR: 'フランス',
  RO: 'ルーマニア',
  RU: 'ロシア',
  SA: 'サウジアラビア',
  SB: 'ソロモン諸島',
  SC: 'セイチェルズ',
  SD: 'スーダン',
  SE: 'スウェーデン',
  SG: 'シンガポール',
  SI: 'スロベニア',
  SJ: 'スバールバル諸島およびジャンマイエン諸島',
  SK: 'スロバキア',
  SL: 'シエラレオネ',
  SN: 'セネガル',
  SO: 'ソマリア',
  SR: 'スリナム',
  ST: 'サントメ・プリンシペ',
  SV: 'エルサルバドル',
  SZ: 'スワジランド',
  TC: 'タークス・カイコス諸島',
  TD: 'チャド',
  TF: 'フランス領南方南方領土',
  TG: 'トーゴ',
  TH: 'タイ',
  TK: 'トケラウ',
  TM: 'トルクメニスタン',
  TN: 'チュニジア',
  TP: '東ティモール',
  TT: 'トリニダード・トバゴ',
  UA: 'ウクライナ',
  US: 'アメリカ',
  UZ: 'ウズベキスタン',
  VA: 'バチカン',
  VE: 'ベネズエラ',
  VG: 'イギリス領ヴァージン諸島',
  VU: 'バヌアツ',
  YE: 'イエメン',
  YT: 'マヨット',
  YU: 'ユーゴスラビア',
  ZA: '南アフリカ',
  ZW: 'ジンバブエ',
  GB: 'イギリス',
  BO: 'ボリビア',
  BT: 'ブータン',
  BY: 'ベラルーシ',
  CC: 'ココス（キーリング）諸島',
  CL: 'チリ',
  CO: 'コロンビア',
  TW: '台湾,中国',
  CY: 'キプロス',
  DK: 'デンマーク',
  DO: 'ドミニカ共和国',
  ES: 'スペイン',
  FI: 'フィンランド',
  FK: 'マルビナス諸島（フォークランド諸島）',
  GE: 'ジョージア',
  GL: 'グリーンランド',
  GN: 'ギニア',
  GS: 'サウスジョージアおよびサウスサンドイッチ諸島',
  KW: 'クウェート',
  KZ: 'カザフスタン',
  LT: 'リトアニア',
  LY: 'リビア',
  MH: 'マーシャル諸島',
  MO: 'マカオ',
  MU: 'モーリシャス',
  MY: 'マレーシア',
  NA: 'ナミビア',
  NU: 'ニウエ',
  OM: 'オマーン',
  PK: 'パキスタン',
  PR: 'プエルトリコ',
  AT: 'オーストリア',
  BD: 'バングラデシュ',
  CH: 'スイス',
  CS: 'クリスマス島',
  FM: 'ミクロネシア',
  IO: '英領インド洋地域',
  KI: 'キリバス',
  LC: 'セントルシア',
  MA: 'モロッコ',
  MM: 'ミャンマー',
  MS: 'モントセラト',
  NG: 'ナイジェリア',
  PH: 'フィリピン',
  RE: '再会',
  WS: '西サモア',
  AE: 'アラブ首長国連邦',
  AS: '米領サモア',
  BE: 'ベルギー',
  BN: 'ブルネイ・ダルサラーム',
  CM: 'カメルーン',
  DJ: 'ジブチ',
  ER: 'エリトリア',
  GF: '仏領ギアナ',
  GW: 'ギニアビサウ',
  HR: 'クロアチア',
  IS: 'アイスランド',
  KH: 'カンボジア',
  KY: 'ケイマン諸島',
  LR: 'リベリア',
  MQ: 'マルティニーク',
  MZ: 'モザンビーク',
  PA: 'パナマ',
  PN: 'ピトケアン諸島グループ',
  RW: 'ルワンダ',
  SH: 'セントヘレナ',
  SM: 'サンマリオン',
  SY: 'シリア',
  TJ: 'タジキスタン',
  TZ: 'タンザニア',
  EE: 'エストニア',
  LI: 'リヒテンシュタイン',
  NF: 'ノーフォーク島',
  PY: 'パラグアイ',
  GP: 'グアドループ',
  UM: '米国の雑多なパック',
  NP: 'ネパール',
  PM: 'サンピエール島とミクロン島',
  GQ: '赤道ギニア',
  GY: 'ガイアナ',
  KG: 'キルギスタン',
  KN: 'セントクリストファーネイビス',
  LA: 'ラオス',
  LK: 'スリランカ',
  MC: 'モナコ',
  MG: 'マダガスカル',
  MR: 'モーリタニア',
  MW: 'マラウイ',
  NE: 'ニジェール',
  NO: 'ノルウェー',
  NR: 'ナウル',
  PG: 'パプアニューギニア',
  PS: 'パレスチナ',
  QA: 'カタール',
  RS: 'セルビア',
  MP: '北マリアナ諸島',
  NL: 'オランダ',
  PF: 'フランス領ポリネシア',
  AM: 'アルメニア',
  BR: 'ブラジル',
  DM: 'ドミニカ',
  HN: 'ホンジュラス',
  TO: 'トンガ',
  TR: '七面鳥',
  TV: 'ツバル',
  UG: 'ウガンダ',
  UY: 'ウルグアイ',
  VC: 'セントビンセントおよびグレナディーン諸島',
  VI: 'United States Virgin Is-lands',
  WF: 'ウォリス・フツナ諸島',
  ZM: 'ザンビア',
  FJ: 'フィジー',
  GH: 'ガーナ',
  GT: 'グアテマラ',
  VN: 'ベトナム',
  ZR: 'ザイール',
};
