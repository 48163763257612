export default {
  AD: 'Andorra',
  AF: 'Afghanistan',
  EG: 'Egypt',
  EH: 'Vestre Sahara',
  FO: 'Færøyene',
  GA: 'Gabon',
  GD: 'Grenada',
  GI: 'Gibraltar',
  GM: 'Gambia',
  AG: 'Antigua og Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AN: 'De nederlandske Antillene',
  AO: 'Angola',
  AQ: 'Antarktis',
  AR: 'Argentina',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Aserbajdsjan',
  BA: 'Bosnia og Herzegovina',
  BB: 'Barbados',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BS: 'Bahamas',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BZ: 'Belize',
  CA: 'Canada',
  CF: 'Sentral-Afrika',
  CG: 'Kongo',
  CI: 'Elfenbenskysten',
  CK: 'Cook-øyene',
  CN: 'Kina',
  CU: 'Cuba',
  CV: 'Kapp Verde',
  DE: 'Tyskland',
  EC: 'Ecuador',
  GR: 'Hellas',
  IR: 'Iran',
  IT: 'Italia',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KM: 'Komorene',
  KP: 'Nord-Korea',
  KR: 'Sør-Korea',
  LB: 'Libanon',
  LS: 'Lesotho',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MD: 'Moldova',
  GU: 'Guam',
  HK: 'Hong Kong',
  HM: 'Hørte øyer og Mc Donald-øyer',
  HT: 'Haiti',
  MK: 'Makedonia',
  ML: 'Mali',
  MN: 'Mongolia',
  HU: 'Ungarn',
  ID: 'Indonesia',
  IE: 'Irland',
  IL: 'Israel',
  IN: 'India',
  IQ: 'Irak',
  MT: 'Malta',
  MV: 'Maldivene',
  MX: 'Mexico',
  NC: 'Ny Caledonia',
  NI: 'Nicaragua',
  NZ: 'New Zealand',
  PE: 'Peru',
  PL: 'Polen',
  PT: 'Portugal',
  PW: 'Palau',
  CR: 'Costa Rica',
  CZ: 'Tsjekkia',
  DZ: 'Algeria',
  ET: 'Etiopia',
  FR: 'Frankrike',
  RO: 'Romania',
  RU: 'Russland',
  SA: 'Saudi-Arabia',
  SB: 'Solomon øyene',
  SC: 'Seychellene',
  SD: 'Sudan',
  SE: 'Sverige',
  SG: 'Singapore',
  SI: 'Slovenia',
  SJ: 'Svalbard og Jan Mayen Islands',
  SK: 'Slovakia',
  SL: 'Sierra leone',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Surinam',
  ST: 'Sao Tome og Principe',
  SV: 'El Salvador',
  SZ: 'Swaziland',
  TC: 'Turks- og Caicosøyene',
  TD: 'Tsjad',
  TF: 'Franske sørterritorier',
  TG: 'Å gå',
  TH: 'Thailand',
  TK: 'Tokelau',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TP: 'Øst-Timor',
  TT: 'Trinidad og Tobago',
  UA: 'Ukraina',
  US: 'Forente stater',
  UZ: 'Usbekistan',
  VA: 'Vatikanet',
  VE: 'Venezuela',
  VG: 'De britiske jomfruøyene',
  VU: 'Vanuatu',
  YE: 'Jemen',
  YT: 'Mayotte',
  YU: 'Jugoslavia',
  ZA: 'Sør-Afrika',
  ZW: 'Zimbabwe',
  GB: 'Storbritannia',
  BO: 'Bolivia',
  BT: 'Bhutan',
  BY: 'Hviterussland',
  CC: 'Cocos(Keeling) Islands',
  CL: 'Chile',
  CO: 'Colombia',
  TW: 'Taiwan, Kina',
  CY: 'Kypros',
  DK: 'Danmark',
  DO: 'Den dominikanske republikk',
  ES: 'Spania',
  FI: 'Finland',
  FK: 'Malvinas-øyene (Falklandsøyene)',
  GE: 'Georgia',
  GL: 'Grønland',
  GN: 'Guinea',
  GS: 'Sør-Georgia og Sør-Sandwichøyene',
  KW: 'Kuwait',
  KZ: 'Kasakhstan',
  LT: 'Litauen',
  LY: 'Libya',
  MH: 'Marshalløyene',
  MO: 'Macau',
  MU: 'Mauritius',
  MY: 'Malaysia',
  NA: 'Namibia',
  NU: 'Niue',
  OM: 'Oman',
  PK: 'Pakistan',
  PR: 'Puerto Rico',
  AT: 'Østerrike',
  BD: 'Bangladesh',
  CH: 'Sveits',
  CS: 'Juleøya',
  FM: 'Micronesia',
  IO: 'Territoriet i det britiske indiske hav',
  KI: 'Kiribati',
  LC: 'Saint Lucia',
  MA: 'Marokko',
  MM: 'Myanmar',
  MS: 'Montserrat',
  NG: 'Nigeria',
  PH: 'Filippinene',
  RE: 'Gjenforening',
  WS: 'Vest-Samoa',
  AE: 'De forente arabiske emirater',
  AS: 'Amerikansk Samoa',
  BE: 'Belgia',
  BN: 'Brunei Darussalam',
  CM: 'Kamerun',
  DJ: 'Djibouti',
  ER: 'Eritrea',
  GF: 'Fransk Guyana',
  GW: 'Guine-bissau',
  HR: 'Kroatia',
  IS: 'Island',
  KH: 'Kambodsja',
  KY: 'Caymanøyene',
  LR: 'Liberia',
  MQ: 'Martinique',
  MZ: 'Mozambique',
  PA: 'Panama',
  PN: 'Pitcairn Islands Group',
  RW: 'Rwanda',
  SH: 'Saint Helena',
  SM: 'San Marion',
  SY: 'Syria',
  TJ: 'Tadsjikistan',
  TZ: 'Tanzania',
  EE: 'Estland',
  LI: 'Liechtenstein',
  NF: 'Norfolk Island',
  PY: 'Paraguay',
  GP: 'Guadeloupe',
  UM: 'United States diverse Pac',
  NP: 'Nepal',
  PM: 'Saint Pierre og Miquelon',
  GQ: 'Ekvatorial-Guinea',
  GY: 'Guyana',
  KG: 'Kirgisistan',
  KN: 'Saint Kitts og nevis',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Monaco',
  MG: 'Madagaskar',
  MR: 'Mauritania',
  MW: 'Malawi',
  NE: 'Niger',
  NO: 'Norge',
  NR: 'Nauru',
  PG: 'Papua Ny-Guinea',
  PS: 'Palestina',
  QA: 'Qatar',
  RS: 'Serbia',
  MP: 'Nord-Marianene',
  NL: 'Nederland',
  PF: 'Fransk Polynesia',
  AM: 'Armenia',
  BR: 'Brasil',
  DM: 'Dominica',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Tyrkia',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UY: 'Uruguay',
  VC: 'Saint Vincent og Grenadinene',
  VI: 'De forente staters jomfruøyer',
  WF: 'Wallis og Futuna Islands',
  ZM: 'Zambia',
  FJ: 'Fiji',
  GH: 'Ghana',
  GT: 'Guatemala',
  VN: 'Viet Nam',
  ZR: 'Zaire',
};
