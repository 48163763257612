export default {
  US: {
    AL: 'ألاباما',
    AK: 'ألاسكا',
    AS: 'ساموا الأمريكية',
    AZ: 'أريزونا',
    AR: 'أركنساس',
    AA: 'القوات المسلحة الأمريكية',
    AE: 'القوات المسلحة أوروبا',
    AP: 'القوات المسلحة باسيفيك',
    CA: 'كاليفورنيا',
    CO: 'كولورادو',
    CT: 'كونيتيكت',
    DE: 'ديلاوير',
    DC: 'مقاطعة كولومبيا',
    FM: 'ولايات ميكرونيزيا الموحدة',
    FL: 'فلوريدا',
    GA: 'جورجيا',
    GU: 'غوام',
    HI: 'هاواي',
    ID: 'ايداهو',
    IL: 'إلينوي',
    IN: 'إنديانا',
    IA: 'آيوا',
    KS: 'كانساس',
    KY: 'كنتاكي ',
    LA: 'لويزيانا',
    ME: 'مين',
    MH: 'جزر مارشال ',
    MD: 'ماريلاند',
    MA: 'ماساتشوستس',
    MI: 'ميشيغان',
    MN: 'مينيسوتا',
    MS: 'ميسيسيبي',
    MO: 'ميسوري',
    MT: 'مونتانا',
    NE: 'نبراسكا',
    NV: 'نيفادا',
    NH: 'نيو هامبشاير',
    NJ: 'نيو جيرسي',
    NM: 'المكسيك جديدة',
    NY: 'نيويورك',
    NC: 'شمال كارولينا',
    ND: 'شمال داكوتا',
    MP: 'جزر مريانا الشمالية',
    OH: 'أوهايو',
    OK: 'أوكلاهوما',
    OR: 'أوريغون',
    PW: 'بالاو',
    PA: 'بنسلفانيا',
    PR: 'بورتوريكو',
    RI: 'جزيرة رود',
    SC: 'كارولينا الجنوبية',
    SD: 'جنوب داكوتا',
    TN: 'تينيسي',
    TX: 'تكساس',
    UM: 'جزر الولايات المتحدة البعيدة الصغرى',
    UT: 'يوتا',
    VT: 'فيرمونت',
    VI: 'جزر فيرجن',
    VA: 'فيرجينيا',
    WA: 'واشنطن',
    WV: 'فرجينيا الغربية',
    WI: 'ويسكونسن',
    WY: 'وايومنغ',
  },
  CA: {
    AB: 'ألبرتا',
    BC: 'كولومبيا البريطانية',
    MB: 'مانيتوبا',
    NB: 'برونزيك جديد',
    NL: 'نيوفاوندلاند',
    NT: 'الاقاليم الشمالية الغربية',
    NS: 'مقاطعة نفوفا سكوشيا',
    NU: 'نونافوت',
    ON: 'أونتاريو',
    PE: 'جزيرة الأمير ادوارد',
    QC: 'كيبيك',
    SK: 'ساسكاتشوان',
    YT: 'يوكون',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
