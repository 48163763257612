export default {
  AD: '安道爾',
  AF: '阿富汗',
  EG: '埃及',
  EH: '撒哈拉沙漠西部',
  FO: '法羅群島',
  GA: '加蓬',
  GD: '格林納達',
  GI: '直布羅陀',
  GM: '岡比亞',
  AG: '安提瓜和巴布達',
  AI: '安圭拉',
  AL: '阿爾巴尼亞',
  AN: '荷屬安的列斯',
  AO: '安哥拉',
  AQ: '南極洲',
  AR: '阿根廷',
  AU: '澳大利亞',
  AW: '阿魯巴',
  AZ: '阿塞拜疆',
  BA: '波斯尼亞和黑塞哥維那',
  BB: '巴巴多斯',
  BF: '布基納法索',
  BG: '保加利亞',
  BH: '巴林',
  BI: '布隆迪',
  BJ: '貝寧',
  BM: '百慕大',
  BS: '巴哈馬',
  BV: '布維島',
  BW: '博茨瓦納',
  BZ: '伯利茲',
  CA: '加拿大',
  CF: '中非',
  CG: '剛果',
  CI: '科特迪瓦',
  CK: '庫克群島',
  CN: '中國',
  CU: '古巴',
  CV: '佛得角',
  DE: '德國',
  EC: '厄瓜多爾',
  GR: '希臘',
  IR: '伊朗',
  IT: '意大利',
  JM: '牙買加',
  JO: '約旦',
  JP: '日本',
  KE: '肯尼亞',
  KM: '科摩羅',
  KP: '北韓',
  KR: '韓國',
  LB: '黎巴嫩',
  LS: '萊索托',
  LU: '盧森堡',
  LV: '拉脫維亞',
  MD: '摩爾多瓦',
  GU: '關島',
  HK: '中国香港',
  HM: '赫德群島和麥克唐納群島',
  HT: '海地',
  MK: '馬其頓',
  ML: '馬里',
  MN: '蒙古',
  HU: '匈牙利',
  ID: '印度尼西亞',
  IE: '愛爾蘭',
  IL: '以色列',
  IN: '印度',
  IQ: '伊拉克',
  MT: '馬耳他',
  MV: '馬爾代夫',
  MX: '墨西哥',
  NC: '新喀裡多尼亞',
  NI: '尼加拉瓜',
  NZ: '新西蘭',
  PE: '秘魯',
  PL: '波蘭',
  PT: '葡萄牙',
  PW: '帕勞',
  CR: '哥斯達黎加',
  CZ: '捷克共和國',
  DZ: '阿爾及利亞',
  ET: '埃塞俄比亞',
  FR: '法國',
  RO: '羅馬尼亞',
  RU: '俄羅斯',
  SA: '沙特阿拉伯',
  SB: '所羅門群島',
  SC: '塞舌爾',
  SD: '蘇丹',
  SE: '瑞典',
  SG: '新加坡',
  SI: '斯洛文尼亞',
  SJ: '斯瓦爾巴群島和揚馬延群島',
  SK: '斯洛伐克',
  SL: '塞拉利昂',
  SN: '塞內加爾',
  SO: '索馬里',
  SR: '蘇里南',
  ST: '聖多美和普林西比',
  SV: '薩爾瓦多',
  SZ: '斯威士蘭',
  TC: '特克斯和凱科斯群島',
  TD: '乍得',
  TF: '法屬南領地',
  TG: '多哥',
  TH: '泰國',
  TK: '托克勞',
  TM: '土庫曼斯坦',
  TN: '突尼斯',
  TP: '東帝汶',
  TT: '特立尼達和多巴哥',
  UA: '烏克蘭',
  US: '美國',
  UZ: '烏茲別克斯坦',
  VA: '教廷',
  VE: '委內瑞拉',
  VG: '英屬維爾京群島',
  VU: '瓦努阿圖',
  YE: '也門',
  YT: '馬約特',
  YU: '南斯拉夫',
  ZA: '南非',
  ZW: '津巴布韋',
  GB: '英國',
  BO: '玻利維亞',
  BT: '不丹',
  BY: '白俄羅斯',
  CC: '科科斯（基林）群島',
  CL: '智利',
  CO: '哥倫比亞',
  TW: '中國台灣',
  CY: '塞浦路斯',
  DK: '丹麥',
  DO: '多明尼加共和國',
  ES: '西班牙',
  FI: '芬蘭',
  FK: '馬爾維納斯群島（福克蘭群島）',
  GE: '喬治亞州',
  GL: '格陵蘭',
  GN: '幾內亞',
  GS: '南喬治亞島和南桑威奇群島',
  KW: '科威特',
  KZ: '哈薩克斯坦',
  LT: '立陶宛',
  LY: '利比亞',
  MH: '馬紹爾群島',
  MO: '中国澳門',
  MU: '毛里求斯',
  MY: '馬來西亞',
  NA: '納米比亞',
  NU: '紐埃',
  OM: '阿曼',
  PK: '巴基斯坦',
  PR: '波多黎各',
  AT: '奧地利',
  BD: '孟加拉國',
  CH: '瑞士',
  CS: '聖誕島',
  FM: '密克羅尼西亞',
  IO: '英屬印度洋領地',
  KI: '基里巴斯',
  LC: '聖盧西亞',
  MA: '摩洛哥',
  MM: '緬甸',
  MS: '蒙特塞拉特',
  NG: '尼日利亞',
  PH: '菲律賓',
  RE: '團圓',
  WS: '西薩摩亞',
  AE: '阿拉伯聯合酋長國',
  AS: '美屬薩摩亞',
  BE: '比利時',
  BN: '文萊達魯薩蘭國',
  CM: '喀麥隆',
  DJ: '吉布提',
  ER: '厄立特里亞',
  GF: '法屬圭亞那',
  GW: '幾內亞比紹',
  HR: '克羅地亞',
  IS: '冰島',
  KH: '柬埔寨',
  KY: '開曼群島',
  LR: '利比里亞',
  MQ: '馬提尼克島',
  MZ: '莫桑比克',
  PA: '巴拿馬',
  PN: '皮特凱恩群島集團',
  RW: '盧旺達',
  SH: '聖赫勒拿',
  SM: '聖馬里昂',
  SY: '敘利亞',
  TJ: '塔吉克斯坦',
  TZ: '坦桑尼亞',
  EE: '愛沙尼亞',
  LI: '列支敦士登',
  NF: '諾福克島',
  PY: '巴拉圭',
  GP: '瓜德羅普',
  UM: '美國雜項 Pac',
  NP: '尼泊爾',
  PM: '聖皮埃爾和密克隆',
  GQ: '赤道幾內亞',
  GY: '圭亞那',
  KG: '吉爾吉斯斯坦',
  KN: '聖基茨和尼維斯',
  LA: '老撾',
  LK: '斯里蘭卡',
  MC: '摩納哥',
  MG: '馬達加斯加',
  MR: '毛里塔尼亞',
  MW: '馬拉維',
  NE: '尼日爾',
  NO: '挪威',
  NR: '瑙魯',
  PG: '巴布亞新幾內亞',
  PS: '巴勒斯坦',
  QA: '卡塔爾',
  RS: '塞爾維亞',
  MP: '北馬里亞納',
  NL: '荷蘭',
  PF: '法屬波利尼西亞',
  AM: '亞美尼亞',
  BR: '巴西',
  DM: '多米尼克',
  HN: '洪都拉斯',
  TO: '湯加',
  TR: '火雞',
  TV: '圖瓦盧',
  UG: '烏干達',
  UY: '烏拉圭',
  VC: '聖文森特和格林納丁斯',
  VI: '美屬維爾京群島',
  WF: '瓦利斯和富圖納群島',
  ZM: '贊比亞',
  FJ: '斐濟',
  GH: '加納',
  GT: '危地馬拉',
  VN: '越南',
  ZR: '扎伊爾',
};
