import { Message } from 'element-ui';

import { allRules } from '../validator';

export function getLeftTime(time, effectiveTime) {
  const timeNew = time.replace(/-/g, '/');
  const now = new Date().getTime();
  const start = new Date(timeNew).getTime();
  const usedTime = now - start;
  let minute = 0;
  let second = 0;
  const leftTime = effectiveTime - usedTime;
  if (leftTime <= 0) {
    return '00:00';
  }
  minute = Math.floor(leftTime / 1000 / 60 % 60);
  second = Math.floor(leftTime / 1000 % 60);
  return (`${minute}`.length < 2 ? `0${minute}` : minute) + ':' + (`${second}`.length < 2 ? `0${second}` : second);
}

export function getLeftTimeByExpire(time) {
  const now = new Date().getTime();
  let minute = 0;
  let second = 0;
  const leftTime = time - now;
  if (leftTime <= 0) {
    return '00:00';
  }
  minute = Math.floor(leftTime / 1000 / 60 % 60);
  second = Math.floor(leftTime / 1000 % 60);
  return (`${minute}`.length < 2 ? `0${minute}` : minute) + ':' + (`${second}`.length < 2 ? `0${second}` : second);
}

const numberRegex = /^\d$/;

export function keydownHandler(evt, type) {
  if (!evt.code || evt.key === 'Unidentified') { // 部分手机evt.code没有值，数据处理放到keyup以后
    return;
  }
  if ((evt.ctrlKey || evt.metaKey) && evt.code === 'KeyC') { // 复制
    return;
  }
  if ((evt.ctrlKey || evt.metaKey) && evt.code === 'KeyV') { // 粘贴
    return;
  }
  if (evt.key === 'Backspace') { // 删除
    return;
  }
  // 删除
  if (type === 'number' && !numberRegex.test(evt.key) && evt.code !== 'Backspace') evt.returnValue = false;
  // 斜杠和删除
  if (type === 'expiredDate' && !numberRegex.test(evt.key) && evt.code !== 'Slash' && evt.code !== 'Backspace') evt.returnValue = false;
}

export function exceptionShow(code, router, key, checkoutType) {
  const query = { key, type: checkoutType };
  if (code === '00020|50054' || code === '50054') { // 订单超时未支付
    query.status = 's0';
    router.push({
      path: '/result/pending',
      query,
    });
    return true;
  }
  if (code === '00020|50051') { // 成功订单
    router.push({
      path: '/result/success',
      query,
    });
    return true;
  }
  if (code === '00020|50052') { // 失败订单
    router.push({
      path: '/result/failed',
      query,
    });
    return true;
  }
  if (code === '00020|50053') { // 订单已被取消
    router.push({
      path: '/result/cancelled',
      query,
    });
    return true;
  }
  if (code === '00020|50050') { // 错误的订单
    router.push({
      path: '/result/invalid',
      query,
    });
    return true;
  }
  return false;
}

// 选择下列国家时，state字段为select，其它为input
export const selectStateCountry = ['US', 'CA', 'CN'];

// 选择一下国家时，Identify Number字段label显示对应的名字
export const identityNumberLabelEbanx = {
  BR: 'CPF/CNPJ',
  AR: 'CUIT/CUIL/CDI',
  CL: 'RUT',
  CO: 'NIT/CC',
  PE: 'DNI',
  UY: 'RUT/RUC',
};

export const identityNumberDlocal = {
  BR: 'CPF/CNPJ',
  CL: 'CI/RUT',
  PE: 'DNI',
  MX: 'CURP',
  PY: 'CI',
  UY: 'CI/RUT',
};

export const identityNumberLabel = {
  BR: 'CPF/CNPJ', // Between 11 to 14 digits
  AR: 'CUIT/CUIL/CDI',
  CL: 'RUT', // Between 8 to 9 characters
  CO: 'NIT/CC', // Between 6 to 10 digits
  PE: 'DNI', // Between 8 to 9 digits
  UY: 'RUT', // Between 6 to 8 digits or 12 digits
  MX: 'CURP',
  PY: 'CI',
};

// 支付信息字段顺序
export const paymentFields = [
  'cardNum',
  'effectiveDate',
  'cvv',
  'issueDate',
  'cardHolderName',
  'prepaidNumber',
  'identityNumber',
  'birthDate',
  'bankName',
  'iBan',
  'jpFirstName',
  'jpLastName',
  'blikcode', // blik支付方式独有
  'walletAccountName', // 本地支付用户名
];

// 账单地址字段顺序
export const billingFields = [
  'country',
  'province',
  'address',
  'city',
  'postalCode',
  'email',
  'firstName', // firstName和lastName显示成Contact name
  'lastName',
  'phone',
  'street',
  'number',
];

export const ALIPAY = [
  'Kakao Pay',
  'TrueMoney Wallet',
  'GCash',
  'Touch \'n Go eWallet',
  'DANA',
  'AlipayHK',
  'Alipay',
];

export function paymentFieldsSort(fields) {
  if (fields && fields.length) {
    fields.sort((field1, field2) => paymentFields.indexOf(field1.name) - paymentFields.indexOf(field2.name));
  }
}

export function billingFieldsSort(fields) {
  if (fields && fields.length) {
    fields.sort((field1, field2) => billingFields.indexOf(field1.name) - billingFields.indexOf(field2.name));
  }
};

export function fieldConfigurationRulesAndNamesHandler(fields, rules, country) {
  const fieldNames = [];
  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    fieldNames.push(field.name);
    const rule = [];
    if (field.require === 'Y') {
      if (field.name === 'cardNum') { // 卡号
        rule.push(allRules.required('message.checkout.cardNumber', true, true, ['change']));
      } else if (field.name === 'effectiveDate') { // 过期日期
        rule.push(allRules.required('message.checkout.expiredDate'));
      } else if (field.name === 'cvv') { // cvv
        rule.push(allRules.required('message.checkout.cvv'));
      } else if (field.name === 'cardHolderName') { // 持卡人姓名
        rule.push(allRules.required('message.checkout.holderName'));
      } else if (field.name === 'jpFirstName') {
        rule.push(allRules.required('姓/カタカナ', false));
      } else if (field.name === 'jpLastName') {
        rule.push(allRules.required('名/カタカナ', false));
      } else {
        rule.push(allRules.required(`message.checkout.${field.name}`));
      }
    }
    if (field.name === 'cardNum') {
      rule.push({ validator: allRules.cardNumber, trigger: 'blur' });
    } else if (field.name === 'effectiveDate') {
      rule.push({ validator: allRules.monthYear, trigger: 'blur' });
    } else if (field.name === 'cvv') {
      rule.push({ validator: allRules.cvv, trigger: 'blur' });
    } else if (field.name === 'cardHolderName') {
      rule.push({ validator: allRules.holderName, trigger: 'blur' });
    } else if (field.name === 'email') {
      rule.push({ validator: allRules.email, trigger: 'blur' });
    } else if (field.name === 'birthDate') {
      rule.push({ validator: allRules.birthDate, trigger: 'blur' });
    } else if (field.name === 'issueDate') {
      rule.push({ validator: allRules.issueDate, trigger: 'blur' });
    } else if (field.name === 'blikcode') {
      rule.push({ validator: allRules.blikcode, trigger: 'blur' });
    }
    rules[field.name] = rule.length ? rule : null;
  }
  // identityNumber字段特殊处理
  if (rules.identityNumber && rules.identityNumber.length) {
    rules.identityNumber.push({ validator: allRules.identityNumberRequired.bind(this, country), trigger: 'blur' });
  }
  if (identityNumberLabel[country]) {
    if (rules.identityNumber && rules.identityNumber.length) {
      rules.identityNumber = [
        allRules.required(identityNumberLabel[country], false, false),
        { validator: allRules.identityNumberRequired.bind(this, country), trigger: 'blur' },
        { validator: allRules.identityNumber.bind(this, country), trigger: 'blur' },
      ];
    } else {
      rules.identityNumber = [
        { validator: allRules.identityNumber.bind(this, country), trigger: 'blur' },
        { validator: allRules.identityNumberRequired.bind(this, country), trigger: 'blur' },
      ];
    }
  }
  return fieldNames;
};

export function deleteFieldConfigurationRules(rules) {
  for (let i = 0; i < paymentFields.length; i++) {
    delete rules[paymentFields[i]];
  }
  for (let i = 0; i < billingFields.length; i++) {
    delete rules[billingFields[i]];
  }
};

export function showMessage(message) {
  Message({
    message,
    iconClass: ' ',
    offset: 150,
  });
}

export function getQueryString(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg);
  if (r !== null) return unescape(r[2]); return null;
}

export function getCardExpiredFullYear(year) {
  if (!year) {
    return '';
  }
  if (year.length === 4) return year;
  return '20' + year;
}

export function getDevice() {
  const userAgent = window.navigator.userAgent;
  if (/(iPhone|iPod|iPad|ios|Android|SymbianOS|Mobile)/i.test(userAgent)) {
    if (/(iPhone|iPod|iPad|ios)/i.test(userAgent)) {
      return {
        device: 'WAP',
        system: 'IOS',
      };
    }
    return {
      device: 'WAP',
      system: 'ANDROID',
    };
  } else if (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1) {
    return {
      device: 'WAP',
      system: 'IOS',
    };
  }
  return {
    device: 'WEB',
  };
}

export function getBrowerInfo() {
  return {
    javaEnabled: window.navigator.javaEnabled(),
    colorDepth: window.screen.colorDepth,
    screenHeight: window.screen.height,
    screenWidth: window.screen.width,
    language: window.navigator.language,
    timeZoneOffset: ((new Date()).getTimezoneOffset()).toString(),
    userAgent: window.navigator.userAgent,
  };
}

// iOS Safari/Android Chrome/Desktop 无限制
// 若为其他设备则venmo支付方式不展示
export function showVenmoPayMethod() {
  const device = getDevice();
  if (device.device === 'WEB') {
    return true;
  }
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (device.system === 'IOS') {
    const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent) && /version\/([\d.]+).*safari/.test(userAgent);
    if (isSafari) {
      return true;
    }
    return false;
  } else if (device.system === 'ANDROID') {
    const isChrome = /chrome\/[\d.\s]*mobile safari\/[\d.]*$/i.test(userAgent) && /google/i.test(navigator.vendor.toLowerCase());
    if (isChrome) {
      return true;
    }
    return false;
  }
  return false;
}

export function setRedirectFlag(key) {
  try {
    window.sessionStorage.setItem(`redirect_${key}`, '1');
  } catch (error) {
    console.log('Set sessionStorage redirect key error', error);
  }
}

export function removeRedirectFlag(key) {
  try {
    window.sessionStorage.removeItem(`redirect_${key}`);
  } catch (error) {
    console.log('Remove sessionStorage redirect key error', error);
  }
}

export function hasRedirect(key) {
  try {
    const redirdct = window.sessionStorage.getItem(`redirect_${key}`);
    if (redirdct === '1') {
      return true;
    }
    return false;
  } catch (error) {
    console.log('Get redirect key error', error);
  }
  return false;
}

export const copyString = (val, message = 'Copiado!') => {
  const input = document.createElement('input');
  input.value = val;
  input.readOnly = true;
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, input.value.length);
  document.execCommand('Copy');
  document.body.removeChild(input);
  showMessage(message);
};

export const unzipOrder = (b64Data) => {
  try {
    let strData = atob(b64Data);
    const charData = strData.split('').map((x) => {
      return x.charCodeAt(0);
    });
    const binData = new Uint8Array(charData);
    const pako = require('pako');
    const data = pako.inflate(binData);
    if (data === undefined) {
      return null;
    }

    const decoder = new TextDecoder('utf-8');
    strData = decoder.decode(data);
    return JSON.parse(decodeURIComponent(strData));
  } catch (err) {
    return null;
  }
};

export const subCharByLength = (str, len) => {
  if (!len) {
    return str;
  }
  let subChar = '';
  let charLen = 0;
  const strLen = `${str}`.length;
  // if (len >= strLen) {
  //   return str;
  // }
  let i;
  for (i = 0; i < strLen; i++) {
    if (str.charCodeAt(i) > 127 || str.charCodeAt(i) === 94) {
      charLen += 2;
    } else {
      charLen += 1;
    }
    if (charLen > len) {
      break;
    }
    subChar += str.charAt(i);
  }
  return `${subChar}${strLen > i ? '...' : ''}`;
};
