import ElementUILocal from 'element-ui/lib/locale/lang/it';

import country from './country/it';
import state from './state/it';

export default {
  message: {
    title: 'Cassa',
    powerByOnerway: 'Alimentato da <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Completa il pagamento entro <span class="time">{time}</span> minuti o la pagina scadrà',
      billingAddressTip:
        'Gli addebiti appariranno sulla fattura come {billingAddress}',
      cardInfo: 'Informazioni sulla carta',
      cardNumber: 'Numero di carta',
      prepaidNumber: 'Numero di carta',
      summary: 'Riepilogo ordine',
      expiredDate: 'Periodo di validità',
      issueDate: 'Issue date',
      cvv: 'CVC/CVV',
      firstName: 'Nome',
      lastName: 'Cognome',
      holderName: 'Nome del titolare',
      walletAccountName: 'Nome',
      orderno: 'ID ordine',
      website: 'URL',
      amount: 'Quantità',
      pay: 'Pagar',
      payNow: 'Paga subito',
      update: 'Aggiorna',
      deduction: 'Importo Addebitato',
      nextDeductionTime: 'Prossima Data di Addebito',
      subscriptionEndTime: "Data di Scadenza dell'Abbonamento",
      frequency: 'Ogni',
      week: 'Settimana',
      day: 'Giorno',
      month: 'Mese',
      year: 'Anno',
      cancel: 'Annulla',
      tip: 'Suggerimento',
      cancelPayTip: 'Vuoi rinunciare a questo pagamento?',
      yes: 'Sì',
      no: 'No',
      subscription: 'Sottoscrizione',
      schedule: 'Ciclo di pagamento',
      endTime: 'Scadenza',
      subscriptionTip:
        'Confermando il pagamento, consenti al commerciante di addebitare la tua carta in futuro secondo i termini.',
      frequencyData: {
        D: '{time}giorni/ora',
      },
      endTimeData: '{year}Anno{month}Luna{day}Giorno',
      billingAddress: 'Indirizzo di fatturazione/Informazioni di contatto',
      country: 'Nazione',
      province: 'Stato/Provincia/Regione',
      address: 'Indirizzo',
      city: 'Città',
      postalCode: 'CAP/Codice Postale',
      email: 'Posta',
      phone: 'Numero di telefono',
      street: 'Numero civico',
      number: 'Numero di casa',
      identityNumber: 'Codice fiscale',
      intallment: 'Messa in scena',
      intallmentPlan: 'Piano a rate',
      paymentMethod: 'Metodo di pagamento',
      bankName: 'Nome della banca',
      iBan: 'Conto bancario',
      contactName: 'Nome del contatto',
      birthDate: 'Data di nascita',
      paymentCompleted: 'Pagamento completato',
      wechatPaymentTip: 'Usa WeChat per scansionare il codice QR per pagare',
      paymentPending: 'Attendere il pagamento',
      billingCountry: 'Paese di fatturazione',
      billingCountryTip:
        'Seleziona prima il paese di fatturazione, in modo che possiamo consigliarti un metodo di pagamento adatto.',
      nextStep: 'Passo successivo',
      changeCountryTip:
        'Dopo aver cambiato paese, ti consiglieremo i metodi di pagamento adeguati.',
      scanCode: 'Scansionalo',
      txnTime: 'Tempo di transazione',
      moreInfo: 'Maggiori informazioni',
      applePayNotSupport: 'Questo dispositivo non supporta Apple Pay.',
      policy: {
        prefix: 'Ho letto e accetto la ',
        suffix: '',
      },
      privacy: 'Privacy Policy',
      intallmentTip: 'Potreste dover pagare gli interessi sulle rate.',
      payInFull: 'Pagare per intero',
      payIn: 'Pagamenti in {count}',
      blikcode: 'Codice BLIK',
      unavailable: 'Non disponibile',
      notSupportPaymentTips:
        'Il metodo di pagamento non è disponibile in questo momento. Si prega di tornare alla pagina di selezione.',
      returnText: 'Torna alla selezione',
      returnMerchant: 'Torna alla pagina del commerciante',
      cvvTips:
        'Il codice CVC si trova sul retro della carta, sul lato destro della striscia bianca della firma; sono sempre le ultime 3 cifre per Visa e Mastercard. Per American Express, sono 4 cifre sul fronte della carta.',
      holderNameTips: 'Nome completo sulla carta',
      continue: 'Continuare',
      validateCVVTitle: 'Verifica di sicurezza',
      validateCVVDesc:
        'Per la sicurezza del tuo pagamento, verifica il CVC/CVV della tua carta',
      // 订阅产品
      subscriptionProduct: 'Sottoscrizione',
      // 订阅费用
      subscriptionFee: 'Costo della sottoscrizione',
      // 更新成功
      updateSuccess: 'Aggiornamento riuscito',
      // 更新失败
      updateFailed: 'Aggiornamento fallito',
      // 次
      times: 'Volte',
    },
    dlocal: {
      amount: 'l’importo',
      expirationDate: 'data di scadenza',
      ticketNumber: 'Numero del biglietto',
      ticketImage: 'Immagine del biglietto',
      downloadTicket: 'Scarica il biglietto',
      searchForShops: 'ricerca di negozi',
      fullBoletoView: 'Vista completa di Boleto',
      back: 'ritorno',
      copySuccess: 'Copia',
      errorMsg: 'Timeout. Impossibile ottenere i parametri.',
      instructionsTitle: 'Istruzione di pagamento',
      instructionsContentPIX:
        "ID Istruzioni per il pagamento:<br/>1. ID della transazione di replica<br/>2. Utilizzate l'ID per identificare i pagamenti PIX nella vostra app bancaria o leggete il codice QR sull'app della banca.",
      instructionsContentRedpagos:
        "Per effettuare il pagamento è necessario recarsi in un negozio redpagos.<br/>Comunicando il numero della carta d'identità e precisando che si vuole pagare una bolletta dlocale, si potrà effettuare il pagamento.",
      instructionsContentOXXO:
        "1. Andare al negozio oxxo più vicino.<br/>2. Indicare nell'apposita casella che si vuole effettuare un pagamento oxxopay.<br/>3. Digita alla cassiera il numero di riferimento di questa carta per digitarlo<br/>direttamente sulla schermata di vendita.<br/>4. Effettua il pagamento corrispondente in contanti.<br/>5. Alla conferma del pagamento, il cassiere vi consegnerà un voucher stampato. Qui è possibile verificare che il pagamento sia stato effettuato correttamente. Conservate questa prova di pagamento.<br/>6. Per ulteriori informazioni sul pagamento, visitate il nostro centro assistenza.",
      instructionsContentSPEI:
        '1. Accreditamento immediato.<br/>2. Una volta ricevuto il bonifico bancario, dlocal informerà la consegna del prodotto/servizio che si sta acquistando.<br/>3. In caso di problemi di pagamento, visitate il nostro portale di assistenza clienti.',
      instructionsContentSencillito:
        'Istruzioni di pagamento di persona:<br/>Presso la sede di Sencillito, è possibile effettuare un pagamento fornendo un numero di riferimento e chiarendo che si vuole pagare la fattura DLOCAL.<br/>Istruzioni di pagamento online:<br/>Per effettuare il pagamento è necessario entrare nel Portale Sencillito, scegliere "dlocal" nel motore di ricerca aziendale e inserire il codice indicato sul voucher 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: 'Dettagli bancari',
      bankDetailsSPEI:
        'Accedere all\'online banking e trasferire al conto successivo. Si prega di notare che non è possibile effettuare pagamenti in contanti.<br/>Banca di destinazione:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Beneficiario: <span class="detail-value">{beneficiary}</span><br/>Importo: <span class="detail-value">{amount} {currency}</span><br/>Concetto di pagamento: <span class="detail-value">{concept}</span><br/>Riferimento: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: 'Tipo di pagamento',
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: 'Importo',
      orderTips: 'Si prega di trasferire al seguente conto',
      numberTitle: 'Numero di conto (solo per questa transazione)',
      copyBtnText: 'Copia numero di conto',
      expireText: "L'ordine scade in {time}",
      bankName: 'Nome della banca',
    },
    date: {
      month: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
      },
    },
    result: {
      success: 'Pagamento andato a buon fine',
      failed: 'Pagamento fallito',
      unknow: 'Pagamenti',
      unknowTip:
        "Aggiorna lo stato dell'ordine per verificare il risultato del pagamento",
      jump: 'Ritorno al commerciante in <span class="hightlight">{time}s</span>',
      reason: 'Motivo',
      processing: 'In lavorazione',
      timeout: 'La pagina è scaduta, riavvia il pagamento',
      invalid: 'Richiesta di transazione non valida',
      cancelled:
        'La richiesta di pagamento è stata annullata, si prega di non ripetere il pagamento',
      emptyPaymentMethod:
        'Non ci sono metodi di pagamento configurati per questo Paese.',
      error:
        "Errore. Si prega di controllare il risultato della transazione sulla pagina dell'ordine del sito web di shopping.",
    },
    validator: {
      input: 'Per favore inserire un {field} valido',
      intallmentCardOrCountryNotSupport:
        'Il pagamento rateale non è supportato dal tipo di carta o dal Paese',
    },
    error: {
      timeout: 'Errore di rete, riprova più tardi',
      cardNumber: 'Le informazioni sulla carta non sono corrette',
    },
    country,
    state,
  },
  ...ElementUILocal,
};
