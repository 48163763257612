export default {
  AD: 'Andorra',
  AF: 'Afganistán',
  EG: 'Egipto',
  EH: 'Sahara Occidental',
  FO: 'Islas Faroe',
  GA: 'Gabón',
  GD: 'Granada',
  GI: 'Gibraltar',
  GM: 'Gambia',
  AG: 'Antigua y Barbuda',
  AI: 'Anguila',
  AL: 'Albania',
  AN: 'Antillas Holandesas',
  AO: 'Angola',
  AQ: 'Antártida',
  AR: 'Argentina',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaiyán',
  BA: 'Bosnia y Herzegovina',
  BB: 'Barbados',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Baréin',
  BI: 'Burundi',
  BJ: 'Benín',
  BM: 'Islas Bermudas',
  BS: 'Bahamas',
  BV: 'Isla Bouvet',
  BW: 'Botsuana',
  BZ: 'Belice',
  CA: 'Canadá',
  CF: 'África central',
  CG: 'Congo',
  CI: 'Costa de Marfil',
  CK: 'Islas Cook',
  CN: 'China',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  DE: 'Alemania',
  EC: 'Ecuador',
  GR: 'Grecia',
  IR: 'Irán',
  IT: 'Italia',
  JM: 'Jamaica',
  JO: 'Jordán',
  JP: 'Japón',
  KE: 'Kenia',
  KM: 'Comoras',
  KP: 'Corea del Norte',
  KR: 'Corea del Sur',
  LB: 'Líbano',
  LS: 'Lesoto',
  LU: 'Luxemburgo',
  LV: 'Letonia',
  MD: 'Moldavia',
  GU: 'Guam',
  HK: 'Hong Kong',
  HM: 'Islas Heard e Islas Mc Donald',
  HT: 'Haití',
  MK: 'Macedonia',
  ML: 'Malí',
  MN: 'Mongolia',
  HU: 'Hungría',
  ID: 'Indonesia',
  IE: 'Irlanda',
  IL: 'Israel',
  IN: 'India',
  IQ: 'Irak',
  MT: 'Malta',
  MV: 'Maldivas',
  MX: 'México',
  NC: 'Nueva Caledonia',
  NI: 'Nicaragua',
  NZ: 'Nueva Zelanda',
  PE: 'Perú',
  PL: 'Polonia',
  PT: 'Portugal',
  PW: 'Palaos',
  CR: 'Costa Rica',
  CZ: 'República Checa',
  DZ: 'Argelia',
  ET: 'Etiopía',
  FR: 'Francia',
  RO: 'Rumania',
  RU: 'Rusia',
  SA: 'Arabia Saudita',
  SB: 'Islas Salomón',
  SC: 'Seychell',
  SD: 'Sudán',
  SE: 'Suecia',
  SG: 'Singapur',
  SI: 'Eslovenia',
  SJ: 'Islas Svalbard y Jan Mayen',
  SK: 'Eslovaquia',
  SL: 'Sierra Leona',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Surinam',
  ST: 'Santo Tomé y Príncipe',
  SV: 'El Salvador',
  SZ: 'Suazilandia',
  TC: 'Islas Turcas y Caicos',
  TD: 'Chad',
  TF: 'Territorios Franceses del Sur',
  TG: 'Ir',
  TH: 'Tailandia',
  TK: 'Tokelau',
  TM: 'Turkmenistán',
  TN: 'Túnez',
  TP: 'Timor Oriental',
  TT: 'Trinidad y Tobago',
  UA: 'Ucrania',
  US: 'Estados Unidos',
  UZ: 'Uzbekistán',
  VA: 'Vaticano',
  VE: 'Venezuela',
  VG: 'Islas Vírgenes Británicas',
  VU: 'Vanuatu',
  YE: 'Yemen',
  YT: 'Mayotte',
  YU: 'Yugoslavia',
  ZA: 'Sudáfrica',
  ZW: 'Zimbabue',
  GB: 'Reino Unido',
  BO: 'Bolivia',
  BT: 'Bután',
  BY: 'Bielorrusia',
  CC: 'Islas Cocos (Keeling)',
  CL: 'Chile',
  CO: 'Colombia',
  TW: 'Taiwán, China',
  CY: 'Chipre',
  DK: 'Dinamarca',
  DO: 'República Dominicana',
  ES: 'España',
  FI: 'Finlandia',
  FK: 'Islas Malvinas (Islas Malvinas)',
  GE: 'Georgia',
  GL: 'Groenlandia',
  GN: 'Guinea',
  GS: 'Islas Georgias del Sur y Sandwich del Sur',
  KW: 'Kuwait',
  KZ: 'Kazajstán',
  LT: 'Lituania',
  LY: 'Libia',
  MH: 'Islas Marshall',
  MO: 'Macao',
  MU: 'Mauricio',
  MY: 'Malasia',
  NA: 'Namibia',
  NU: 'Niue',
  OM: 'Omán',
  PK: 'Pakistán',
  PR: 'Puerto Rico',
  AT: 'Austria',
  BD: 'Bangladés',
  CH: 'Suiza',
  CS: 'Isla de Navidad',
  FM: 'Micronesia',
  IO: 'Territorio Británico del Océano Índico',
  KI: 'Kiribatí',
  LC: 'Santa Lucía',
  MA: 'Marruecos',
  MM: 'Birmania',
  MS: 'Monserrat',
  NG: 'Nigeria',
  PH: 'Filipinas',
  RE: 'Reunión',
  WS: 'Samoa Occidental',
  AE: 'Emiratos Árabes Unidos',
  AS: 'Samoa Americana',
  BE: 'Bélgica',
  BN: 'Brunei Darussalam',
  CM: 'Camerún',
  DJ: 'Yibuti',
  ER: 'Eritrea',
  GF: 'Guayana Francesa',
  GW: 'Guine-bissau',
  HR: 'Croacia',
  IS: 'Islandia',
  KH: 'Camboya',
  KY: 'Islas Caimán',
  LR: 'Liberia',
  MQ: 'Martinica',
  MZ: 'Mozambique',
  PA: 'Panamá',
  PN: 'Grupo de las Islas Pitcairn',
  RW: 'Ruanda',
  SH: 'Santa Elena',
  SM: 'San Marion',
  SY: 'Siria',
  TJ: 'Tayikistán',
  TZ: 'Tanzania',
  EE: 'Estonia',
  LI: 'Liechtenstein',
  NF: 'Isla Norfolk',
  PY: 'Paraguay',
  GP: 'Guadalupe',
  UM: 'Estados Unidos varios Pac',
  NP: 'Nepal',
  PM: 'San Pedro y Miquelón',
  GQ: 'Guinea Ecuatorial',
  GY: 'Guayana',
  KG: 'Kirguistán',
  KN: 'San Cristóbal y Nieves',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Mónaco',
  MG: 'Madagascar',
  MR: 'Mauritania',
  MW: 'Malaui',
  NE: 'Níger',
  NO: 'Noruega',
  NR: 'Nauru',
  PG: 'Papúa Nueva Guinea',
  PS: 'Palestina',
  QA: 'Katar',
  RS: 'Serbia',
  MP: 'Marianas del Norte',
  NL: 'Países Bajos',
  PF: 'Polinesia francés',
  AM: 'Armenia',
  BR: 'Brasil',
  DM: 'Dominica',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Pavo',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UY: 'Uruguay',
  VC: 'San Vicente y las Granadinas',
  VI: 'Islas Vírgenes de los Estados Unidos',
  WF: 'Islas Wallis y Futuna',
  ZM: 'Zambia',
  FJ: 'Fiyi',
  GH: 'Ghana',
  GT: 'Guatemala',
  VN: 'Vietnam',
  ZR: 'Zaire',
};
