export default {
  AD: 'Андорра',
  AF: 'Афганистан',
  EG: 'Египет',
  EH: 'Западная Сахара',
  FO: 'Фарерские острова',
  GA: 'Габон',
  GD: 'Гренада',
  GI: 'Гибралтар',
  GM: 'Гамбия',
  AG: 'Антигуа и Барбуда',
  AI: 'Ангилья',
  AL: 'Албания',
  AN: 'Нидерландские Антильские острова',
  AO: 'Ангола',
  AQ: 'Антарктида',
  AR: 'Аргентина',
  AU: 'Австралия',
  AW: 'Аруба',
  AZ: 'Азербайджан',
  BA: 'Босния и Герцеговина',
  BB: 'Барбадос',
  BF: 'Буркина-Фасо',
  BG: 'Болгария',
  BH: 'Бахрейн',
  BI: 'Бурунди',
  BJ: 'Бенин',
  BM: 'Бермуды',
  BS: 'Багамы',
  BV: 'Остров Буве',
  BW: 'Ботсвана',
  BZ: 'Белиз',
  CA: 'Канада',
  CF: 'Центральная Африка',
  CG: 'Конго',
  CI: 'Берег Слоновой Кости',
  CK: 'Острова Кука',
  CN: 'Китай',
  CU: 'Куба',
  CV: 'Кабо-Верде',
  DE: 'Германия',
  EC: 'Эквадор',
  GR: 'Греция',
  IR: 'Иран',
  IT: 'Италия',
  JM: 'Ямайка',
  JO: 'Иордания',
  JP: 'Япония',
  KE: 'Кения',
  KM: 'Коморы',
  KP: 'Северная Корея',
  KR: 'Южная Корея',
  LB: 'Ливан',
  LS: 'Лесото',
  LU: 'Люксембург',
  LV: 'Латвия',
  MD: 'Молдова',
  GU: 'Гуам',
  HK: 'Гонконг',
  HM: 'Острова Херда и острова Мак-Дональд',
  HT: 'Гаити',
  MK: 'Македония',
  ML: 'Мали',
  MN: 'Монголия',
  HU: 'Венгрия',
  ID: 'Индонезия',
  IE: 'Ирландия',
  IL: 'Израиль',
  IN: 'Индия',
  IQ: 'Ирак',
  MT: 'Мальта',
  MV: 'Мальдивы',
  MX: 'Мексика',
  NC: 'Новая Каледония',
  NI: 'Никарагуа',
  NZ: 'Новая Зеландия',
  PE: 'Перу',
  PL: 'Польша',
  PT: 'Португалия',
  PW: 'Палау',
  CR: 'Коста-Рика',
  CZ: 'Чехия',
  DZ: 'Алжир',
  ET: 'Эфиопия',
  FR: 'Франция',
  RO: 'Румыния',
  RU: 'Россия',
  SA: 'Саудовская Аравия',
  SB: 'Соломоновы острова',
  SC: 'Сейшелы',
  SD: 'Судан',
  SE: 'Швеция',
  SG: 'Сингапур',
  SI: 'Словения',
  SJ: 'Острова Шпицберген и Ян-Майен',
  SK: 'Словакия',
  SL: 'Сьерра-Леоне',
  SN: 'Сенегал',
  SO: 'Сомали',
  SR: 'Суринам',
  ST: 'Сан-Томе и Принсипи',
  SV: 'Сальвадор',
  SZ: 'Свазиленд',
  TC: 'Острова Теркс и Кайкос',
  TD: 'Чад',
  TF: 'Южные Французские Территории',
  TG: 'Идти',
  TH: 'Таиланд',
  TK: 'Токелау',
  TM: 'Туркменистан',
  TN: 'Тунис',
  TP: 'Восточный Тимор',
  TT: 'Тринидад и Тобаго',
  UA: 'Украина',
  US: 'Соединенные Штаты',
  UZ: 'Узбекистан',
  VA: 'Ватикан',
  VE: 'Венесуэла',
  VG: 'Британские Виргинские острова',
  VU: 'Вануату',
  YE: 'Йемен',
  YT: 'Майотта',
  YU: 'Югославия',
  ZA: 'Южная Африка',
  ZW: 'Зимбабве',
  GB: 'Объединенное Королевство',
  BO: 'Боливия',
  BT: 'Бутан',
  BY: 'Беларусь',
  CC: 'Кокосовые (Килинг) острова',
  CL: 'Чили',
  CO: 'Колумбия',
  TW: 'Тайвань, Китай',
  CY: 'Кипр',
  DK: 'Дания',
  DO: 'Доминиканская Республика',
  ES: 'Испания',
  FI: 'Финляндия',
  FK: 'Мальвинские острова (Фолклендские острова)',
  GE: 'Грузия',
  GL: 'Гренландия',
  GN: 'Гвинея',
  GS: 'Южная Георгия и Южные Сандвичевы острова',
  KW: 'Кувейт',
  KZ: 'Казахстан',
  LT: 'Литва',
  LY: 'Ливия',
  MH: 'Маршалловы острова',
  MO: 'Макао',
  MU: 'Маврикий',
  MY: 'Малайзия',
  NA: 'Намибия',
  NU: 'Ниуэ',
  OM: 'Оман',
  PK: 'Пакистан',
  PR: 'Пуэрто-Рико',
  AT: 'Австрия',
  BD: 'Бангладеш',
  CH: 'Швейцария',
  CS: 'Остров Рождества',
  FM: 'Микронезия',
  IO: 'Британская территория Индийского океана',
  KI: 'Кирибати',
  LC: 'Санкт-Люсия',
  MA: 'Марокко',
  MM: 'Мьянма',
  MS: 'Монтсеррат',
  NG: 'Нигерия',
  PH: 'Филиппины',
  RE: 'Воссоединение',
  WS: 'Западное Самоа',
  AE: 'Объединенные Арабские Эмираты',
  AS: 'Американское Самоа',
  BE: 'Бельгия',
  BN: 'Бруней-Даруссалам',
  CM: 'Камерун',
  DJ: 'Джибути',
  ER: 'Эритрея',
  GF: 'Французская Гвиана',
  GW: 'Гвинея-бисау',
  HR: 'Хорватия',
  IS: 'Исландия',
  KH: 'Камбоджа',
  KY: 'Каймановы острова',
  LR: 'Либерия',
  MQ: 'Мартиника',
  MZ: 'Мозамбик',
  PA: 'Панама',
  PN: 'Группа островов Питкэрн',
  RW: 'Руанда',
  SH: 'Святая Елена',
  SM: 'Сан-Марион',
  SY: 'Сирия',
  TJ: 'Таджикистан',
  TZ: 'Танзания',
  EE: 'Эстония',
  LI: 'Лихтенштейн',
  NF: 'Остров Норфолк',
  PY: 'Парагвай',
  GP: 'Гваделупа',
  UM: 'Разное Pac США',
  NP: 'Непал',
  PM: 'Сен-Пьер и Микелон',
  GQ: 'Экваториальная Гвинея',
  GY: 'Гайана',
  KG: 'Кыргызстан',
  KN: 'Сент-Китс и Невис',
  LA: 'лаосский',
  LK: 'Шри-Ланка',
  MC: 'Монако',
  MG: 'Мадагаскар',
  MR: 'Мавритания',
  MW: 'Малави',
  NE: 'Нигер',
  NO: 'Норвегия',
  NR: 'Науру',
  PG: 'Папуа - Новая Гвинея',
  PS: 'Палестина',
  QA: 'Катар',
  RS: 'Сербия',
  MP: 'Северные Марианские острова',
  NL: 'Нидерланды',
  PF: 'Французская Полинезия',
  AM: 'Армения',
  BR: 'Бразилия',
  DM: 'Доминика',
  HN: 'Гондурас',
  TO: 'Тонга',
  TR: 'Турция',
  TV: 'Тувалу',
  UG: 'Уганда',
  UY: 'Уругвай',
  VC: 'Святой Винсент и Гренадины',
  VI: 'Виргинские острова Соединенных Штатов',
  WF: 'Острова Уоллис и Футуна',
  ZM: 'Замбия',
  FJ: 'Фиджи',
  GH: 'Гана',
  GT: 'Гватемала',
  VN: 'Вьетнам',
  ZR: 'Заир',
};
