export default {
  AD: 'Andorra',
  AF: 'Afganistan',
  EG: 'Egypti',
  EH: 'Länsi-Sahara',
  FO: 'Färsaaret',
  GA: 'Gabon',
  GD: 'Grenada',
  GI: 'Gibraltar',
  GM: 'Gambia',
  AG: 'Antigua ja Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AN: 'Alankomaiden Antillit',
  AO: 'Angola',
  AQ: 'Antarktis',
  AR: 'Argentiina',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaidžan',
  BA: 'Bosnia ja Hertsegovina',
  BB: 'Barbados',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BS: 'Bahama',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BZ: 'Belize',
  CA: 'Kanada',
  CF: 'Keski-Afrikka',
  CG: 'Kongo',
  CI: 'Norsunluurannikko',
  CK: 'Cook saaret',
  CN: 'Kiina',
  CU: 'Kuuba',
  CV: 'Kap Verde',
  DE: 'Saksa',
  EC: 'Ecuador',
  GR: 'Kreikka',
  IR: 'Iran',
  IT: 'Italia',
  JM: 'Jamaika',
  JO: 'Jordania',
  JP: 'Japani',
  KE: 'Kenia',
  KM: 'Komorit',
  KP: 'Pohjois-Korea',
  KR: 'Etelä-Korea',
  LB: 'Libanon',
  LS: 'Lesotho',
  LU: 'Luxemburg',
  LV: 'Latvia',
  MD: 'Moldova',
  GU: 'Guam',
  HK: 'Hong Kong',
  HM: 'Heard saaret ja Mc Donald Islands',
  HT: 'Haiti',
  MK: 'Makedonia',
  ML: 'Mali',
  MN: 'Mongolia',
  HU: 'Unkari',
  ID: 'Indonesia',
  IE: 'Irlanti',
  IL: 'Israel',
  IN: 'Intia',
  IQ: 'Irak',
  MT: 'Malta',
  MV: 'Malediivit',
  MX: 'Meksiko',
  NC: 'Uusi-Caledonia',
  NI: 'Nicaragua',
  NZ: 'Uusi Seelanti',
  PE: 'Peru',
  PL: 'Puola',
  PT: 'Portugali',
  PW: 'Palau',
  CR: 'Costa Rica',
  CZ: 'Tšekin tasavalta',
  DZ: 'Algeria',
  ET: 'Etiopia',
  FR: 'Ranska',
  RO: 'Romania',
  RU: 'Venäjä',
  SA: 'Saudi-Arabia',
  SB: 'Solomonsaaret',
  SC: 'Seychellit',
  SD: 'Sudan',
  SE: 'Ruotsi',
  SG: 'Singapore',
  SI: 'Slovenia',
  SJ: 'Svalbard ja Jan Mayen saaret',
  SK: 'Slovakia',
  SL: 'Sierra Leone',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'Sao Tome ja Principe',
  SV: 'El Salvador',
  SZ: 'Swazimaa',
  TC: 'Turks-ja Caicossaaret',
  TD: 'Tšad',
  TF: 'French Southern Territo-ries',
  TG: 'Mennä',
  TH: 'Thaimaa',
  TK: 'Tokelau',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TP: 'Itä-Timor',
  TT: 'Trinidad ja Tobago',
  UA: 'Ukraina',
  US: 'Yhdysvallat',
  UZ: 'Uzbekistan',
  VA: 'Vatikaani',
  VE: 'Venezuela',
  VG: 'Britannian Neitsytsaaret',
  VU: 'Vanuatu',
  YE: 'Jemen',
  YT: 'Mayotte',
  YU: 'Jugoslavia',
  ZA: 'Etelä-Afrikka',
  ZW: 'Zimbabwe',
  GB: 'Yhdistynyt kuningaskunta',
  BO: 'Bolivia',
  BT: 'Bhutan',
  BY: 'Valko-Venäjä',
  CC: 'Cocos(Keeling) Islands',
  CL: 'Chile',
  CO: 'Kolumbia',
  TW: 'Taiwan, Kiina',
  CY: 'Kypros',
  DK: 'Tanska',
  DO: 'Dominikaaninen tasavalta',
  ES: 'Espanja',
  FI: 'Suomi',
  FK: 'Malvinassaaret (Falklandinsaaret)',
  GE: 'Georgia',
  GL: 'Grönlanti',
  GN: 'Guinea',
  GS: 'Etelä-Georgia ja Eteläiset Sandwichsaaret',
  KW: 'Kuwait',
  KZ: 'Kazakstan',
  LT: 'Liettua',
  LY: 'Libya',
  MH: 'Marshallsaaret',
  MO: 'Macao',
  MU: 'Mauritius',
  MY: 'Malesia',
  NA: 'Namibia',
  NU: 'Niue',
  OM: 'Oman',
  PK: 'Pakistan',
  PR: 'Puerto Rico',
  AT: 'Itävalta',
  BD: 'Bangladesh',
  CH: 'Sveitsi',
  CS: 'Joulusaari',
  FM: 'Micronesia',
  IO: 'British Indian Ocean Territory',
  KI: 'Kiribati',
  LC: 'Saint Lucia',
  MA: 'Marokko',
  MM: 'Myanmar',
  MS: 'Montserrat',
  NG: 'Nigeria',
  PH: 'Filippiinit',
  RE: 'Reunion',
  WS: 'Länsi-Samoa',
  AE: 'Yhdistyneet Arabiemiirikunnat',
  AS: 'American Samoa',
  BE: 'Belgia',
  BN: 'Brunei Darussalam',
  CM: 'Kamerun',
  DJ: 'Djibouti',
  ER: 'Eritrea',
  GF: 'Ranskan Guyana',
  GW: 'Guine-bissau',
  HR: 'Kroatia',
  IS: 'Islanti',
  KH: 'Kambodža',
  KY: 'Caymansaaret',
  LR: 'Liberia',
  MQ: 'Martinique',
  MZ: 'Mosambik',
  PA: 'Panama',
  PN: 'Pitcairn Islands Group',
  RW: 'Ruanda',
  SH: 'Saint Helena',
  SM: 'San Marion',
  SY: 'Syyria',
  TJ: 'Tadžikistan',
  TZ: 'Tansania',
  EE: 'Viro',
  LI: 'Liechtenstein',
  NF: 'Norfolk Island',
  PY: 'Paraguay',
  GP: 'Guadeloupe',
  UM: 'United States miscella-neous Pac',
  NP: 'Nepal',
  PM: 'Saint Pierre ja Miquelon',
  GQ: 'Päiväntasaajan Guinea',
  GY: 'Guyana',
  KG: 'Kirgyzstan',
  KN: 'Saint Kitts ja Nevis',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Monaco',
  MG: 'Madagaskar',
  MR: 'Mauritania',
  MW: 'Malawi',
  NE: 'Niger',
  NO: 'Norja',
  NR: 'Nauru',
  PG: 'Papua-Uusi-Guinea',
  PS: 'Palestiina',
  QA: 'Qatar',
  RS: 'Serbia',
  MP: 'Pohjois-Mariaanit',
  NL: 'Alankomaat',
  PF: 'Ranskan Polynesia',
  AM: 'Armenia',
  BR: 'Brasilia',
  DM: 'Dominica',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Turkki',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UY: 'Uruguay',
  VC: 'Saint Vincent ja Grenadiinit',
  VI: 'Yhdysvaltain Neitsytsaaret',
  WF: 'Wallis- ja Futuna-saaret',
  ZM: 'Sambia',
  FJ: 'Fiji',
  GH: 'Ghana',
  GT: 'Guatemala',
  VN: 'Vietnam',
  ZR: 'Zaire',
};
