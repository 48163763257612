import zhLocale from 'element-ui/lib/locale/lang/ko';

import country from './country/ko';
import state from './state/ko';

export default {
  message: {
    title: '계산대',
    powerByOnerway: '<span class="ic-powerby"></span> 의 기술 지원 제공',
    checkout: {
      timeTip:
        '<span class="time">{time}</span> 분 이내에 결제를 완료하지 않으면 페이지가 타임아웃됩니다.',
      billingAddressTip: '청구서는 청구서에 {billingAddress}로 표시됩니다',
      cardInfo: '카드 정보',
      cardNumber: '카드 번호',
      prepaidNumber: '카드 번호',
      summary: '주문 요약',
      expiredDate: '만료일',
      issueDate: 'Issue date',
      cvv: 'CVC/CVV',
      firstName: '이름',
      lastName: '성',
      holderName: '카드 소지자 이름',
      walletAccountName: '이름',
      orderno: '주문 번호',
      website: 'URL',
      amount: '양',
      pay: '결제',
      payNow: '즉시 지불',
      update: '업데이트',
      deduction: '결제 금액',
      nextDeductionTime: '다음 결제 날짜',
      subscriptionEndTime: '구독 만료 날짜',
      frequency: '매',
      week: '주',
      day: '일',
      month: '월',
      year: '년',
      cancel: '취소',
      tip: '힌트',
      cancelPayTip: '이 결제를 취소하시겠습니까?',
      yes: '네',
      no: '아니요',
      subscription: '신청',
      schedule: '지불 기간',
      endTime: '마감 시간',
      subscriptionTip:
        '결제를 확인하면 판매자가 약관에 따라 향후 카드를 인출할 수 있습니다.',
      frequencyData: {
        D: '{time}일/시간',
      },
      endTimeData: '{year}년{month}월{day}일',
      billingAddress: '청구서 수신 주소/연락처 정보',
      country: '국가',
      province: '주/도/지역',
      address: '주소',
      city: '도시',
      postalCode: '우편번호',
      email: '우편',
      phone: '전화 번호',
      street: '거리 번호',
      number: '집 번호',
      identityNumber: '세금번호',
      intallment: '각색',
      intallmentPlan: '할부계획',
      paymentMethod: '결제수단',
      bankName: '은행 이름',
      iBan: '은행 계좌',
      contactName: '담당자 이름',
      birthDate: '생일',
      paymentCompleted: '결제 완료',
      wechatPaymentTip: '위챗으로 QR코드 스캔해서 결제하세요',
      paymentPending: '지불을 기다리다',
      billingCountry: '청구 국가',
      billingCountryTip:
        '귀하에게 적합한 지불 방법을 추천할 수 있도록 먼저 청구 국가를 선택하십시오.',
      nextStep: '다음 단계',
      changeCountryTip: '국가를 변경한 후 적합한 결제 방법을 추천해 드립니다.',
      scanCode: '스캔',
      txnTime: '거래 시간',
      moreInfo: '추가 정보',
      applePayNotSupport: '이 기기는 Apple Pay를 지원하지 않습니다.',
      policy: {
        prefix: '',
        suffix: '읽었으며 이에 동의합니다.',
      },
      privacy: '개인정보 보호정책을',
      intallmentTip: '할부금에 대한 이자를 지불해야 할 수도 있습니다.',
      payInFull: '전액 결제',
      payIn: '{count}단계로 결제',
      blikcode: 'BLIK 코드',
      unavailable: '사용 불가',
      notSupportPaymentTips:
        '결제 방법을 현재 사용할 수 없습니다. 선택 페이지로 돌아가십시오.',
      returnText: '선택으로 돌아가기',
      returnMerchant: '사용자 페이지로 돌아가기',
      cvvTips:
        'CVC 코드는 카드 뒷면의 흰색 서명란 오른쪽에 위치합니다. Visa 및 Mastercard의 경우 항상 마지막 3자리 숫자입니다. American Express의 경우 카드 앞면에 4자리 숫자로 표시됩니다.',
      holderNameTips: '카드상의 전체 이름',
      continue: '계속하다',
      validateCVVTitle: '보안 확인',
      validateCVVDesc: '결제 보안을 위해 카드의 CVC/CVV를 확인해 주세요',
      // 订阅产品
      subscriptionProduct: '구독 상품',
      // 订阅费用
      subscriptionFee: '구독 금액',
      // 更新成功
      updateSuccess: '업데이트 성공',
      // 更新失败
      updateFailed: '업데이트 실패',
      // 次
      times: '회',
    },
    dlocal: {
      amount: '분량',
      expirationDate: '마감',
      ticketNumber: '티켓 번호',
      ticketImage: '티켓 이미지',
      downloadTicket: '다운로드 티켓',
      searchForShops: '결제 상점 검색',
      fullBoletoView: '전체Boleto보기',
      back: '돌아 올',
      copySuccess: '복사',
      errorMsg: '시간 초과. 매개변수를 가져오지 못했습니다.',
      instructionsTitle: '결제 지침',
      instructionsContentPIX:
        'ID 결제 지침:<br/>1. 복제 거래 ID<br/>2. 해당 ID로 은행 앱에서 PIX 결제를 확인하거나 은행 앱의 QR 코드를 읽습니다.',
      instructionsContentRedpagos:
        '결제를 하려면 레드파고스 매장을 방문해야 합니다.<br/>신분증 번호를 말하고 현지 청구서 결제를 원한다고 밝히면 결제할 수 있습니다',
      instructionsContentOXXO:
        '1. 가장 가까운 옥소 매장으로 이동합니다.<br/>2. 상자에 옥소페이 결제를 원한다고 표시합니다.<br/>3. 3. 이 카드의 참조 번호를 점원에게 알려주어 입력합니다.<br/>판매 화면에서 직접 입력합니다.<br/>4. 해당 금액을 현금으로 결제합니다.<br/>5. 결제를 확인하면 계산원이 인쇄된 바우처를 제공합니다. 여기에서 결제가 올바르게 완료되었는지 확인할 수 있습니다. 이 결제 증빙을 보관하세요.<br/>6. 결제에 대한 자세한 내용은 고객센터를 참조하세요.',
      instructionsContentSPEI:
        '1. 즉시 인증.<br/>2. 은행 송금이 접수되면 dlocal에서 구매 중인 제품/서비스 배송을 알려드립니다.<br/>3. 결제에 문제가 있는 경우 고객 서비스 포털을 방문하세요.',
      instructionsContentSencillito:
        '직접 지불 지시:<br/>Sencillito의 사업장에서는 참조 번호를 제공하고 DLOCAL 송장을 지불하겠다는 것을 분명히 함으로써 지불을 할 수 있습니다.<br/>온라인 결제 지시:<br/>결제를 하려면 포털 Sencillito에 들어가서 회사 검색 엔진에서"dlocal"을 선택하고 바우처 134216에 표시된 코드를 입력해야 합니다.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: '은행 세부사항',
      bankDetailsSPEI:
        '온라인 뱅킹에 로그인하여 다음 계좌로 이체하세요.현금결제는 불가하오니 이점 유의하여 주시기 바랍니다.<br/>목적은행: <span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>수혜자: <span class="detail-value">{beneficiary}</span><br/>금액: <span class="detail-value">{amount} {currency}</span><br/>지불 개념: <span class="detail-value">{concept}</span><br/>참조: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: '지불 유형',
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: '금액',
      orderTips: '다음 계좌로 이체해 주세요',
      numberTitle: '계좌 번호(이 거래에만 해당)',
      copyBtnText: '계좌 번호 복사',
      expireText: '주문 만료 시간 {time}',
      bankName: '은행 이름',
    },
    date: {
      month: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
      },
    },
    result: {
      success: '결제 성공',
      failed: '결제 실패',
      unknow: '결제',
      unknowTip: '결제 결과를 확인하려면 주문 상태를 새로고침하세요',
      jump: '<span class="hightlight">{time}s</span>후 상인에게로 돌아갑니다',
      reason: '이유',
      processing: '처리중....',
      timeout: '페이지 시간이 초과되었습니다. 결제를 다시 시작하십시오',
      invalid: '잘못된 거래 요청',
      cancelled: '결제 신청이 취소되었습니다, 다시 결제하지 마세요',
      emptyPaymentMethod: '이 국가에 대해 구성된 결제 수단이 없습니다.',
      error:
        '오류. 쇼핑 사이트의 주문 페이지에서 거래 결과를 확인하시기 바랍니다.',
    },
    validator: {
      input: '유효한 {field}을 입력하십시오',
      intallmentCardOrCountryNotSupport:
        '카드 종류 또는 국가별로 할부 결제가 지원되지 않습니다',
    },
    error: {
      timeout: '네트워크 오류입니다. 나중에 다시 시도해 주세요.',
      cardNumber: '카드 정보가 잘못되었습니다',
    },
    country,
    state,
  },
  ...zhLocale,
};
