export default {
  AD: 'อันดอร์รา',
  AF: 'อัฟกานิสถาน',
  EG: 'อียิปต์',
  EH: 'ซาฮาร่าตะวันตก',
  FO: 'หมู่เกาะแฟโร',
  GA: 'กาบอง',
  GD: 'เกรเนดา',
  GI: 'ยิบรอลตาร์',
  GM: 'แกมเบีย',
  AG: 'แอนติกาและบาร์บูดา',
  AI: 'แองกวิลลา',
  AL: 'แอลเบเนีย',
  AN: 'เนเธอร์แลนด์',
  AO: 'แองโกลา',
  AQ: 'แอนตาร์กติกา',
  AR: 'อาร์เจนตินา',
  AU: 'ออสเตรเลีย',
  AW: 'อารูบา',
  AZ: 'อาเซอร์ไบจาน',
  BA: 'บอสเนียและเฮอร์เซโก',
  BB: 'บาร์เบโดส',
  BF: 'บูร์กินาฟาโซ',
  BG: 'บัลแกเรีย',
  BH: 'บาห์เรน',
  BI: 'บุรุนดี',
  BJ: 'เบนิน',
  BM: 'เบอร์มิวดา',
  BS: 'บาฮามาส',
  BV: 'เกาะบูเวต',
  BW: 'บอตสวานา',
  BZ: 'เบลีซ',
  CA: 'แคนาดา',
  CF: 'แอฟริกากลาง',
  CG: 'คองโก',
  CI: 'โกตดิวัวร์',
  CK: 'หมู่เกาะคุก',
  CN: 'จีน',
  CU: 'คิวบา',
  CV: 'เคปเวิร์ด',
  DE: 'เยอรมนี',
  EC: 'เอกวาดอร์',
  GR: 'กรีซ',
  IR: 'อิหร่าน',
  IT: 'อิตาลี',
  JM: 'จาเมกา',
  JO: 'จอร์แดน',
  JP: 'ญี่ปุ่น',
  KE: 'เคนยา',
  KM: 'คอโมโรส',
  KP: 'เกาหลีเหนือ',
  KR: 'เกาหลีใต้',
  LB: 'เลบานอน',
  LS: 'เลโซโท',
  LU: 'ลักเซมเบิร์ก',
  LV: 'ลัตเวีย',
  MD: 'มอลโดวา',
  GU: 'กวม',
  HK: 'ฮ่องกง',
  HM: 'หมู่เกาะเฮิร์ดและหมู่เกาะแมคโดนัลด์',
  HT: 'เฮติ',
  MK: 'มาซิโดเนีย',
  ML: 'มาลี',
  MN: 'มองโกเลีย',
  HU: 'ฮังการี',
  ID: 'อินโดนีเซีย',
  IE: 'ไอร์แลนด์',
  IL: 'อิสราเอล',
  IN: 'อินเดีย',
  IQ: 'อิรัก',
  MT: 'มอลตา',
  MV: 'มัลดีฟส์',
  MX: 'เม็กซิโก',
  NC: 'นิวแคลิโดเนีย',
  NI: 'นิการากัว',
  NZ: 'นิวซีแลนด์',
  PE: 'เปรู',
  PL: 'โปแลนด์',
  PT: 'โปรตุเกส',
  PW: 'ปาเลา',
  CR: 'คอสตาริกา',
  CZ: 'สาธารณรัฐเช็ก',
  DZ: 'แอลจีเรีย',
  ET: 'เอธิโอเปีย',
  FR: 'ฝรั่งเศส',
  RO: 'โรมาเนีย',
  RU: 'รัสเซีย',
  SA: 'ซาอุดิอาราเบีย',
  SB: 'หมู่เกาะโซโลมอน',
  SC: 'เซเชลส์',
  SD: 'ซูดาน',
  SE: 'สวีเดน',
  SG: 'สิงคโปร์',
  SI: 'สโลวีเนีย',
  SJ: 'หมู่เกาะสฟาลบาร์และยานไมเอน',
  SK: 'สโลวาเกีย',
  SL: 'เซียร์ราลีโอน',
  SN: 'เซเนกัล',
  SO: 'โซมาเลีย',
  SR: 'ซูรินาเม',
  ST: 'เซาตูเมและปรินซิปี',
  SV: 'เอลซัลวาดอร์',
  SZ: 'สวาซิแลนด์',
  TC: 'หมู่เกาะเติกส์และหมู่เกาะเคคอส',
  TD: 'ชาด',
  TF: 'เฟรนช์เซาเทิร์นเทร์ริโตรีส์',
  TG: 'ไป',
  TH: 'ประเทศไทย',
  TK: 'โตเกเลา',
  TM: 'เติร์กเมนิสถาน',
  TN: 'ตูนิเซีย',
  TP: 'ติมอร์ตะวันออก',
  TT: 'ตรินิแดดและโตเบโก',
  UA: 'ยูเครน',
  US: 'สหรัฐ',
  UZ: 'อุซเบกิสถาน',
  VA: 'วาติกัน',
  VE: 'เวเนซุเอลา',
  VG: 'หมู่เกาะบริติชเวอร์จิน',
  VU: 'วานูอาตู',
  YE: 'เยเมน',
  YT: 'มายอต',
  YU: 'ยูโกสลาเวีย',
  ZA: 'แอฟริกาใต้',
  ZW: 'ซิมบับเว',
  GB: 'ประเทศอังกฤษ',
  BO: 'โบลิเวีย',
  BT: 'ภูฏาน',
  BY: 'เบลารุส',
  CC: 'หมู่เกาะโคโคส (คีลิง)',
  CL: 'ชิลี',
  CO: 'โคลัมเบีย',
  TW: 'ไต้หวัน, จีน',
  CY: 'ไซปรัส',
  DK: 'เดนมาร์ก',
  DO: 'สาธารณรัฐโดมินิกัน',
  ES: 'สเปน',
  FI: 'ฟินแลนด์',
  FK: 'หมู่เกาะมัลวินัส (หมู่เกาะฟอล์กแลนด์)',
  GE: 'จอร์เจีย',
  GL: 'กรีนแลนด์',
  GN: 'กินี',
  GS: ' หมู่เกาะเซาท์จอร์เจียและหมู่เกาะเซาท์แซนด์วิช',
  KW: 'คูเวต',
  KZ: 'คาซัคสถาน',
  LT: 'ลิทัวเนีย',
  LY: 'ลิเบีย',
  MH: 'หมู่เกาะมาร์แชลล์',
  MO: 'มาเก๊า',
  MU: 'มอริเชียส',
  MY: 'มาเลเซีย',
  NA: 'นามิเบีย',
  NU: 'นีอูเอ',
  OM: 'โอมาน',
  PK: 'ปากีสถาน',
  PR: 'เปอร์โตริโก้',
  AT: 'ออสเตรีย',
  BD: 'บังคลาเทศ',
  CH: 'สวิตเซอร์แลนด์',
  CS: 'เกาะคริสต์มาส',
  FM: 'ไมโครนีเซีย',
  IO: 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
  KI: 'คิริบาส',
  LC: 'นักบุญลูเซีย',
  MA: 'โมร็อกโก',
  MM: 'พม่า',
  MS: 'มอนต์เซอร์รัต',
  NG: 'ไนจีเรีย',
  PH: 'ฟิลิปปินส์',
  RE: 'เรอูนียง',
  WS: 'ซามัวตะวันตก',
  AE: 'สหรัฐอาหรับเอมิเรตส์',
  AS: 'อเมริกันซามัว',
  BE: 'เบลเยี่ยม',
  BN: 'บรูไนดารุสซาลาม',
  CM: 'แคเมอรูน',
  DJ: 'จิบูตี',
  ER: 'เอริเทรีย',
  GF: 'เฟรนช์เกีย',
  GW: 'กินีบิสเซา',
  HR: 'โครเอเชีย',
  IS: 'ไอซ์แลนด์',
  KH: 'กัมพูชา',
  KY: 'หมู่เกาะเคย์เเมน',
  LR: 'ไลบีเรีย',
  MQ: 'มาร์ตินีก',
  MZ: 'โมซัมบิก',
  PA: 'ปานามา',
  PN: 'กลุ่มหมู่เกาะพิตแคร์น',
  RW: 'รวันดา',
  SH: 'นักบุญเฮเลน่า',
  SM: 'ซานมาริออน',
  SY: 'ซีเรีย',
  TJ: 'ทาจิกิสถาน',
  TZ: 'แทนซาเนีย',
  EE: 'เอสโตเนีย',
  LI: 'ลิกเตนสไตน์',
  NF: 'เกาะนอร์ฟอล์ก',
  PY: 'ประเทศปารากวัย',
  GP: 'กวาเดอลูป',
  UM: 'Pac เบ็ดเตล็ดของสหรัฐอเมริกา',
  NP: 'เนปาล',
  PM: 'นักบุญปิแอร์และมีเกอลง',
  GQ: 'อิเควทอเรียลกินี',
  GY: 'กายอานา',
  KG: 'คีร์กีซสถาน',
  KN: 'เซนต์คิตส์และเนวิส',
  LA: 'ลาว',
  LK: 'ศรีลังกา',
  MC: 'โมนาโก',
  MG: 'มาดากัสการ์',
  MR: 'มอริเตเนีย',
  MW: 'มาลาวี',
  NE: 'ไนเจอร์',
  NO: 'นอร์เวย์',
  NR: 'นาอูรู',
  PG: 'ปาปัวนิวกินี',
  PS: 'ปาเลสไตน์',
  QA: 'กาตาร์',
  RS: 'เซอร์เบีย',
  MP: 'นอร์เทิร์นมาเรียนา',
  NL: 'เนเธอร์แลนด์',
  PF: 'เฟรนช์โปลินีเซีย',
  AM: 'อาร์เมเนีย',
  BR: 'บราซิล',
  DM: 'โดมินิกา',
  HN: 'ฮอนดูรัส',
  TO: 'ตองกา',
  TR: 'ไก่งวง',
  TV: 'ตูวาลู',
  UG: 'ยูกันดา',
  UY: 'อุรุกวัย',
  VC: 'เซนต์วินเซนต์และเกรนาดีนส์',
  VI: 'หมู่เกาะเวอร์จินของสหรัฐอเมริกา',
  WF: 'หมู่เกาะวาลลิสและฟุตูนา',
  ZM: 'แซมเบีย',
  FJ: 'ฟิจิ',
  GH: 'กานา',
  GT: 'กัวเตมาลา',
  VN: 'เวียดนาม',
  ZR: 'ซาอีร์',
};
