import { getQueryString } from '@/utils/tools/utils';

let queryLang = getQueryString('lang');
queryLang = queryLang && queryLang.toLowerCase();
let browserLang = window.navigator.language && window.navigator.language.toLowerCase();
const supportLang = ['de', 'en', 'es', 'fr', 'it', 'nl', 'no', 'pl', 'pt', 'fi', 'sv', 'ru', 'ar', 'th', 'zh', 'zh-cn', 'th-tw', 'ja', 'ko'];
if (queryLang === 'zh') {
  queryLang = 'zh-cn';
}
if (browserLang === 'zh') {
  browserLang = 'zh-cn';
}
if (browserLang && (browserLang.indexOf('pt-') === 0)) { // 匹配 pt-br/pt-pt
  browserLang = 'pt';
}
export default {
  namespaced: true,
  state: ({
    currentLan: (window && window.localStorage && window.localStorage.getItem('lang')) || (window && window.sessionStorage && window.sessionStorage.getItem('lang')) || (supportLang.includes(queryLang) && queryLang) || (supportLang.includes(browserLang) && browserLang) || 'en',
    supportCards: [],
    brandInfo: { init: true },
    gaDeviceinfo: {},
  }),
  mutations: {
    setLang: (state, lang) => {
      state.currentLan = lang;
    },
    setCards: (state, cards) => {
      state.supportCards = cards;
    },
    setBrandInfo: (state, info) => {
      state.brandInfo = info || {};
      if (info && info.color) {
        window.document.documentElement.style.setProperty('--color-primary', info.color);
      }
    },
    setGaDeviceinfo: (state, info) => {
      state.gaDeviceinfo = info || {};
    },
  },
  actions: {
    setLang: ({ commit }, lang) => {
      commit('setLang', lang);
    },
    setCards: ({ commit }, cards) => {
      commit('setCards', cards);
    },
    setBrandInfo: ({ commit }, info) => {
      commit('setBrandInfo', info);
    },
    setGaDeviceinfo: ({ commit }, info) => {
      commit('setGaDeviceinfo', info);
    },
    getGaDeviceinfo: ({ commit }, info) => {
      commit('setGaDeviceinfo', info);
    },
  },
  getters: {
    gaDeviceinfo: state => state.gaDeviceinfo,
  },
};
