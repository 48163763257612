import zhLocale from 'element-ui/lib/locale/lang/sv-SE';

import country from './country/sv';
import state from './state/sv';

export default {
  message: {
    title: 'Kassa',
    powerByOnerway: 'Drivs av <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Slutför betalningen inom <span class="time">{time}</span> minuter, annars försvinner sidan',
      billingAddressTip:
        'Debiteringen kommer att visas på din faktura som {billingAddress}',
      cardInfo: 'Kortinformation',
      cardNumber: 'Kortnummer',
      prepaidNumber: 'Kortnummer',
      summary: 'Ordersammanfattning',
      expiredDate: 'Giltighetstid',
      issueDate: 'Issue date',
      cvv: 'CVC/CVV',
      firstName: 'Förnamn',
      lastName: 'Efternamn',
      holderName: 'Kortinnehavarens namn',
      walletAccountName: 'Namn',
      orderno: 'Beställnings ID',
      website: 'Hemsida',
      amount: 'Belopp',
      pay: 'Betala',
      payNow: 'Betala nu',
      update: 'Uppdatera',
      deduction: 'Debiterat Belopp',
      nextDeductionTime: 'Nästa Debiteringsdatum',
      subscriptionEndTime: 'Prenumerationsslutdatum',
      frequency: 'Varje',
      week: 'Vecka',
      day: 'Dag',
      month: 'Månad',
      year: 'År',
      cancel: 'Avbryt',
      tip: 'Dricks',
      cancelPayTip: 'Vill du avbryta den här betalningen',
      yes: 'Ja',
      no: 'Nej',
      subscription: 'Prenumeration',
      schedule: 'Betalningsperiod',
      endTime: 'Sluttid',
      subscriptionTip:
        'När du har bekräftat betalningen tillåter du säljaren att debitera ditt kort enligt villkoren i framtiden.',
      frequencyData: {
        D: '{time}dag/tid',
      },
      endTimeData: '{year}år{month}månad{day}dag',
      billingAddress: 'Faktureringsadress/Kontaktinformation',
      country: 'Land',
      province: 'Delstat/Provins/Region',
      address: 'Adress',
      city: 'Stad',
      postalCode: 'Postnummer',
      email: 'E-post',
      phone: 'Telefonnummer',
      street: 'Gatanummer',
      number: 'Husnummer',
      identityNumber: 'Skattenummer',
      intallment: 'Iscensättning',
      intallmentPlan: 'Avbetalningsplan',
      paymentMethod: 'Betalningsmetod',
      bankName: 'Bank name',
      iBan: 'Bankkontonummer',
      contactName: 'Kontaktnamn',
      birthDate: 'Födelsedatum',
      paymentCompleted: 'Betalning genomförd',
      wechatPaymentTip:
        'Vänligen använd WeChat för att skanna QR-koden för att betala',
      paymentPending: 'vänta på betalning',
      billingCountry: 'Faktureringsland',
      billingCountryTip:
        'Välj faktureringsland först, så att vi kan rekommendera en lämplig betalningsmetod för dig.',
      nextStep: 'Nästa steg',
      changeCountryTip:
        'Efter att ha bytt land kommer vi att rekommendera lämpliga betalningsmetoder till dig.',
      scanCode: 'Skanna den',
      txnTime: 'Transaktionstid',
      moreInfo: 'Mer information',
      applePayNotSupport: 'Den här enheten stöder inte Apple Pay.',
      policy: {
        prefix: 'Jag har läst och godkänner ',
        suffix: '',
      },
      privacy: 'integritetspolicyn',
      intallmentTip: 'Du kan behöva betala ränta på avbetalningarna.',
      payInFull: 'Betala i sin helhet',
      payIn: 'Betala in {count}',
      blikcode: 'BLIK-kod',
      unavailable: 'Inte tillgänglig',
      notSupportPaymentTips:
        'Betalningsmetoden är inte tillgänglig för närvarande. Vänligen återgå till val-sidan.',
      returnText: 'Återgå till val',
      returnMerchant: 'Tillbaka till återförsäljarsidan',
      cvvTips:
        'CVC-koden finns på baksidan av ditt kort på höger sida av den vita signaturremsan; det är alltid de sista 3 siffrorna för Visa och Mastercard. För American Express är det 4 siffror på framsidan av kortet.',
      holderNameTips: 'Fullständigt namn på kortet',
      continue: 'Fortsätt',
      validateCVVTitle: 'Säkerhetsverifiering',
      validateCVVDesc:
        'För din betalnings säkerhet, verifiera ditt korts CVC/CVV',
      // 订阅产品
      subscriptionProduct: 'Prenumerationsprodukt',
      // 订阅费用
      subscriptionFee: 'Prenumerationsavgift',
      // 更新成功
      updateSuccess: 'Uppdatering lyckades',
      // 更新失败
      updateFailed: 'Uppdatering misslyckades',
      // 次
      times: 'Gånger',
    },
    dlocal: {
      amount: 'belopp',
      expirationDate: 'Tidsfrist',
      ticketNumber: 'Biljettnummer',
      ticketImage: 'Bild på biljett',
      downloadTicket: 'Ladda ner biljett',
      searchForShops: 'sök efter butiker',
      fullBoletoView: 'Fullständig utsikt över Boleto',
      back: 'återvända',
      copySuccess: 'Kopiera',
      errorMsg: 'Timeout. Misslyckades med att hämta parametrar.',
      instructionsTitle: 'Betalningsinstruktion',
      instructionsContentPIX:
        'ID Betalningsinstruktioner:<br/>1. Transaktions-ID för replikering<br/>2. Använd ID för att identifiera dina PIX-betalningar i din bankapp eller läs QR-koden i bankens app.',
      instructionsContentRedpagos:
        'Du måste gå till en redpagos-butik för att göra betalningen.<br/>Genom att säga ditt id-kortsnummer och förtydliga att du vill betala en dlocal-faktura kan du göra betalningen.',
      instructionsContentOXXO:
        '1. Gå till närmaste oxxo-butik.<br/>2. Ange i rutan att du vill göra en oxxopay-betalning.<br/>3. Diktera referensnumret på detta kort till kassörskan för att skriva<br/>Direkt på försäljningsskärmen.<br/>4. Gör motsvarande betalning med kontanter.<br/>5. När du bekräftar din betalning ger kassörskan dig en utskriven voucher. Här kan du kontrollera att det har gått rätt till. Spara detta bevis på betalningen.<br/>6. För mer information om din betalning besök vårt hjälpcenter',
      instructionsContentSPEI:
        '1. Omedelbar ackreditering.<br/>2. När banköverföringen har mottagits kommer dlocal att informera leveransen om den produkt/tjänst du köper.<br/>3. Om du har några betalningsproblem, vänligen besök vår kundtjänstportal.',
      instructionsContentSencillito:
        'Betalningsinstruktion personligen:<br/>På Sencillitos verksamhetsställe kan du göra en betalning genom att ange ett referensnummer och göra det tydligt att du vill betala DLOCAL-fakturan.<br/>Instruktion för onlinebetalning:<br/>För att göra betalningen måste du gå in på Portal Sencillito, välja "dlocal" i företagets sökmotor och ange koden som anges på kupongen 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: 'Bankuppgifter',
      bankDetailsSPEI:
        'Logga in på din internetbank och överför till nästa konto. Observera att kontant betalning inte är möjlig.<br/>Destinationsbank:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Stödmottagare: <span class="detail-value">{beneficiary}</span><br/>Belopp: <span class="detail-value">{amount} {currency}</span><br/>Betalningskoncept: <span class="detail-value">{concept}</span><br/>Referens: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: 'Typ av betalning',
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: 'Belopp',
      orderTips: 'Vänligen överför till följande konto',
      numberTitle: 'Kontonummer (endast för denna transaktion)',
      copyBtnText: 'Kopiera kontonummer',
      expireText: 'Beställningen går ut om {time}',
      bankName: 'Banknamn',
    },
    date: {
      month: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
        10: '10',
        11: '11',
        12: '12',
      },
    },
    result: {
      success: 'Betalning lyckad',
      failed: 'Betalning misslyckades',
      unknow: 'Betalar',
      unknowTip:
        'Vänligen uppdatera beställningsstatusen för att kontrollera betalningsresultatet',
      jump: 'Återvänder till handlaren om <span class="hightlight">{time}s</span>',
      reason: 'Anledning',
      processing: 'Bearbetar...',
      timeout: 'Sidan tar slut, vänligen initiera betalningen igen',
      invalid: 'Ogiltig transaktionsbegäran',
      cancelled:
        'Betalningsansökan har avbrutits, vänligen upprepa inte betalningen',
      emptyPaymentMethod:
        'Det finns inga betalningsmetoder konfigurerade för det här landet.',
      error:
        'Fel. Kontrollera transaktionsresultatet på beställningssidan på shoppingwebbplatsen.',
    },
    validator: {
      input: 'Vänligen ange ett giltigt {field}',
      intallmentCardOrCountryNotSupport:
        'Delbetalning stöds inte av korttyp eller land',
    },
    error: {
      timeout: 'Nätverket är onormalt, försök igen senare',
      cardNumber: 'Kortinformationen är felaktig',
    },
    country,
    state,
  },
  ...zhLocale,
};
