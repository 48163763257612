<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { getDevice } from '@/utils/tools/utils';
export default {
  created() {
    try {
      const device = getDevice();
      // const geolocation = this.getGeolocation();
      const gaDeviceInfo = {
        user_agent: window.navigator.userAgent,
        // geolocation,
      };
      if (device.device === 'WAP') {
        gaDeviceInfo.device_type = 'Mobile';
      } else {
        gaDeviceInfo.device_type = 'PC';
      }
      this.$store.dispatch('app/setGaDeviceinfo', gaDeviceInfo);
    } catch (error) {
    }
  },
  methods: {
    getGeolocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          // return { Lat: latitude, Lon: longitude };
          return `${latitude}_${longitude}`;
        });
      }
      // return {};
      return '';
    },
  },
};
</script>
