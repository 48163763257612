export default {
  AD: 'Andorra',
  AF: 'Afghanistan',
  EG: 'Egypte',
  EH: 'Westelijke Sahara',
  FO: 'Faeröer Eilanden',
  GA: 'Gabon',
  GD: 'Grenada',
  GI: 'Gibraltar',
  GM: 'Gambia',
  AG: 'Antigua en Barbuda',
  AI: 'Anguilla',
  AL: 'Albanië',
  AN: 'Nederlandse Antillen',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentinië',
  AU: 'Australië',
  AW: 'Aruba',
  AZ: 'Azerbeidzjan',
  BA: 'Bosnië-Herzegovina',
  BB: 'Barbados',
  BF: 'Burkina Faso',
  BG: 'Bulgarije',
  BH: 'Bahrein',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BS: 'Bahamas',
  BV: 'Bouvet-eiland',
  BW: 'Botswana',
  BZ: 'Belize',
  CA: 'Canada',
  CF: 'Centraal Afrika',
  CG: 'Congo',
  CI: 'Ivoorkust',
  CK: 'Cook Eilanden',
  CN: 'China',
  CU: 'Cuba',
  CV: 'Kaapverdië',
  DE: 'Duitsland',
  EC: 'Ecuador',
  GR: 'Griekenland',
  IR: 'Iraan',
  IT: 'Italië',
  JM: 'Jamaica',
  JO: 'Jordanië',
  JP: 'Japan',
  KE: 'Kenia',
  KM: 'Comoren',
  KP: 'Noord Korea',
  KR: 'Zuid-Korea',
  LB: 'Libanon',
  LS: 'Lesotho',
  LU: 'Luxemburg',
  LV: 'Letland',
  MD: 'Moldavië',
  GU: 'Guam',
  HK: 'Hongkong',
  HM: 'Gehoord-eilanden en Mc Donald-eilanden',
  HT: 'Haïti',
  MK: 'Macedonië',
  ML: 'Mali',
  MN: 'Mongolië',
  HU: 'Hongarije',
  ID: 'Indonesië',
  IE: 'Ierland',
  IL: 'Israël',
  IN: 'Indië',
  IQ: 'Irak',
  MT: 'Malta',
  MV: 'Maldiven',
  MX: 'Mexico',
  NC: 'Nieuw-Caledonië',
  NI: 'Nicaragua',
  NZ: 'Nieuw-Zeeland',
  PE: 'Peru',
  PL: 'Polen',
  PT: 'Portugal',
  PW: 'Palau',
  CR: 'Costa Rica',
  CZ: 'Tsjechië',
  DZ: 'Algerije',
  ET: 'Ethiopië',
  FR: 'Frankrijk',
  RO: 'Roemenië',
  RU: 'Rusland',
  SA: 'Saoedi-Arabië',
  SB: 'Solomon eilanden',
  SC: 'Seychellen',
  SD: 'Soedan',
  SE: 'Zweden',
  SG: 'Singapore',
  SI: 'Slovenië',
  SJ: 'Svalbard en jan Mayen Islands',
  SK: 'Slowakije',
  SL: 'Sierra Leone',
  SN: 'Senegal',
  SO: 'Somalië',
  SR: 'Suriname',
  ST: 'Sao Tomé en Principe',
  SV: 'El Salvador',
  SZ: 'Swaziland',
  TC: 'Turken- en Caicoseilanden',
  TD: 'Tsjaad',
  TF: 'Franse zuidelijke gebieden',
  TG: 'Gaan',
  TH: 'Thailand',
  TK: 'Tokelau',
  TM: 'Turkmenistan',
  TN: 'Tunesië',
  TP: 'Oost Timor',
  TT: 'Trinidad en Tobago',
  UA: 'Oekraïne',
  US: 'Verenigde Staten',
  UZ: 'Oezbekistan',
  VA: 'Vaticaan',
  VE: 'Venezuela',
  VG: 'Britse Maagdeneilanden',
  VU: 'Vanuatu',
  YE: 'Jemen',
  YT: 'Mayotte',
  YU: 'Joegoslavië',
  ZA: 'Zuid-Afrika',
  ZW: 'Zimbabwe',
  GB: 'Verenigd Koningkrijk',
  BO: 'Bolivia',
  BT: 'Bhutan',
  BY: 'Wit-Rusland',
  CC: 'Cocos (Keeling) Eilanden',
  CL: 'Chili',
  CO: 'Colombia',
  TW: 'Taiwan, China',
  CY: 'Cyprus',
  DK: 'Denemarken',
  DO: 'Dominicaanse Republiek',
  ES: 'Spanje',
  FI: 'Finland',
  FK: 'Malvinas-eilanden (Falklandeilanden)',
  GE: 'Georgië',
  GL: 'Groenland',
  GN: 'Guinea',
  GS: 'Zuid-Georgia en Zuid-Sandwicheilanden',
  KW: 'Koeweit',
  KZ: 'Kazachstan',
  LT: 'Litouwen',
  LY: 'Libië',
  MH: 'Marshall eilanden',
  MO: 'Macao',
  MU: 'Mauritius',
  MY: 'Maleisië',
  NA: 'Namibië',
  NU: 'Neen',
  OM: 'Oman',
  PK: 'Pakistan',
  PR: 'Puerto Rico',
  AT: 'Oostenrijk',
  BD: 'Bangladesh',
  CH: 'Zwitserland',
  CS: 'Kersteiland',
  FM: 'Micronesië',
  IO: 'Brits-Indisch oceaan gebied',
  KI: 'Kiribati',
  LC: 'Sint-Lucia',
  MA: 'Marokko',
  MM: 'Myanmar',
  MS: 'Montserrat',
  NG: 'Nigeria',
  PH: 'Filippijnen',
  RE: 'Bijeenkomst',
  WS: 'West-Samoa',
  AE: 'Verenigde Arabische Emiraten',
  AS: 'Amerikaans Samoa',
  BE: 'België',
  BN: 'Brunei Darussalam',
  CM: 'Kameroen',
  DJ: 'Djibouti',
  ER: 'Eritrea',
  GF: 'Frans Guyana',
  GW: 'Guine-bissau',
  HR: 'Kroatië',
  IS: 'IJsland',
  KH: 'Cambodja',
  KY: 'Kaaiman Eilanden',
  LR: 'Liberia',
  MQ: 'Martinique',
  MZ: 'Mozambique',
  PA: 'Panama',
  PN: 'Pitcairneilanden Groep',
  RW: 'Rwanda',
  SH: 'Sint-helena',
  SM: 'San Marion',
  SY: 'Syrië',
  TJ: 'Tadzjikistan',
  TZ: 'Tanzania',
  EE: 'Estland',
  LI: 'Liechtenstein',
  NF: 'Norfolkeiland',
  PY: 'Paraguay',
  GP: 'Guadeloupe',
  UM: 'Verenigde Staten diversen Pac',
  NP: 'Nepal',
  PM: 'Saint-Pierre en Miquelon',
  GQ: 'Equatoriaal-Guinea',
  GY: 'Guyana',
  KG: 'Kirgizië',
  KN: 'Saint Kitts en Nevis',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Monaco',
  MG: 'Madagascar',
  MR: 'Mauritanië',
  MW: 'Malawi',
  NE: 'Niger',
  NO: 'Noorwegen',
  NR: 'Nauru',
  PG: 'Papoea-Nieuw-Guinea',
  PS: 'Palestina',
  QA: 'Qatar',
  RS: 'Servië',
  MP: 'Noordelijke Marianen',
  NL: 'Nederland',
  PF: 'Frans-Polynesië',
  AM: 'Armenië',
  BR: 'Brazilië',
  DM: 'Dominica',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Kalkoen',
  TV: 'Tuvalu',
  UG: 'Oeganda',
  UY: 'Uruguay',
  VC: 'Saint Vincent en de Grenadines',
  VI: 'Verenigde Staten Maagdeneilanden',
  WF: 'Wallis en Futuna-eilanden',
  ZM: 'Zambia',
  FJ: 'Fiji',
  GH: 'Ghana',
  GT: 'Guatemala',
  VN: 'Vietnam',
  ZR: 'Zaïre',
};
