import Vue from 'vue';

export default function initIEPlaceholder() {
  // input/textarea
  Vue.directive('placeholder', {
    inserted(el) {
      // 如果兼容placeholder则终止
      if (('placeholder' in document.createElement('input'))) {
        return false;
      }
      const isReadonly = el.getAttribute('readonly');
      if (isReadonly) {
        return false;
      }
      const input = el.querySelector('input') || el.querySelector('textarea'); // input或者textarea
      const placeholder = input.getAttribute('placeholder');
      const span = document.createElement('span');
      span.className = 'ie-placeholder';
      span.innerText = placeholder;
      input.parentNode.style.position = 'relative';
      input.parentNode.appendChild(span);
      el.addEventListener('click', (event) => {
        if (event.target.nodeName === 'SPAN') {
          input.focus();
        }
      });
      span.addEventListener('click', () => {
        input.focus();
      });
      input.onblur = (event) => {
        if (el.className.indexOf('el-select') > -1) {
          setTimeout(() => {
            if (!event.target.value) {
              span.style.display = 'inline';
            } else {
              span.style.display = 'none';
            }
          }, 200);
        } else {
          if (!event.target.value) {
            span.style.display = 'inline';
          }
        }
      };
      input.oninput = (event) => {
        console.log('oninput...', event.target);
        if (event.target.value) {
          span.style.display = 'none';
        } else {
          span.style.display = 'inline';
        }
      };
    },
    unbind(el) {
      const input = el.querySelector('input') || el.querySelector('textarea');
      input.onfocus = input.onblur = input.oninput = null;
    },
  });
}
