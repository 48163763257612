export default {
  AD: 'Andorra',
  AF: 'Afeganistão',
  EG: 'Egito',
  EH: 'Saara Ocidental',
  FO: 'Ilhas Faroe',
  GA: 'Gabão',
  GD: 'Granada',
  GI: 'Gibraltar',
  GM: 'Gâmbia',
  AG: 'Antígua e Barbuda',
  AI: 'Anguila',
  AL: 'Albânia',
  AN: 'Antilhas Holandesas',
  AO: 'Angola',
  AQ: 'Antártica',
  AR: 'Argentina',
  AU: 'Austrália',
  AW: 'Aruba',
  AZ: 'Azerbaijão',
  BA: 'Bósnia e Herzegovina',
  BB: 'Barrados',
  BF: 'Burkina Faso',
  BG: 'Bulgária',
  BH: 'Bahrein',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermudas',
  BS: 'Bahamas',
  BV: 'Ilha Bouvet',
  BW: 'Botsuana',
  BZ: 'Belize',
  CA: 'Canadá',
  CF: 'África Central',
  CG: 'Congo',
  CI: 'Cote d\'Ivoire',
  CK: 'Ilhas Cook',
  CN: 'China',
  CU: 'Cuba',
  CV: 'Cabo Verde',
  DE: 'Alemanha',
  EC: 'Equador',
  GR: 'Grécia',
  IR: 'Irã',
  IT: 'Itália',
  JM: 'Jamaica',
  JO: 'Jordânia',
  JP: 'Japão',
  KE: 'Quênia',
  KM: 'Comores',
  KP: 'Coréia do Norte',
  KR: 'Coreia do Sul',
  LB: 'Líbano',
  LS: 'Lesoto',
  LU: 'Luxemburgo',
  LV: 'Letônia',
  MD: 'Moldávia',
  GU: 'Guam',
  HK: 'Hong Kong',
  HM: 'Ilhas Heard e Ilhas Mc Donald',
  HT: 'Haiti',
  MK: 'Macedônia',
  ML: 'Mali',
  MN: 'Mongólia',
  HU: 'Hungria',
  ID: 'Indonésia',
  IE: 'Irlanda',
  IL: 'Israel',
  IN: 'Índia',
  IQ: 'Iraque',
  MT: 'Malta',
  MV: 'Maldivas',
  MX: 'México',
  NC: 'Nova Caledônia',
  NI: 'Nicarágua',
  NZ: 'Nova Zelândia',
  PE: 'Peru',
  PL: 'Polônia',
  PT: 'Portugal',
  PW: 'Palaú',
  CR: 'Costa Rica',
  CZ: 'República Tcheca',
  DZ: 'Argélia',
  ET: 'Etiópia',
  FR: 'França',
  RO: 'Romênia',
  RU: 'Rússia',
  SA: 'Arábia Saudita',
  SB: 'Ilhas Salomão',
  SC: 'Seychelles',
  SD: 'Sudão',
  SE: 'Suécia',
  SG: 'Cingapura',
  SI: 'Eslovênia',
  SJ: 'Ilhas Svalbard e jan Mayen',
  SK: 'Eslováquia',
  SL: 'Serra Leoa',
  SN: 'Senegal',
  SO: 'Somália',
  SR: 'Suriname',
  ST: 'São Tomé e Príncipe',
  SV: 'El Salvador',
  SZ: 'Suazilândia',
  TC: 'Ilhas Turcas e Caicos',
  TD: 'Chade',
  TF: 'Territórios do Sul da França',
  TG: 'Ir',
  TH: 'Tailândia',
  TK: 'Toquela',
  TM: 'Turquemenistão',
  TN: 'Tunísia',
  TP: 'Timor Leste',
  TT: 'Trinidad e Tobago',
  UA: 'Ucrânia',
  US: 'Estados Unidos',
  UZ: 'Uzbequistão',
  VA: 'Vaticano',
  VE: 'Venezuela',
  VG: 'Ilhas Virgens Britânicas',
  VU: 'Vanuatu',
  YE: 'Iémen',
  YT: 'Maiote',
  YU: 'Iugoslávia',
  ZA: 'África do Sul',
  ZW: 'Zimbábue',
  GB: 'Reino Unido',
  BO: 'Bolívia',
  BT: 'Butão',
  BY: 'Bielorrússia',
  CC: 'Ilhas Cocos(Keeling)',
  CL: 'Chile',
  CO: 'Colômbia',
  TW: 'Taiwan, China',
  CY: 'Chipre',
  DK: 'Dinamarca',
  DO: 'República Dominicana',
  ES: 'Espanha',
  FI: 'Finlândia',
  FK: 'Ilhas Malvinas (Ilhas Malvinas)',
  GE: 'Geórgia',
  GL: 'Gronelândia',
  GN: 'Guiné',
  GS: 'Ilhas Geórgia do Sul e Sandwich do Sul',
  KW: 'Kuwait',
  KZ: 'Cazaquistão',
  LT: 'Lituânia',
  LY: 'Líbia',
  MH: 'Ilhas Marshall',
  MO: 'Macau',
  MU: 'Maurício',
  MY: 'Malásia',
  NA: 'Namíbia',
  NU: 'Niu',
  OM: 'Omã',
  PK: 'Paquistão',
  PR: 'Porto Rico',
  AT: 'Áustria',
  BD: 'Bangladesh',
  CH: 'Suíça',
  CS: 'Ilha do Natal',
  FM: 'Micronésia',
  IO: 'Território Britânico do Oceano Índico',
  KI: 'Kiribati',
  LC: 'Santa Lúcia',
  MA: 'Marrocos',
  MM: 'Mianmar',
  MS: 'Montserrate',
  NG: 'Nigéria',
  PH: 'Filipinas',
  RE: 'Reunião',
  WS: 'Samoa Ocidental',
  AE: 'Emirados Árabes Unidos',
  AS: 'Samoa Americana',
  BE: 'Bélgica',
  BN: 'Brunei Darussalam',
  CM: 'Camarões',
  DJ: 'Djibuti',
  ER: 'Eritreia',
  GF: 'Guiana Francesa',
  GW: 'Guiné-bissau',
  HR: 'Croácia',
  IS: 'Islândia',
  KH: 'Camboja',
  KY: 'Ilhas Cayman',
  LR: 'Libéria',
  MQ: 'Martinica',
  MZ: 'Moçambique',
  PA: 'Panamá',
  PN: 'Grupo das Ilhas Pitcairn',
  RW: 'Ruanda',
  SH: 'Santa Helena',
  SM: 'São Marion',
  SY: 'Síria',
  TJ: 'Tajiquistão',
  TZ: 'Tanzânia',
  EE: 'Estônia',
  LI: 'Liechtenstein',
  NF: 'Ilha Norfolk',
  PY: 'Paraguai',
  GP: 'Guadalupe',
  UM: 'Pac diversos Estados Unidos',
  NP: 'Nepal',
  PM: 'São Pedro e Miquelon',
  GQ: 'Guiné Equatorial',
  GY: 'Guiana',
  KG: 'Quirguistão',
  KN: 'São Cristóvão e Nevis',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Mônaco',
  MG: 'Madagáscar',
  MR: 'Mauritânia',
  MW: 'Malawi',
  NE: 'Níger',
  NO: 'Noruega',
  NR: 'Nauru',
  PG: 'Papua Nova Guiné',
  PS: 'Palestina',
  QA: 'Catar',
  RS: 'Sérvia',
  MP: 'Marinas do Norte',
  NL: 'Países Baixos',
  PF: 'Polinésia Francesa',
  AM: 'Armênia',
  BR: 'Brasil',
  DM: 'Dominica',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Turquia',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UY: 'Uruguai',
  VC: 'São Vicente e Granadinas',
  VI: 'Ilhas Virgens dos Estados Unidos',
  WF: 'Ilhas Wallis e Futuna',
  ZM: 'Zâmbia',
  FJ: 'Fiji',
  GH: 'Gana',
  GT: 'Guatemala',
  VN: 'Viet Nam',
  ZR: 'Zaire',
};
