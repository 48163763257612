export default {
  AD: 'Andorre',
  AF: 'Afghanistan',
  EG: 'Égypte',
  EH: 'Sahara occidental',
  FO: 'Îles Féroé',
  GA: 'Gabon',
  GD: 'Grenade',
  GI: 'Gibraltar',
  GM: 'Gambie',
  AG: 'Antigua-et-Barbuda',
  AI: 'Anguille',
  AL: 'Albanie',
  AN: 'Antilles néerlandaises',
  AO: 'Angola',
  AQ: 'Antarctique',
  AR: 'Argentine',
  AU: 'Australie',
  AW: 'Aruba',
  AZ: 'Azerbaïdjan',
  BA: 'Bosnie Herzégovine',
  BB: 'Barbade',
  BF: 'Burkina Faso',
  BG: 'Bulgarie',
  BH: 'Bahreïn',
  BI: 'Burundi',
  BJ: 'Bénin',
  BM: 'Bermudes',
  BS: 'Bahamas',
  BV: 'Île Bouvet',
  BW: 'Botswana',
  BZ: 'Bélize',
  CA: 'Canada',
  CF: 'Afrique centrale',
  CG: 'Conga',
  CI: 'Côte d\'Ivoire',
  CK: 'Les Îles Cook',
  CN: 'Chine',
  CU: 'Cuba',
  CV: 'Cap-Vert',
  DE: 'Allemagne',
  EC: 'Equateur',
  GR: 'Grèce',
  IR: 'L\'Iran',
  IT: 'Italie',
  JM: 'Jamaïque',
  JO: 'Jordan',
  JP: 'Japon',
  KE: 'Kenya',
  KM: 'Comores',
  KP: 'Corée du Nord',
  KR: 'Corée du Sud',
  LB: 'Liban',
  LS: 'Lesotho',
  LU: 'Luxembourg',
  LV: 'Lettonie',
  MD: 'Moldavie',
  GU: 'Guam',
  HK: 'Hong Kong',
  HM: 'Îles Heard et îles Mc Donald',
  HT: 'Haïti',
  MK: 'Macédoine',
  ML: 'Mali',
  MN: 'Mongolie',
  HU: 'Hongrie',
  ID: 'Indonésie',
  IE: 'Irlande',
  IL: 'Israël',
  IN: 'Inde',
  IQ: 'Irak',
  MT: 'Malte',
  MV: 'Maldives',
  MX: 'Mexique',
  NC: 'Nouvelle Calédonie',
  NI: 'Nicaragua',
  NZ: 'Nouvelle-Zélande',
  PE: 'Pérou',
  PL: 'Pologne',
  PT: 'Le Portugal',
  PW: 'Palaos',
  CR: 'Costa Rica',
  CZ: 'République tchèque',
  DZ: 'Algérie',
  ET: 'Ethiopie',
  FR: 'La France',
  RO: 'Roumanie',
  RU: 'Russie',
  SA: 'Arabie Saoudite',
  SB: 'Îles Salomon',
  SC: 'Seychelles',
  SD: 'Soudan',
  SE: 'La Suède',
  SG: 'Singapour',
  SI: 'Slovénie',
  SJ: 'Îles Svalbard et Jan Mayen',
  SK: 'Slovaquie',
  SL: 'Sierra Leone',
  SN: 'Sénégal',
  SO: 'Somalie',
  SR: 'Surinam',
  ST: 'Sao Tomé et Principe',
  SV: 'Le Salvador',
  SZ: 'Swaziland',
  TC: 'Îles Turques-et-Caïques',
  TD: 'Tchad',
  TF: 'Territoires Sud Français',
  TG: 'Aller',
  TH: 'Thaïlande',
  TK: 'Tokélaou',
  TM: 'Turkménistan',
  TN: 'Tunisie',
  TP: 'Timor oriental',
  TT: 'Trinité-et-Tobago',
  UA: 'Ukraine',
  US: 'États-Unis',
  UZ: 'Ouzbékistan',
  VA: 'Vatican',
  VE: 'Venezuela',
  VG: 'Îles Vierges britanniques',
  VU: 'Vanuatu',
  YE: 'Yémen',
  YT: 'Mayotte',
  YU: 'Yougoslavie',
  ZA: 'Afrique du Sud',
  ZW: 'Zimbabwe',
  GB: 'Royaume-Uni',
  BO: 'Bolivie',
  BT: 'Bhoutan',
  BY: 'Biélorussie',
  CC: 'Îles Cocos (Keeling)',
  CL: 'Chili',
  CO: 'Colombie',
  TW: 'Taïwan, Chine',
  CY: 'Chypre',
  DK: 'Danemark',
  DO: 'République Dominicaine',
  ES: 'Espagne',
  FI: 'Finlande',
  FK: 'îles Malvinas (îles Falkland)',
  GE: 'Géorgie',
  GL: 'Groenland',
  GN: 'Guinée',
  GS: 'Géorgie du Sud et Îles Sandwich du Sud',
  KW: 'Koweit',
  KZ: 'Kazakhstan',
  LT: 'Lituanie',
  LY: 'Libye',
  MH: 'Iles Marshall',
  MO: 'Macao',
  MU: 'Maurice',
  MY: 'Malaisie',
  NA: 'Namibie',
  NU: 'Niué',
  OM: 'Oman',
  PK: 'Pakistan',
  PR: 'Porto Rico',
  AT: 'L\'Autriche',
  BD: 'Bangladesh',
  CH: 'La Suisse',
  CS: 'L\'île de noël',
  FM: 'Micronésie',
  IO: 'Territoire britannique de l\'océan Indien',
  KI: 'Kiribati',
  LC: 'Sainte-Lucie',
  MA: 'Maroc',
  MM: 'Birmanie',
  MS: 'Montserrat',
  NG: 'Nigeria',
  PH: 'Philippines',
  RE: 'Réunion',
  WS: 'Samoa occidentales',
  AE: 'Emirats Arabes Unis',
  AS: 'Samoa américaines',
  BE: 'Belgique',
  BN: 'Brunei Darussalam',
  CM: 'Cameroun',
  DJ: 'Djibouti',
  ER: 'Érythrée',
  GF: 'Guyane Française',
  GW: 'Guine-bissau',
  HR: 'Croatie',
  IS: 'Islande',
  KH: 'Cambodge',
  KY: 'Îles Caïmans',
  LR: 'Libéria',
  MQ: 'Martinique',
  MZ: 'Mozambique',
  PA: 'Panama',
  PN: 'Groupe des îles Pitcairn',
  RW: 'Rwanda',
  SH: 'Sainte Hélène',
  SM: 'San Marion',
  SY: 'Syrie',
  TJ: 'Tadjikistan',
  TZ: 'Tanzanie',
  EE: 'Estonie',
  LI: 'Liechtenstein',
  NF: 'L\'ile de Norfolk',
  PY: 'Paraguay',
  GP: 'Guadeloupe',
  UM: 'États-Unis divers Pac',
  NP: 'Népal',
  PM: 'Saint-Pierre-et-Miquelon',
  GQ: 'Guinée Équatoriale',
  GY: 'Guyane',
  KG: 'Kirghizistan',
  KN: 'Saint-Christophe-et-Niévès',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Monaco',
  MG: 'Madagascar',
  MR: 'Mauritanie',
  MW: 'Malawi',
  NE: 'Niger',
  NO: 'Norvège',
  NR: 'Nauru',
  PG: 'Papouasie Nouvelle Guinée',
  PS: 'Palestine',
  QA: 'Qatar',
  RS: 'Serbie',
  MP: 'Mariannes du Nord',
  NL: 'Pays-Bas',
  PF: 'Polynésie française',
  AM: 'Arménie',
  BR: 'Brésil',
  DM: 'Dominique',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Dinde',
  TV: 'Tuvalu',
  UG: 'Ouganda',
  UY: 'Uruguay',
  VC: 'Saint-Vincent-et-les-Grenadines',
  VI: 'Îles Vierges des États-Unis',
  WF: 'Îles Wallis et Futuna',
  ZM: 'Zambie',
  FJ: 'Fidji',
  GH: 'Ghana',
  GT: 'Guatemala',
  VN: 'Vietnam',
  ZR: 'Zaïre',
};
