export default {
  AD: '안도라',
  AF: '아프가니스탄',
  EG: '이집트',
  EH: '서부 사하라',
  FO: '페로 제도',
  GA: '가봉',
  GD: '그레나다',
  GI: '지브롤터',
  GM: '감비아',
  AG: '앤티가 바부다',
  AI: '앵귈라',
  AL: '알바니아',
  AN: '네덜란드 령 앤 틸리 스',
  AO: '앙골라',
  AQ: '남극',
  AR: '아르헨티나',
  AU: '호주',
  AW: '아루바',
  AZ: '아제르바이잔',
  BA: '보스니아 헤르체고비나',
  BB: '바베이도스',
  BF: '부키 나 파소',
  BG: '불가리아',
  BH: '바레인',
  BI: '부룬디',
  BJ: '베냉',
  BM: '버뮤다',
  BS: '바하마',
  BV: '부베 아일랜드',
  BW: '보츠와나',
  BZ: '벨리즈',
  CA: '캐나다',
  CF: '중앙아프리카',
  CG: '콩고',
  CI: '코트디부아르',
  CK: '쿡 제도',
  CN: '중국',
  CU: '쿠바',
  CV: '카보베르데',
  DE: '독일',
  EC: '에콰도르',
  GR: '그리스',
  IR: '이란',
  IT: '이탈리아',
  JM: '자메이카',
  JO: '요르단',
  JP: '일본',
  KE: '케냐',
  KM: '코모로',
  KP: '북한',
  KR: '대한민국',
  LB: '레바논',
  LS: '레소토',
  LU: '룩셈부르크',
  LV: '라트비아',
  MD: '몰도바',
  GU: '괌',
  HK: '홍콩',
  HM: '허드 아일랜드와 맥도널드 제도',
  HT: '아이티',
  MK: '마케도니아',
  ML: '말리',
  MN: '몽골리아',
  HU: '헝가리',
  ID: '인도네시아',
  IE: '아일랜드',
  IL: '이스라엘',
  IN: '인도',
  IQ: '이라크',
  MT: '몰타',
  MV: '몰디브',
  MX: '멕시코',
  NC: '뉴 칼레도니아',
  NI: '니카라과',
  NZ: '뉴질랜드',
  PE: '페루',
  PL: '폴란드',
  PT: '포르투갈',
  PW: '팔라우',
  CR: '코스타리카',
  CZ: '체코 공화국',
  DZ: '알제리',
  ET: '에티오피아',
  FR: '프랑스',
  RO: '루마니아',
  RU: '러시아',
  SA: '사우디 아라비아',
  SB: '솔로몬 제도',
  SC: '세이셸',
  SD: '수단',
  SE: '스웨덴',
  SG: '싱가포르',
  SI: '슬로베니아',
  SJ: '스발바르와 얀마옌 제도',
  SK: '슬로바키아',
  SL: '시에라리온',
  SN: '세네갈',
  SO: '소말리아',
  SR: '수리남',
  ST: '상투메 프린시페',
  SV: '엘살바도르',
  SZ: '스와질랜드',
  TC: '터크스케이커스 제도',
  TD: '차드',
  TF: '프랑스 남부 지역',
  TG: '토고',
  TH: '태국',
  TK: '토켈라우',
  TM: '투르크메니스탄',
  TN: '튀니지',
  TP: '동 티모르',
  TT: '트리니다드 토바고',
  UA: '우크라이나',
  US: '미국',
  UZ: '우즈베키스탄',
  VA: '바티칸 궁전',
  VE: '베네수엘라',
  VG: '영국령 버진 아일랜드',
  VU: '바누아투',
  YE: '예멘',
  YT: '마요트',
  YU: '유고슬라비아',
  ZA: '남아프리카',
  ZW: '짐바브웨',
  GB: '영국',
  BO: '볼리비아',
  BT: '부탄',
  BY: '벨라루스',
  CC: '코코스(킬링) 제도',
  CL: '칠레',
  CO: '콜롬비아',
  TW: '대만, 중국',
  CY: '키프로스',
  DK: '덴마크',
  DO: '도미니카 공화국',
  ES: '스페인',
  FI: '핀란드',
  FK: '말비나스 제도(포클랜드 제도)',
  GE: '그루지야',
  GL: '그린란드',
  GN: '기니',
  GS: '사우스 조지아와 사우스 샌드위치 제도',
  KW: '쿠웨이트',
  KZ: '카자흐스탄',
  LT: '리투아니아',
  LY: '리비아',
  MH: '마셜 제도',
  MO: '마카오',
  MU: '모리셔스',
  MY: '말레이시아',
  NA: '나미비아',
  NU: '니우에',
  OM: '오만',
  PK: '파키스탄',
  PR: '푸에르토 리코',
  AT: '오스트리아',
  BD: '방글라데시',
  CH: '스위스',
  CS: '크리스마스 아일랜드',
  FM: '미크로네시아',
  IO: '영국령 인도양 영토',
  KI: '키리바시',
  LC: '성 루시아',
  MA: '모로코',
  MM: '미얀마',
  MS: '몬세라트',
  NG: '나이지리아',
  PH: '필리핀 제도',
  RE: '재결합',
  WS: '서사모아',
  AE: '아랍 에미리트',
  AS: '아메리칸 사모아',
  BE: '벨기에',
  BN: '브루나이 다루살람',
  CM: '카메룬',
  DJ: '지부티',
  ER: '에리트레아',
  GF: '프랑스령 기아나',
  GW: '기네비사우',
  HR: '크로아티아',
  IS: '아이슬란드',
  KH: '캄보디아',
  KY: '케이맨 제도',
  LR: '라이베리아',
  MQ: '마르티니크',
  MZ: '모잠비크',
  PA: '파나마',
  PN: '핏케언 제도 그룹',
  RW: '르완다',
  SH: '세인트 헬레나',
  SM: '산 마리온',
  SY: '시리아',
  TJ: '타지키스탄',
  TZ: '탄자니아',
  EE: '에스토니아',
  LI: '리히텐슈타인',
  NF: '노퍽 섬',
  PY: '파라과이',
  GP: '과들루프',
  UM: '미국 기타 Pac',
  NP: '네팔',
  PM: '생 피에르와 미클롱',
  GQ: '적도 기니',
  GY: '가이아나',
  KG: '키르기스스탄',
  KN: '세인트 키츠와 네비스',
  LA: '라오',
  LK: '스리랑카',
  MC: '모나코',
  MG: '마다가스카르',
  MR: '모리타니',
  MW: '말라위',
  NE: '니제르',
  NO: '노르웨이',
  NR: '나우루',
  PG: '파푸아 뉴기니',
  PS: '팔레스타인',
  QA: '카타르',
  RS: '세르비아',
  MP: '북마리아나 제도',
  NL: '네덜란드',
  PF: '프랑스 령 폴리네시아의',
  AM: '아르메니아',
  BR: '브라질',
  DM: '도미니카',
  HN: '온두라스',
  TO: '통가',
  TR: '칠면조',
  TV: '투발루',
  UG: '우간다',
  UY: '우루과이',
  VC: '세인트 빈센트 그레나딘',
  VI: '미국령 버진 아일랜드',
  WF: '월리스와 푸투나 제도',
  ZM: '잠비아',
  FJ: '피지',
  GH: '가나',
  GT: '과테말라',
  VN: '베트남',
  ZR: '자이르',
};
