export default {
  AD: 'Andorra',
  AF: 'Afghanistan',
  EG: 'Egypt',
  EH: 'Western Sahara',
  FO: 'Faroe Islands',
  GA: 'Gabon',
  GD: 'Grenada',
  GI: 'Gibraltar',
  GM: 'Gambia',
  AG: 'Antigua and Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AN: 'Netherlands Antilles',
  AO: 'Angola',
  AQ: 'Antarctica',
  AR: 'Argentina',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbaijan',
  BA: 'Bosnia and Herzegovina',
  BB: 'Barbados',
  BF: 'Burkina Faso',
  BG: 'Bulgaria',
  BH: 'Bahrain',
  BI: 'Burundi',
  BJ: 'Benin',
  BM: 'Bermuda',
  BS: 'Bahamas',
  BV: 'Bouvet Island',
  BW: 'Botswana',
  BZ: 'Belize',
  CA: 'Canada',
  CF: 'Central Africa',
  CG: 'Congo',
  CI: 'Cote d\'Ivoire',
  CK: 'Cook Islands',
  CN: 'China',
  CU: 'Cuba',
  CV: 'Cape Verde',
  DE: 'Germany',
  EC: 'Ecuador',
  GR: 'Greece',
  IR: 'Iran',
  IT: 'Italy',
  JM: 'Jamaica',
  JO: 'Jordan',
  JP: 'Japan',
  KE: 'Kenya',
  KM: 'Comoros',
  KP: 'North Korea',
  KR: 'South Korea',
  LB: 'Lebanon',
  LS: 'Lesotho',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MD: 'Moldova',
  GU: 'Guam',
  HK: 'Hong Kong',
  HM: 'Heard islands and Mc Donald Islands',
  HT: 'Haiti',
  MK: 'Macedonia',
  ML: 'Mali',
  MN: 'Mongolia',
  HU: 'Hungary',
  ID: 'Indonesia',
  IE: 'Ireland',
  IL: 'Israel',
  IN: 'India',
  IQ: 'Iraq',
  MT: 'Malta',
  MV: 'Maldives',
  MX: 'Mexico',
  NC: 'New Caledonia',
  NI: 'Nicaragua',
  NZ: 'New Zealand',
  PE: 'Peru',
  PL: 'Poland',
  PT: 'Portugal',
  PW: 'Palau',
  CR: 'Costa Rica',
  CZ: 'Czech Repoublic',
  DZ: 'Algeria',
  ET: 'Ethiopia',
  FR: 'France',
  RO: 'Romania',
  RU: 'Russia',
  SA: 'Saudi Arabia',
  SB: 'Solomon Islands',
  SC: 'Seychells',
  SD: 'Sudan',
  SE: 'Sweden',
  SG: 'Singapore',
  SI: 'Slovenia',
  SJ: 'Svalbard and jan Mayen Islands',
  SK: 'Slovakia',
  SL: 'Sierra leone',
  SN: 'Senegal',
  SO: 'Somalia',
  SR: 'Suriname',
  ST: 'Sao Tome and Principe',
  SV: 'El Salvador',
  SZ: 'Swaziland',
  TC: 'Turks and Caicos Islands',
  TD: 'Chad',
  TF: 'French Southern Territo-ries',
  TG: 'Togo',
  TH: 'Thailand',
  TK: 'Tokelau',
  TM: 'Turkmenistan',
  TN: 'Tunisia',
  TP: 'East Timor',
  TT: 'Trinidad and Tobago',
  UA: 'Ukraine',
  US: 'United States',
  UZ: 'Uzbekistan',
  VA: 'Vatican',
  VE: 'Venezuela',
  VG: 'British Virgin Islands',
  VU: 'Vanuatu',
  YE: 'Yemen',
  YT: 'Mayotte',
  YU: 'Yugoslavia',
  ZA: 'South Africa',
  ZW: 'Zimbabwe',
  GB: 'United Kingdom',
  BO: 'Bolivia',
  BT: 'Bhutan',
  BY: 'Belarus',
  CC: 'Cocos(Keeling) Islands',
  CL: 'Chile',
  CO: 'Colombia',
  TW: 'Taiwan,China',
  CY: 'Cyprus',
  DK: 'Denmark',
  DO: 'Dominican Republic',
  ES: 'Spain',
  FI: 'Finland',
  FK: 'Malvinas islands (Falkland Islands)',
  GE: 'Georgia',
  GL: 'Greenland',
  GN: 'Guinea',
  GS: 'South Georgia and South Sandwich Islands',
  KW: 'Kuwait',
  KZ: 'Kazakhstan',
  LT: 'Lithuania',
  LY: 'Libya',
  MH: 'Marshall Islands',
  MO: 'Macau',
  MU: 'Mauritius',
  MY: 'Malaysia',
  NA: 'Namibia',
  NU: 'Niue',
  OM: 'Oman',
  PK: 'Pakistan',
  PR: 'Puerto Rico',
  AT: 'Austria',
  BD: 'Bangladesh',
  CH: 'Switzerland',
  CS: 'Christmas Island',
  FM: 'Micronesia',
  IO: 'British indian Ocean Ter-ritory',
  KI: 'Kiribati',
  LC: 'Saint lucia',
  MA: 'Morocco',
  MM: 'Myanmar',
  MS: 'Montserrat',
  NG: 'Nigeria',
  PH: 'Philippines',
  RE: 'Reunion',
  WS: 'Western Samoa',
  AE: 'United Arab Emirates',
  AS: 'American Samoa',
  BE: 'Belgium',
  BN: 'Brunei Darussalam',
  CM: 'Cameroon',
  DJ: 'Djibouti',
  ER: 'Eritrea',
  GF: 'French Guiana',
  GW: 'Guine-bissau',
  HR: 'Croatia',
  IS: 'Iceland',
  KH: 'Cambodia',
  KY: 'Cayman Islands',
  LR: 'Liberia',
  MQ: 'Martinique',
  MZ: 'Mozambique',
  PA: 'Panama',
  PN: 'Pitcairn Islands Group',
  RW: 'Rwanda',
  SH: 'Saint helena',
  SM: 'San Marion',
  SY: 'Syria',
  TJ: 'Tajikistan',
  TZ: 'Tanzania',
  EE: 'Estonia',
  LI: 'Liechtenstein',
  NF: 'Norfolk Island',
  PY: 'Paraguay',
  GP: 'Guadeloupe',
  UM: 'United States miscella-neous Pac',
  NP: 'Nepal',
  PM: 'Saint Pierre and Miquelon',
  GQ: 'Equatorial Guinea',
  GY: 'Guyana',
  KG: 'Kyrgyzstan',
  KN: 'Saint Kitts and nevis',
  LA: 'Lao',
  LK: 'Sri Lanka',
  MC: 'Monaco',
  MG: 'Madagascar',
  MR: 'Mauritania',
  MW: 'Malawi',
  NE: 'Niger',
  NO: 'Norway',
  NR: 'Nauru',
  PG: 'Papua New Guinea',
  PS: 'Palestine',
  QA: 'Qatar',
  RS: 'Serbia',
  MP: 'Northern Marianas',
  NL: 'Netherlands',
  PF: 'French Polynesia',
  AM: 'Armenia',
  BR: 'Brazil',
  DM: 'Dominica',
  HN: 'Honduras',
  TO: 'Tonga',
  TR: 'Turkey',
  TV: 'Tuvalu',
  UG: 'Uganda',
  UY: 'Uruguay',
  VC: 'Saint Vincent and the Grenadines',
  VI: 'United States Virgin Is-lands',
  WF: 'Wallis and Futuna Is-lands',
  ZM: 'Zambia',
  FJ: 'Fiji',
  GH: 'Ghana',
  GT: 'Guatemala',
  VN: 'Viet Nam',
  ZR: 'Zaire',
};
