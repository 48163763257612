export default {
  US: {
    AL: 'แอละแบมา',
    AK: 'อลาสก้า',
    AS: 'อเมริกันซามัว',
    AZ: 'แอริโซนา',
    AR: 'อาร์คันซอ',
    AA: 'กองทัพอเมริกา',
    AE: 'กองทัพยุโรป',
    AP: 'กองทัพแปซิฟิก',
    CA: 'แคลิฟอร์เนีย',
    CO: 'โคโลราโด',
    CT: 'คอนเนตทิคัต',
    DE: 'เดลาแวร์',
    DC: 'เขตโคลัมเบีย',
    FM: 'สหพันธรัฐไมโครนีเซีย',
    FL: 'ฟลอริดา',
    GA: 'จอร์เจีย',
    GU: 'กวม',
    HI: 'ฮาวาย',
    ID: 'ไอดาโฮ',
    IL: 'อิลลินอยส์',
    IN: 'อินเดียน่า',
    IA: 'ไอโอวา',
    KS: 'แคนซัส',
    KY: 'เคนตักกี้',
    LA: 'หลุยเซียน่า',
    ME: 'เมน',
    MH: 'หมู่เกาะมาร์แชลล์',
    MD: 'แมริแลนด์',
    MA: 'แมสซาชูเซตส์',
    MI: 'มิชิแกน',
    MN: 'มินนิโซตา',
    MS: 'มิสซิสซิปปี้',
    MO: 'มิสซูรี',
    MT: 'มอนทาน่า',
    NE: 'เนบราสก้า',
    NV: 'เนวาดา',
    NH: 'นิวแฮมป์เชียร์',
    NJ: 'นิวเจอร์ซี',
    NM: 'นิวเม็กซิโก',
    NY: 'นิวยอร์ก',
    NC: 'นอร์ทแคโรไลนา',
    ND: 'นอร์ทดาโคตา',
    MP: 'หมู่เกาะนอร์เทิร์นมาเรียนา',
    OH: 'โอไฮโอ',
    OK: 'โอคลาโฮมา',
    OR: 'ออริกอน',
    PW: 'ปาเลา',
    PA: 'เพนซิลเวเนีย',
    PR: 'เปอร์โตริโก้',
    RI: 'โรดไอแลนด์',
    SC: 'เซาท์แคโรไลนา',
    SD: 'เซาท์ดาโคตา',
    TN: 'เทนเนสซี',
    TX: 'เท็กซัส',
    UM: 'เกาะเล็กรอบนอกของสหรัฐอเมริกา',
    UT: 'ยูทาห์',
    VT: 'เวอร์มอนต์',
    VI: 'หมู่เกาะเวอร์จิน',
    VA: 'เวอร์จิเนีย',
    WA: 'วอชิงตัน',
    WV: 'เวสต์เวอร์จิเนีย',
    WI: 'วิสคอนซิน',
    WY: 'ไวโอมิง',
  },
  CA: {
    AB: 'อัลเบอร์ต้า',
    BC: 'บริติชโคลัมเบีย',
    MB: 'แมนิโทบา',
    NB: 'นิวบรันสวิก',
    NL: 'นิวฟันด์แลนด์',
    NT: 'ดินแดนตะวันตกเฉียงเหนือ',
    NS: 'โนวาสโกเชีย',
    NU: 'หนูนาวุธ',
    ON: 'ออนแทรีโอ',
    PE: 'เจ้าชายเอ็ดเวิร์ดไอแลนด์',
    QC: 'ควิเบก',
    SK: 'ซัสแคตเชวัน',
    YT: 'ยูคอน',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
