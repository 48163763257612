export default {
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'American Samoa',
    AZ: 'Arizona',
    AR: 'Arkansas',
    AA: 'Lực lượng vũ trang Hoa Kỳ',
    AE: 'lực lượng vũ trang châu Âu',
    AP: 'lực lượng vũ trang Thái Bình Dương',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'Quận Columbia',
    FM: 'Liên bang Micronesia',
    FL: 'Florida',
    GA: 'Gruzia',
    GU: 'đảo Guam',
    HI: 'hawaii',
    ID: 'idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'đảo Marshall',
    MD: 'maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'nebraska',
    NV: 'Nevada',
    NH: 'Mới Hampshire',
    NJ: 'Áo mới',
    NM: 'Mexico mới',
    NY: 'Newyork',
    NC: 'bắc Carolina',
    ND: 'bắc dakota',
    MP: 'Quần đảo Bắc Mariana',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'đảo Rhode',
    SC: 'phía Nam Carolina',
    SD: 'Nam Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UM: 'Quần đảo xa xôi nhỏ của Hoa Kỳ',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Quần đảo Virgin',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'phia Tây Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'brunswick mới',
    NL: 'vùng đất mới',
    NT: 'Vùng Tây Bắc',
    NS: 'Nova Scotia',
    NU: 'nunavut',
    ON: 'Ontario',
    PE: 'đảo hoàng tử edward',
    QC: 'người quebec',
    SK: 'saskatchewan',
    YT: 'Yukon',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
