export default {
  AD: 'Andora',
  AF: 'Afganistan',
  EG: 'Egipt',
  EH: 'Sahara Zachodnia',
  FO: 'Wyspy Owcze',
  GA: 'Gabon',
  GD: 'Grenada',
  GI: 'Gibraltar',
  GM: 'Gambia',
  AG: 'Antigua i Barbuda',
  AI: 'Anguilla',
  AL: 'Albania',
  AN: 'Antyle Holenderskie',
  AO: 'Angola',
  AQ: 'Antarktyda',
  AR: 'Argentyna',
  AU: 'Australia',
  AW: 'Aruba',
  AZ: 'Azerbejdżan',
  BA: 'Bośnia i Hercegowina',
  BB: 'Barbados',
  BF: 'Burkina Faso',
  BG: 'Bułgaria',
  BH: 'Bahrajn',
  BI: 'burundi',
  BJ: 'Benin',
  BM: 'Bermudy',
  BS: 'Bahamy',
  BV: 'Wyspa Bouveta',
  BW: 'Botswana',
  BZ: 'Belize',
  CA: 'Kanada',
  CF: 'Afryka Centralna',
  CG: 'Kongo',
  CI: 'Wybrzeże Kości Słoniowej',
  CK: 'Wyspy Cooka',
  CN: 'Chiny',
  CU: 'Kuba',
  CV: 'Wyspy Zielonego Przylądka',
  DE: 'Niemcy',
  EC: 'Ekwador',
  GR: 'Grecja',
  IR: 'Iran',
  IT: 'Włochy',
  JM: 'Jamajka',
  JO: 'Jordania',
  JP: 'Japonia',
  KE: 'Kenia',
  KM: 'Komory',
  KP: 'Korea Północna',
  KR: 'Korea Południowa',
  LB: 'Liban',
  LS: 'Lesoto',
  LU: 'Luksemburg',
  LV: 'Łotwa',
  MD: 'Moldova',
  GU: 'Guam',
  HK: 'Hongkong',
  HM: 'Wyspy Heard i Wyspy Mc Donalda',
  HT: 'Haiti',
  MK: 'Macedonia',
  ML: 'Mali',
  MN: 'Mongolia',
  HU: 'Węgry',
  ID: 'Indonezja',
  IE: 'Irlandia',
  IL: 'Izrael',
  IN: 'Indie',
  IQ: 'Irak',
  MT: 'Malta',
  MV: 'Malediwy',
  MX: 'Meksyk',
  NC: 'Nowa Kaledonia',
  NI: 'Nikaragua',
  NZ: 'Nowa Zelandia',
  PE: 'Peru',
  PL: 'Polska',
  PT: 'Portugalia',
  PW: 'Palau',
  CR: 'Kostaryka',
  CZ: 'Republika Czeska',
  DZ: 'Algieria',
  ET: 'Etiopia',
  FR: 'Francja',
  RO: 'Rumunia',
  RU: 'Rosja',
  SA: 'Arabia Saudyjska',
  SB: 'Wyspy Salomona',
  SC: 'Seszele',
  SD: 'Sudan',
  SE: 'Szwecja',
  SG: 'Singapur',
  SI: 'Słowenia',
  SJ: 'Wyspy Svalbard i Jan Mayen',
  SK: 'Słowacja',
  SL: 'Sierra Leone',
  SN: 'Senegal',
  SO: 'Somali',
  SR: 'Surinam',
  ST: 'Wysp Świętego Tomasza i Książęca',
  SV: 'Salwador',
  SZ: 'Suazi',
  TC: 'Wyspy Turks i Caicos',
  TD: 'Czad',
  TF: 'Francuskie Terytoria Południowe',
  TG: 'Iść',
  TH: 'Tajlandia',
  TK: 'Tokelau',
  TM: 'Turkmenia',
  TN: 'Tunezja',
  TP: 'Wschodni Timor',
  TT: 'Trynidad i Tobago',
  UA: 'Ukraina',
  US: 'Stany Zjednoczone',
  UZ: 'Uzbekistan',
  VA: 'Watykan',
  VE: 'Wenezuela',
  VG: 'Brytyjskie Wyspy Dziewicze',
  VU: 'Vanuatu',
  YE: 'Jemen',
  YT: 'Majotta',
  YU: 'Jugosławia',
  ZA: 'Afryka Południowa',
  ZW: 'Zimbabwe',
  GB: 'Zjednoczone Królestwo',
  BO: 'Boliwia',
  BT: 'Bhutan',
  BY: 'Białoruś',
  CC: 'Wyspy Kokosowe (Keelinga)',
  CL: 'Chile',
  CO: 'Kolumbia',
  TW: 'Tajwan, Chiny',
  CY: 'Cypr',
  DK: 'Dania',
  DO: 'Republika Dominikany',
  ES: 'Hiszpania',
  FI: 'Finlandia',
  FK: 'Wyspy Malwiny (Falklandy)',
  GE: 'Gruzja',
  GL: 'Grenlandia',
  GN: 'Gwinea',
  GS: 'Gruzja Południowa i Sandwich Południowy',
  KW: 'Kuwejt',
  KZ: 'Kazachstan',
  LT: 'Litwa',
  LY: 'Libia',
  MH: 'Wyspy Marshalla',
  MO: 'Makau',
  MU: 'Mauritius',
  MY: 'Malezja',
  NA: 'Namibia',
  NU: 'Niue',
  OM: 'Oman',
  PK: 'Pakistan',
  PR: 'Portoryko',
  AT: 'Austria',
  BD: 'Bangladesz',
  CH: 'Szwajcaria',
  CS: 'Wyspa Bożego Narodzenia',
  FM: 'Mikronezja',
  IO: 'Brytyjskie Terytorium Oceanu Indyjskiego',
  KI: 'Kiribati',
  LC: 'Święta Lucia',
  MA: 'Maroko',
  MM: 'Myanmar',
  MS: 'Monserrat',
  NG: 'Nigeria',
  PH: 'Filipiny',
  RE: 'Zjazd',
  WS: 'Samoa Zachodnie',
  AE: 'Zjednoczone Emiraty Arabskie',
  AS: 'Samoa Amerykańskie',
  BE: 'Belgia',
  BN: 'Brunei Darussalam',
  CM: 'Kamerun',
  DJ: 'Dżibuti',
  ER: 'Erytrea',
  GF: 'Gujana Francuska',
  GW: 'Gwine Bissau',
  HR: 'Chorwacja',
  IS: 'Islandia',
  KH: 'Kambodża',
  KY: 'Kajmany',
  LR: 'Liberii',
  MQ: 'Martynika',
  MZ: 'Mozambik',
  PA: 'Panama',
  PN: 'Grupa Wysp Pitcairn',
  RW: 'Rwanda',
  SH: 'Św. Helena',
  SM: 'San Marion',
  SY: 'Syria',
  TJ: 'Tadżykistan',
  TZ: 'Tanzania',
  EE: 'Estonia',
  LI: 'Liechtenstein',
  NF: 'Wyspa Norfolk',
  PY: 'Paragwaj',
  GP: 'Gwadelupa',
  UM: 'Stany Zjednoczone różne Pac',
  NP: 'Nepal',
  PM: 'Święty Piotr i Miquelon',
  GQ: 'Gwinea Równikowa',
  GY: 'Gujana',
  KG: 'Kirgistan',
  KN: 'Saint Kitts i nevis',
  LA: 'Laos',
  LK: 'Sri Lanka',
  MC: 'Monako',
  MG: 'Madagaskar',
  MR: 'Mauretania',
  MW: 'Malawi',
  NE: 'Niger',
  NO: 'Norwegia',
  NR: 'Nauru',
  PG: 'Papua Nowa Gwinea',
  PS: 'Palestyna',
  QA: 'Katar',
  RS: 'Serbia',
  MP: 'Marianie Północne',
  NL: 'Holandia',
  PF: 'Polinezja Francuska',
  AM: 'Armenia',
  BR: 'Brazylia',
  DM: 'Dominika',
  HN: 'Honduras',
  TO: 'tonga',
  TR: 'Indyk',
  TV: 'tuvalu',
  UG: 'Uganda',
  UY: 'Urugwaj',
  VC: 'Święty Wincenty i Grenadyny',
  VI: 'Wyspy Dziewicze Stanów Zjednoczonych',
  WF: 'Wyspy Wallis i Futuna',
  ZM: 'Zambia',
  FJ: 'Fidżi',
  GH: 'Ghana',
  GT: 'Gwatemala',
  VN: 'Wietnam',
  ZR: 'Zair',
};
