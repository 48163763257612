export default {
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'Samoa americane',
    AZ: 'Arizona',
    AR: 'Arkansas',
    AA: 'Forze armate americane',
    AE: 'Forze armate europee',
    AP: 'Forze armate del Pacifico',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'Distretto della Colombia',
    FM: 'Stati Federati di Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MH: 'Isole Marshall',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'Nuovo Messico',
    NY: 'New York',
    NC: 'Carolina del Nord',
    ND: 'Nord Dakota',
    MP: 'Isole Marianne settentrionali',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palù',
    PA: 'Pennsylvania',
    PR: 'Portorico',
    RI: 'Rhode Island',
    SC: 'Carolina del Sud',
    SD: 'Sud Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UM: 'Isole minori esterne degli Stati Uniti',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Isole Vergini',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'Virginia dell\'ovest',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'British Columbia',
    MB: 'Manitoba',
    NB: 'Nuovo Brunswick',
    NL: 'Terranova',
    NT: 'Territori del Nordovest',
    NS: 'Nuova Scozia',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Isola del Principe Edoardo',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
