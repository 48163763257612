export default {
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'Samoa Amerykańskie',
    AZ: 'Arizona',
    AR: 'Arkansas',
    AA: 'Siły Zbrojne Ameryki',
    AE: 'Siły Zbrojne Europy',
    AP: 'Siły Zbrojne Pacyfiku',
    CA: 'Kalifornia',
    CO: 'Kolorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'Dystrykt Kolumbii',
    FM: 'Sfederowane Stany Mikronezji',
    FL: 'Floryda',
    GA: 'Gruzja',
    GU: 'Guam',
    HI: 'Hawaje',
    ID: 'Idaho',
    IL: 'stan Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Luizjana',
    ME: 'Maine',
    MH: 'Wyspy Marshalla',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesocie',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'Nowy Meksyk',
    NY: 'Nowy Jork',
    NC: 'Karolina Północna',
    ND: 'Północna Dakota',
    MP: 'Marianie Północne',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palau',
    PA: 'Pensylwania',
    PR: 'Portoryko',
    RI: 'Rhode Island',
    SC: 'Karolina Południowa',
    SD: 'Południowa Dakota',
    TN: 'Tennessee',
    TX: 'Teksas',
    UM: 'Stany Zjednoczone Dalekie Wyspy Mniejsze',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Wyspy Dziewicze',
    VA: 'Wirginia',
    WA: 'Waszyngton',
    WV: 'Wirginia Zachodnia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'Brytyjska Kolumbia',
    MB: 'Manitoba',
    NB: 'Nowy Brunszwik',
    NL: 'Nowa Fundlandia',
    NT: 'Północno - zachodnie terytoria',
    NS: 'Nowa Szkocja',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Wyspa Księcia Edwarda',
    QC: 'Quebek',
    SK: 'Saskatchewan',
    YT: 'Jukon',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
