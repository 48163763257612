export default {
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'Samoa Americana',
    AZ: 'Arizona',
    AR: 'arkansas',
    AA: 'Fuerzas Armadas de las Américas',
    AE: 'Fuerzas Armadas de Europa',
    AP: 'Fuerzas Armadas del Pacífico',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'Distrito de Columbia',
    FM: 'Estados Federados de Micronesia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawai',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Luisiana',
    ME: 'Maine',
    MH: 'Islas Marshall',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Misisipí',
    MO: 'Misuri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'Nuevo Hampshire',
    NJ: 'New Jersey',
    NM: 'Nuevo Mexico',
    NY: 'Nueva York',
    NC: 'Carolina del Norte',
    ND: 'Dakota del Norte',
    MP: 'Islas Marianas del Norte',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregón',
    PW: 'Palaos',
    PA: 'Pensilvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'Carolina del Sur',
    SD: 'Dakota del Sur',
    TN: 'Tennesse',
    TX: 'Texas',
    UM: 'Islas menores alejadas de los Estados Unidos',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Islas Virgenes',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'Virginia del Oeste',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'Columbia Británica',
    MB: 'Manitoba',
    NB: 'Nuevo Brunswick',
    NL: 'Terranova',
    NT: 'Territorios del Noroeste',
    NS: 'Nueva Escocia',
    NU: 'Nunavut',
    ON: 'ontario',
    PE: 'Isla del Príncipe Eduardo',
    QC: 'Quebec',
    SK: 'Saskatchewan',
    YT: 'Yukón',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
