export default {
  US: {
    AL: 'Алабама',
    AK: 'Аляска',
    AS: 'Американское Самоа',
    AZ: 'Аризона',
    AR: 'Арканзас',
    AA: 'Вооруженные силы Америки',
    AE: 'Вооруженные силы Европы',
    AP: 'Тихоокеанские вооруженные силы',
    CA: 'Калифорния',
    CO: 'Колорадо',
    CT: 'Коннектикут',
    DE: 'Делавэр',
    DC: 'Район Колумбии',
    FM: 'Федеративные Штаты Микронезии',
    FL: 'Флорида',
    GA: 'Грузия',
    GU: 'Гуам',
    HI: 'Гавайи',
    ID: 'Айдахо',
    IL: 'Иллинойс',
    IN: 'Индиана',
    IA: 'Айова',
    KS: 'Канзас',
    KY: 'Кентукки',
    LA: 'Луизиана',
    ME: 'Мэн',
    MH: 'Маршалловы острова',
    MD: 'Мэриленд',
    MA: 'Массачусетс',
    MI: 'Мичиган',
    MN: 'Миннесота',
    MS: 'Миссисипи',
    MO: 'Миссури',
    MT: 'Монтана',
    NE: 'Небраска',
    NV: 'Невада',
    NH: 'Нью-Гемпшир',
    NJ: 'Нью-Джерси',
    NM: 'Нью-Мексико',
    NY: 'Нью-Йорк',
    NC: 'Северная Каролина',
    ND: 'Северная Дакота',
    MP: 'Северные Марианские острова',
    OH: 'Огайо',
    OK: 'Оклахома',
    OR: 'Орегон',
    PW: 'Палау',
    PA: 'Пенсильвания',
    PR: 'Пуэрто-Рико',
    RI: 'Род-Айленд',
    SC: 'Южная Каролина',
    SD: 'Северная Дакота',
    TN: 'Теннесси',
    TX: 'Техас',
    UM: 'Малые отдаленные острова США',
    UT: 'Юта',
    VT: 'Вермонт',
    VI: 'Виргинские острова',
    VA: 'Вирджиния',
    WA: 'Вашингтон',
    WV: 'Западная Виргиния',
    WI: 'Висконсин',
    WY: 'Вайоминг',
  },
  CA: {
    AB: 'Альберта',
    BC: 'Британская Колумбия',
    MB: 'Манитоба',
    NB: 'Нью-Брансуик',
    NL: 'Ньюфаундленд',
    NT: 'Северо-западные территории',
    NS: 'Новая Шотландия',
    NU: 'Нунавут',
    ON: 'Онтарио',
    PE: 'Остров Принца Эдуарда',
    QC: 'Квебек',
    SK: 'Саскачеван',
    YT: 'Юкон',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
