export default {
  US: {
    AL: 'Alabama',
    AK: 'Alaska',
    AS: 'Samoa américaines',
    AZ: 'Arizona',
    AR: 'Arkansas',
    AA: 'Forces armées Amériques',
    AE: 'Forces armées européennes',
    AP: 'Forces armées du Pacifique',
    CA: 'Californie',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District de Colombie',
    FM: 'États fédérés de Micronésie',
    FL: 'Floride',
    GA: 'Géorgie',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiane',
    ME: 'Maine',
    MH: 'Iles Marshall',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'Nouveau Mexique',
    NY: 'New York',
    NC: 'Caroline du Nord',
    ND: 'Dakota du nord',
    MP: 'Îles Mariannes du Nord',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PW: 'Palaos',
    PA: 'Pennsylvanie',
    PR: 'Porto Rico',
    RI: 'Rhode Island',
    SC: 'Caroline du Sud',
    SD: 'Dakota du Sud',
    TN: 'Tennessee',
    TX: 'Texas',
    UM: 'Îles mineures éloignées des États-Unis',
    UT: 'Utah',
    VT: 'Vermont',
    VI: 'Les iles vierges',
    VA: 'Virginie',
    WA: 'Washington',
    WV: 'Virginie occidentale',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'Colombie britannique',
    MB: 'Manitoba',
    NB: 'Nouveau-Brunswick',
    NL: 'Terre-Neuve',
    NT: 'Territoires du nord-ouest',
    NS: 'Nouvelle-Écosse',
    NU: 'Nunavut',
    ON: 'Ontario',
    PE: 'Île-du-Prince-Édouard',
    QC: 'Québec',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
