export default {
  US: {
    AL: '알라바마',
    AK: '알래스카',
    AS: '아메리칸 사모아',
    AZ: '애리조나',
    AR: '아칸소',
    AA: '미주 군대',
    AE: '군대 유럽',
    AP: '태평양군',
    CA: '캘리포니아',
    CO: '콜로라도',
    CT: '코네티컷',
    DE: '델라웨어',
    DC: '컬럼비아 특별구',
    FM: '미크로네시아 연방',
    FL: '플로리다',
    GA: '그루지야',
    GU: '괌',
    HI: '하와이',
    ID: '아이다호',
    IL: '일리노이',
    IN: '인디아나',
    IA: '아이오와',
    KS: '캔자스',
    KY: '켄터키',
    LA: '루이지애나',
    ME: '메인',
    MH: '마셜 제도',
    MD: '메릴랜드',
    MA: '매사추세츠 주',
    MI: '미시간',
    MN: '미네소타',
    MS: '미시시피',
    MO: '미주리',
    MT: '몬타나',
    NE: '네브래스카',
    NV: '네바다',
    NH: '뉴햄프셔',
    NJ: '뉴저지',
    NM: '뉴 멕시코',
    NY: '뉴욕',
    NC: '노스 캐롤라이나',
    ND: '노스 다코타',
    MP: '북마리아나 제도',
    OH: '오하이오',
    OK: '오클라호마',
    OR: '오레곤',
    PW: '팔라우',
    PA: '펜실베니아',
    PR: '푸에르토 리코',
    RI: '로드 아일랜드',
    SC: '사우스 캐롤라이나',
    SD: '사우스다코타',
    TN: '테네시',
    TX: '텍사스',
    UM: '미국령 군소 제도',
    UT: '유타',
    VT: '버몬트',
    VI: '버진 아일랜드',
    VA: '여자 이름',
    WA: '워싱턴',
    WV: '웨스트 버지니아',
    WI: '위스콘신',
    WY: '와이오밍',
  },
  CA: {
    AB: '앨버타',
    BC: '브리티시 컬럼비아',
    MB: '매니토바',
    NB: '뉴 브런즈윅',
    NL: '뉴펀들랜드',
    NT: '노스웨스트 테리토리',
    NS: '노바스코샤',
    NU: '누나부트',
    ON: '온타리오',
    PE: '프린스 에드워드 아일랜드',
    QC: '퀘벡',
    SK: '서스캐처원',
    YT: '유콘',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
