import Vue from 'vue';
import VueI18n from 'vue-i18n';

import enLocal from '@/language/locales/en';

Vue.use(VueI18n);

const loadedLanguages = ['en']; // 我们的预装默认语言

export const i18n = new VueI18n({
  locale: 'en', // 设置语言环境
  fallbackLocale: 'en',
  messages: {
    en: enLocal,
  },
});

function setI18nLanguage(lang) {
  i18n.locale = lang;
  // axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang);
  try {
    window.localStorage.setItem('lang', lang);
  } catch (error) {
    console.log('Set localStorage lang error', error);
  }
  try {
    window.sessionStorage.setItem('lang', lang);
  } catch (error) {
    console.log('Set sessionStorage lang error', error);
  }
  return lang;
}

export function loadLanguageAsync(lang) {
  // 如果语言相同
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // 如果语言已经加载
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // 如果尚未加载语言
  const messages = require(`@/language/locales/${lang}.js`);
  i18n.setLocaleMessage(lang, messages.default);
  loadedLanguages.push(lang);
  return Promise.resolve(setI18nLanguage(lang));
}
