import ElementUILocal from 'element-ui/lib/locale/lang/fr';

import country from './country/fr';
import state from './state/fr';

export default {
  message: {
    title: 'Caisse',
    powerByOnerway: 'Propulsé par <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Veuillez effectuer le paiement dans les <span class="time">{time}</span> minutes, sinon la page expirera.',
      billingAddressTip:
        'Les frais apparaîtront sur votre facture sous la forme {billingAddress}.',
      cardInfo: 'Informations sur la carte',
      cardNumber: 'Numéro de carte',
      prepaidNumber: 'Numéro de carte',
      summary: 'Récapitulatif de la commande',
      expiredDate: "Date d'expiration",
      issueDate: 'Issue date',
      cvv: 'CVC/CVV',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      holderName: 'Nom du titulaire',
      walletAccountName: 'Nom',
      orderno: 'ID de commande',
      website: 'Site Internet',
      amount: 'Quantité',
      pay: 'Paiement',
      payNow: 'Payez maintenant',
      update: 'Mettre à Jour',
      deduction: 'Montant du Prélèvement',
      nextDeductionTime: 'Prochaine Date de Prélèvement',
      subscriptionEndTime: "Date de Fin d'Abonnement",
      frequency: 'Chaque',
      week: 'Semaine',
      day: 'Jour',
      month: 'Mois',
      year: 'Année',
      cancel: 'Annuler',
      tip: 'Des astuces',
      cancelPayTip: 'Voulez-vous renoncer à ce paiement ?',
      yes: 'Oui',
      no: 'Non',
      subscription: 'Abonnement',
      schedule: 'Calendrier de paiement',
      endTime: 'Date de fin',
      subscriptionTip:
        'En confirmant votre paiement, vous autorisez le commerçant à débiter votre carte pour les paiements futurs conformément à ses conditions générales.',
      frequencyData: {
        D: 'Une fois tous les {time} jours',
      },
      endTimeData: '{month} {day}, {year}',
      billingAddress: 'Adresse de facturation/Coordonnées',
      country: 'Pays',
      province: 'État/Province/Région',
      address: 'Adresse',
      city: 'Ville',
      postalCode: 'Code Postal',
      email: 'E-mail',
      phone: 'Numéro de téléphone',
      street: 'Numéro de rue',
      number: 'Nombre',
      identityNumber: "Numéro d'identification fiscale",
      intallment: 'Mise en scène',
      intallmentPlan: 'Plan de versement',
      paymentMethod: 'Payment Method',
      bankName: 'Nom de banque',
      iban: 'IBAN',
      contactName: 'Nom du contact',
      birthDate: 'Date de naissance',
      paymentCompleted: 'Paiement terminé',
      wechatPaymentTip:
        'Veuillez utiliser WeChat pour scanner le code QR pour payer',
      paymentPending: 'Attendre le paiement',
      billingCountry: 'Pays de facturation',
      billingCountryTip:
        "Veuillez d'abord sélectionner le pays de facturation afin que nous puissions vous recommander un mode de paiement approprié.",
      nextStep: "L'étape suivante",
      changeCountryTip:
        'Après avoir changé de pays, nous vous recommanderons des méthodes de paiement appropriées.',
      scanCode: 'Scannez-le',
      txnTime: 'Temps de transaction',
      moreInfo: "Plus d'information",
      applePayNotSupport: 'Cet appareil ne prend pas en charge Apple Pay.',
      policy: {
        prefix: "J'ai lu et j'accepte la ",
        suffix: '',
      },
      privacy: 'politique de confidentialité',
      intallmentTip:
        'Vous pouvez être amené à payer des intérêts sur les versements.',
      payInFull: 'Payer en totalité',
      payIn: 'Payer en {count}',
      blikcode: 'Code BLIK',
      unavailable: 'Indisponible',
      notSupportPaymentTips:
        'La méthode de paiement n’est pas disponible pour le moment. Veuillez retourner à la page de sélection.',
      returnText: 'Retour à la sélection',
      returnMerchant: 'Retour à la page du marchand',
      cvvTips:
        "Le code CVC est situé au dos de votre carte, sur le côté droit de la bande blanche de signature ; ce sont toujours les 3 derniers chiffres pour Visa et Mastercard. Pour American Express, il s'agit de 4 chiffres situés à l'avant de la carte.",
      holderNameTips: 'Nom complet sur la carte',
      continue: 'Continuer',
      validateCVVTitle: 'Vérification de sécurité',
      validateCVVDesc:
        'Pour la sécurité de votre paiement, veuillez vérifier le CVC/CVV de votre carte',
      // 订阅产品
      subscriptionProduct: 'Produit d’abonnement',
      // 订阅费用
      subscriptionFee: 'Frais d’abonnement',
      // 更新成功
      updateSuccess: 'Mis à jour avec successe',
      // 更新失败
      updateFailed: 'Mis à jour avec echec',
      // 次
      times: 'Fois',

    },
    dlocal: {
      amount: 'somme',
      expirationDate: "Date d'expiration",
      ticketNumber: 'Numéro du billet',
      ticketImage: 'Image du billet',
      downloadTicket: 'Télécharger le ticket',
      searchForShops: 'recherche de magasins',
      fullBoletoView: 'Vue complète de Boleto',
      back: 'RETOUR',
      copySuccess: 'Instruction de paiement',
      errorMsg: "Délai d'attente dépassé. Échec de l'obtention des paramètres.",
      instructionsTitle: 'ID Instructions de paiement',
      instructionsContentPIX:
        "ID Instructions de paiement:<br/>1. ID de la transaction de réplication<br/>2. Utilisez l'ID pour identifier vos paiements PIX dans votre application bancaire ou lisez le code QR sur l'application de la banque.",
      instructionsContentRedpagos:
        "Vous devrez vous rendre dans un magasin Redpagos pour effectuer le paiement.<br/>En indiquant votre numéro de carte d'identité et en précisant que vous souhaitez payer une facture dlocale, vous pourrez effectuer le paiement.",
      instructionsContentOXXO:
        "1. Rendez-vous dans le magasin oxxo le plus proche.<br/>2. Indiquez dans la case que vous souhaitez effectuer un paiement oxxopay.<br/>3. Dicte le numéro de référence de cette carte à la caissière pour qu'elle le tape<br/>Directement sur l'écran de vente.<br/>4. Effectue le paiement correspondant en espèces.<br/>5. Lorsque vous confirmez votre paiement, le caissier vous remet un bon imprimé. Vous pouvez ainsi vérifier que le paiement a été effectué correctement. Conservez cette preuve de paiement.<br/>6. Pour plus d'informations sur votre paiement, consultez notre centre d'assistance.",
      instructionsContentSPEI:
        '1. Accréditation immédiate.<br/>2. Dès réception du virement bancaire, dlocal informe le fournisseur du produit/service acheté.<br/>3. Si vous rencontrez des problèmes de paiement, veuillez consulter notre portail de service à la clientèle.',
      instructionsContentSencillito:
        'Instruction de paiement en personne :<br/>Au siège de Sencillito, vous pouvez effectuer un paiement en fournissant un numéro de référence et en indiquant clairement que vous souhaitez payer la facture DLOCAL.<br/>Instruction de paiement en ligne :<br/>Pour effectuer le paiement, vous devez entrer dans le Portail Sencillito, choisir "dlocal" dans le moteur de recherche de l\'entreprise et saisir le code indiqué sur le bon 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: 'Coordonnées bancaires',
      bankDetailsSPEI:
        'Connectez-vous à votre banque en ligne et effectuez un virement sur le compte suivant. Veuillez noter que les paiements en espèces ne sont pas possibles.<br/>Banque de destination:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Bénéficiaire: <span class="detail-value">{beneficiary}</span><br/>Montant: <span class="detail-value">{amount} {currency}</span><br/>Concept de paiement: <span class="detail-value">{concept}</span><br/>Référence: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: 'Type de paiement',
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: 'Montant',
      orderTips: 'Veuillez transférer sur le compte suivant',
      numberTitle: 'Numéro de compte (uniquement pour cette transaction)',
      copyBtnText: 'Copier le numéro de compte',
      expireText: 'La commande expire dans {time}',
      bankName: 'Nom de la banque',
    },
    date: {
      month: {
        1: 'Janvier',
        2: 'Février',
        3: 'Mars',
        4: 'Avril',
        5: 'Peut',
        6: 'Juin',
        7: 'Juillet',
        8: 'Août',
        9: 'Septembre',
        10: 'Octobre',
        11: 'Novembre',
        12: 'Décembre',
      },
    },
    result: {
      success: 'Paiement réussi',
      failed: 'Paiement échoué',
      unknow: 'Paiements',
      unknowTip:
        'Veuillez actualiser le statut de la commande pour voir le résultat',
      jump: 'Retour au marchand dans <span class="hightlight">{time}s</span>',
      reason: 'Raison',
      processing: 'Traitement',
      timeout: 'La page a expiré, veuillez relancer le paiement',
      invalid: 'Demande de transaction invalide',
      cancelled:
        'La demande de paiement a été annulée,<br/>veuillez ne pas répéter le paiement',
      emptyPaymentMethod:
        "Aucun moyen de paiement n'est configuré pour ce pays.",
      error:
        "Erreur. Veuillez vérifier le résultat de la transaction sur la page de commande du site Web d'achat.",
    },
    validator: {
      input: "S'il vous plait, entrez un {field} valide",
      intallmentCardOrCountryNotSupport:
        "Le paiement échelonné n'est pas pris en charge par type de carte ou pays",
    },
    error: {
      timeout: 'Erreur réseau, veuillez réessayer plus tard',
      cardNumber: 'Les informations de la carte sont incorrectes',
    },
    country,
    state,
  },
  ...ElementUILocal,
};
