export default {
  AD: 'أندورا',
  AF: 'أفغانستان',
  EG: 'مصر',
  EH: 'الصحراء الغربية',
  FO: 'جزر فاروس',
  GA: 'الجابون',
  GD: 'غرينادا',
  GI: 'جبل طارق',
  GM: 'غامبيا',
  AG: 'أنتيغوا وبربودا',
  AI: 'أنغيلا',
  AL: 'ألبانيا',
  AN: 'جزر الأنتيل الهولندية',
  AO: 'أنغولا',
  AQ: 'القارة القطبية الجنوبية',
  AR: 'الأرجنتين',
  AU: 'أستراليا',
  AW: 'أروبا',
  AZ: 'أذربيجان',
  BA: 'البوسنة والهرسك',
  BB: 'بربادوس',
  BF: 'بوركينا فاسو',
  BG: 'بلغاريا',
  BH: 'البحرين',
  BI: 'بوروندي',
  BJ: 'بنين',
  BM: 'برمودا',
  BS: 'جزر البهاما',
  BV: 'جزيرة بوفيت ',
  BW: 'بوتسوانا',
  BZ: 'بليز',
  CA: 'كندا',
  CF: 'افريقيا الوسطى',
  CG: 'الكونغو',
  CI: 'كوت ديفوار',
  CK: 'جزر كوك',
  CN: 'الصين',
  CU: 'كوبا',
  CV: 'الرأس الأخضر',
  DE: 'ألمانيا',
  EC: 'الإكوادور',
  GR: 'اليونان',
  IR: 'إيران',
  IT: 'إيطاليا',
  JM: 'جامايكا',
  JO: 'الأردن',
  JP: 'اليابان',
  KE: 'كينيا',
  KM: 'جزر القمر',
  KP: 'كوريا الشمالية',
  KR: 'كوريا الجنوبية',
  LB: 'لبنان',
  LS: 'ليسوتو',
  LU: 'لوكسمبورغ',
  LV: 'لاتفيا',
  MD: 'مولدوفا',
  GU: 'غوام',
  HK: 'هونج كونج',
  HM: 'جزر هيرد وجزر ماكدونالد',
  HT: 'هايتي',
  MK: 'مقدونيا',
  ML: 'مالي',
  MN: 'منغوليا ',
  HU: 'هنغاريا',
  ID: 'إندونيسيا',
  IE: 'أيرلندا',
  IL: 'إسرائيل',
  IN: 'الهند',
  IQ: 'العراق',
  MT: 'مالطا',
  MV: 'جزر المالديف',
  MX: 'المكسيك',
  NC: 'كاليدونيا الجديدة',
  NI: 'نيكاراغوا ',
  NZ: 'نيوزيلندا',
  PE: 'بيرو',
  PL: 'بولندا',
  PT: 'البرتغال',
  PW: 'بالاو ',
  CR: 'كوستا ريكا',
  CZ: 'جمهورية التشيك',
  DZ: 'الجزائر',
  ET: 'أثيوبيا',
  FR: 'فرنسا',
  RO: 'رومانيا',
  RU: 'روسيا',
  SA: 'المملكة العربية السعودية',
  SB: 'جزر سليمان',
  SC: 'سيشل',
  SD: 'السودان',
  SE: 'السويد',
  SG: 'سنغافورة',
  SI: 'سلوفينيا',
  SJ: 'جزر سفالبارد وجان ماين',
  SK: 'سلوفاكيا',
  SL: 'سيرا ليون',
  SN: 'السنغال',
  SO: 'الصومال',
  SR: 'سورينام',
  ST: 'ساو تومي وبرينسيبي',
  SV: 'السلفادور',
  SZ: 'سوازيلاند',
  TC: 'جزر تركس وكايكوس',
  TD: 'تشاد',
  TF: 'المناطق الجنوبية لفرنسا',
  TG: 'توجو',
  TH: 'تايلاند',
  TK: 'توكيلاو',
  TM: 'تركمانستان',
  TN: 'تونس',
  TP: 'تيمور الشرقية',
  TT: 'ترينداد وتوباغو',
  UA: 'أوكرانيا',
  US: 'الولايات المتحدة الأمريكية',
  UZ: 'أوزبكستان',
  VA: 'الفاتيكان',
  VE: 'فنزويلا',
  VG: 'جزر فيرجن البريطانية',
  VU: 'فانواتو',
  YE: 'اليمن',
  YT: 'مايوت ',
  YU: 'يوغوسلافيا',
  ZA: 'جنوب أفريقيا',
  ZW: 'زيمبابوي',
  GB: 'المملكة المتحدة',
  BO: 'بوليفيا',
  BT: 'بوتان',
  BY: 'بيلاروسيا',
  CC: 'جزر كوكوس (كيلينغ)',
  CL: 'تشيلي',
  CO: 'كولومبيا',
  TW: 'تايوان , الصين',
  CY: 'قبرص',
  DK: 'الدنمارك',
  DO: 'جمهورية الدومنيكان',
  ES: 'إسبانيا',
  FI: 'فنلندا',
  FK: 'جزر مالفيناس (جزر فوكلاند)',
  GE: 'جورجيا',
  GL: 'الأرض الخضراء',
  GN: 'غينيا',
  GS: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
  KW: 'الكويت',
  KZ: 'كازاخستان',
  LT: 'ليتوانيا',
  LY: 'ليبيا',
  MH: 'جزر مارشال',
  MO: 'ماكاو',
  MU: 'موريشيوس',
  MY: 'ماليزيا',
  NA: 'ناميبيا',
  NU: 'نيوي',
  OM: 'سلطنة عمان',
  PK: 'باكستان',
  PR: 'بورتوريكو',
  AT: 'النمسا',
  BD: 'بنغلاديش',
  CH: 'سويسرا',
  CS: 'جزيرة الكريسماس',
  FM: 'ميكرونيزيا',
  IO: 'إقليم المحيط البريطاني الهندي',
  KI: 'كيريباتي',
  LC: 'القديسة لوسيا',
  MA: 'المغرب',
  MM: 'ميانمار',
  MS: 'مونتسيرات',
  NG: 'نيجيريا',
  PH: 'فيلبيني',
  RE: 'جمع شمل',
  WS: 'ساموا الغربية',
  AE: 'الإمارات العربية المتحدة',
  AS: 'ساموا الأمريكية',
  BE: 'بلجيكا',
  BN: 'بروناي دار السلام',
  CM: 'الكاميرون',
  DJ: 'جيبوتي',
  ER: 'إريتريا',
  GF: 'غيانا الفرنسية',
  GW: 'جوين بيساو',
  HR: 'كرواتيا',
  IS: 'أيسلندا',
  KH: 'كمبوديا',
  KY: 'جزر كايمان',
  LR: 'ليبيريا',
  MQ: 'مارتينيك',
  MZ: 'موزمبيق',
  PA: 'بنما',
  PN: 'مجموعة جزر بيتكيرن',
  RW: 'رواندا',
  SH: 'سانت هيلانة',
  SM: 'سان ماريون',
  SY: 'سوريا',
  TJ: 'طاجيكستان',
  TZ: 'تنزانيا',
  EE: 'إستونيا',
  LI: 'ليختنشتاين',
  NF: 'جزيرة نورفولك ',
  PY: 'باراغواي',
  GP: 'جوادلوب',
  UM: 'الولايات المتحدة منغوليا-نيوس باك',
  NP: 'نيبال',
  PM: 'سانت بيير وميكلون',
  GQ: 'غينيا الإستوائية',
  GY: 'غيانا',
  KG: 'قيرغيزستان',
  KN: 'سانت كيتس ونيفيس',
  LA: 'لاو',
  LK: 'سيريلانكا',
  MC: 'موناكو',
  MG: 'مدغشقر',
  MR: 'موريتانيا',
  MW: 'ملاوي',
  NE: 'النيجر',
  NO: 'النرويج',
  NR: 'ناورو',
  PG: 'بابوا غينيا الجديدة',
  PS: 'فلسطين',
  QA: 'دولة قطر',
  RS: 'صربيا',
  MP: 'ماريانا الشمالية',
  NL: 'هولندا',
  PF: 'بولينيزيا الفرنسية',
  AM: 'أرمينيا',
  BR: 'البرازيل',
  DM: 'دومينيكا',
  HN: 'هندوراس',
  TO: 'تونغا',
  TR: 'ديك رومى',
  TV: 'توفالو',
  UG: 'أوغندا',
  UY: 'أوروغواي',
  VC: 'سانت فنسنت وجزر غرينادين',
  VI: 'جزر العذراء الأمريكية',
  WF: 'جزر واليس وفوتونا ',
  ZM: 'زامبيا',
  FJ: 'فيجي',
  GH: 'غانا',
  GT: 'غواتيمالا',
  VN: 'فيتنام',
  ZR: 'زائير',
};
