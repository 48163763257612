<template>
  <el-select v-model="app.currentLan" @change="changeLan" @visible-change="clickLanHandler" class="lang-wrapper">
    <template slot="prefix" >
      {{ (lang.find(item => item.value === app.currentLan)|| {}).label }}
    </template>
    <el-option v-for="(item, idx) in lang" :label="item.label" :value="item.value" :key="idx"></el-option>
  </el-select>
</template>
<script>
import { mapGetters } from 'vuex';

import { loadLanguageAsync } from '@/language/index';

export default {
  inject: ['reload'],
  computed: {
    ...mapGetters(['app']),
  },
  data() {
    return {
      lang: [
        { value: 'de', label: 'Deutsch' }, // 德语
        { value: 'en', label: 'English' }, // 英语
        { value: 'es', label: 'Español' }, // 西班牙语
        { value: 'fr', label: 'Français' }, // 法语
        { value: 'it', label: 'Italiano' }, // 意大利语
        { value: 'nl', label: 'Nederlands' }, // 荷兰语
        { value: 'no', label: 'Norsk' }, // 挪威语
        { value: 'pl', label: 'Polski' }, // 波兰语
        { value: 'pt', label: 'Português' }, // 葡萄牙语
        { value: 'fi', label: 'Suomi' }, // 芬兰语
        { value: 'sv', label: 'svenska' }, // 瑞典语
        { value: 'ru', label: 'Русский язык' }, // 俄语
        { value: 'ar', label: 'العربيه' }, // 阿拉伯语
        { value: 'th', label: 'ไทย' }, // 泰语
        { value: 'zh-cn', label: '中文' }, // 中文
        { value: 'ja', label: '日本語' }, // 日语
        { value: 'zh-tw', label: '繁体中文' }, // 繁体中文
        { value: 'ko', label: '한국어' }, // 韩语
        { value: 'vi', label: 'Tiếng Việt' }, // 越南语
      ],
    };
  },
  methods: {
    changeLan(lang) {
      loadLanguageAsync(lang);
      this.$bus.$emit('lang_components', 'select_language');
    },
    clickLanHandler(evt) {
      if (evt) {
        this.$bus.$emit('lang_components', 'click_language_dropdown');
      }
    },
  },
};
</script>
<style lang="scss">
.lang-wrapper {
  &.el-select {
    // width: 120px;
    min-width: 120px;

    .el-input__prefix {
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      padding-left: 15px;
      padding-right: 60px;
      height: 44px;
      line-height: 44px;
      color: #27272B;
      left: 0;
      visibility: hidden;
      min-width: 120px;
      // display: none;
    }

    .el-input__inner {
      position: absolute;
      padding-left: 15px;
    }
  }
  input {
    font-weight: bold;
  }
}
@media screen and (max-width: 1140px) {
.lang-wrapper {
  &.el-select {
    width: 120px;
      .el-input__prefix {
        display: none;
        min-width: auto;
      }

      .el-input__inner {
        position: relative;
        padding-left: 15px;
      }
    }
    input {
      font-weight: bold;
    }
  }
}
</style>
