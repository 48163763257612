export default {
  US: {
    AL: 'Alabama',
    AK: 'Alasca',
    AS: 'Samoa Americana',
    AZ: 'Arizona',
    AR: 'Arkansas',
    AA: 'Forças Armadas das Américas',
    AE: 'Forças Armadas da Europa',
    AP: 'Forças Armadas do Pacífico',
    CA: 'Califórnia',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'Distrito da Colombia',
    FM: 'Estados Federados da Micronésia',
    FL: 'Flórida',
    GA: 'Geórgia',
    GU: 'Guam',
    HI: 'Havaí',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Luisiana',
    ME: 'Maine',
    MH: 'Ilhas Marshall',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minesota',
    MS: 'Mississipi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebrasca',
    NV: 'Nevada',
    NH: 'Nova Hampshire',
    NJ: 'Nova Jersey',
    NM: 'Novo México',
    NY: 'Nova Iorque',
    NC: 'Carolina do Norte',
    ND: 'Dakota do Norte',
    MP: 'Ilhas Marianas do Norte',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregão',
    PW: 'Palaú',
    PA: 'Pensilvânia',
    PR: 'Porto Rico',
    RI: 'Rhode Island',
    SC: 'Carolina do Sul',
    SD: 'Dakota do Sul',
    TN: 'Tennessee',
    TX: 'Texas',
    UM: 'Ilhas Menores Distantes dos Estados Unidos',
    UT: 'Utá',
    VT: 'Vermont',
    VI: 'Ilhas Virgens',
    VA: 'Virgínia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  },
  CA: {
    AB: 'Alberta',
    BC: 'Columbia Britânica',
    MB: 'Manitoba',
    NB: 'Nova Brunswick',
    NL: 'Terra Nova',
    NT: 'Territórios do Noroeste',
    NS: 'Nova Escócia',
    NU: 'Nunavut',
    ON: 'Ontário',
    PE: 'Ilha Principe Edward',
    QC: 'Quebeque',
    SK: 'Saskatchewan',
    YT: 'Yukon',
  },
  CN: {
    AH: 'Anhui',
    BJ: 'Beijing',
    CQ: 'Chongqing',
    FJ: 'Fujian',
    GS: 'Gansu',
    GD: 'Guangdong',
    GX: 'Guangxi',
    GZ: 'Guizhou',
    HI: 'Hainan',
    HE: 'Hebei',
    HL: 'Heilongjiang',
    HA: 'Henan',
    HK: 'Hong Kong SAR',
    HB: 'Hubei',
    HN: 'Hunan',
    JS: 'Jiangsu',
    JX: 'Jiangxi',
    JL: 'Jilin',
    LN: 'Liaoning',
    MO: 'Macao SAR',
    NM: 'Nei Mongol',
    NX: 'Ningxia',
    QH: 'Qinghai',
    SN: 'Shaanxi',
    SD: 'Shandong',
    SH: 'Shanghai',
    SX: 'Shanxi',
    SC: 'Sichuan',
    TW: 'Taiwan',
    TJ: 'Tianjin',
    XJ: 'Xinjiang',
    XZ: 'Xizang',
    YN: 'Yunnan',
    ZJ: 'Zhejiang',
  },
};
