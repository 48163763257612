import ElementUILocal from 'element-ui/lib/locale/lang/en';

import country from './country/en';
import state from './state/en';
// us
export default {
  message: {
    title: 'Checkout',
    powerByOnerway: 'Powered by <span class="ic-powerby"></span>',
    checkout: {
      timeTip:
        'Please complete the payment within <span class="time">{time}</span> minutes,Otherwise the page will time out.',
      billingAddressTip:
        'The charge will appear on your bill as {billingAddress}.',
      cardInfo: 'Card Information',
      cardNumber: 'Card number',
      prepaidNumber: 'Card number',
      summary: 'Order Summary',
      expiredDate: 'Expiry date',
      issueDate: 'Issue date',
      cvv: 'CVC/CVV',
      firstName: 'First name',
      lastName: 'Last name',
      holderName: 'Cardholder name',
      walletAccountName: 'Name',
      orderno: 'Order ID',
      website: 'Website',
      amount: 'Amount',
      pay: 'Pay',
      payNow: 'Pay Now',
      update: 'Update',
      deduction: 'Charge Amount',
      nextDeductionTime: 'Next Charge Date',
      subscriptionEndTime: 'Subscription End Date',
      frequency: 'Every',
      week: 'Week',
      day: 'Day',
      month: 'Month',
      year: 'Year',
      cancel: 'Cancel',
      tip: 'Tips',
      cancelPayTip: 'Do you want to give up this payment?',
      yes: 'Yes',
      no: 'No',
      subscription: 'Subscription',
      schedule: 'Payment Schedule',
      endTime: 'End Date',
      subscriptionTip:
        'By confirming your payment, you allow merchant to charge your card for future payments in accordance with their terms & conditions.',
      frequencyData: {
        D: 'Once per {time} days',
      },
      endTimeData: '{month} {day}, {year}',
      billingAddress: 'Billing Address/Contact information',
      country: 'Country',
      province: 'State/Province/Region',
      address: 'Address',
      city: 'Town/City',
      postalCode: 'ZIP/Postal Code',
      email: 'Email',
      phone: 'Phone number',
      street: 'Street Number',
      number: 'Number',
      identityNumber: 'Document',
      intallment: 'Intallments',
      intallmentPlan: 'Installment Plan',
      paymentMethod: 'Payment Method',
      bankName: 'Bank Name',
      iBan: 'IBAN',
      contactName: 'Contact name',
      birthDate: 'Birth date',
      paymentCompleted: 'Payment completed',
      wechatPaymentTip: 'Please use WeChat to scan the QR code to pay',
      paymentPending: 'Wait for payment',
      billingCountry: 'Billing country',
      billingCountryTip:
        'Please select the billing country first, so that we can recommend a suitable payment method for you.',
      nextStep: 'Next step',
      changeCountryTip:
        'After switching countries, we will recommend suitable payment methods to you.',
      scanCode: 'Scan it',
      txnTime: 'Transaction time',
      moreInfo: 'More information',
      applePayNotSupport: 'This device does not support Apple Pay.',
      policy: {
        prefix: 'I have read and agree to the ',
        suffix: '',
      },
      privacy: 'Privacy Policy',
      intallmentTip: 'You may have to pay interest on the installments.',
      payInFull: 'Pay in Full',
      payIn: 'Pay in {count}',
      blikcode: 'BLIK code',
      unavailable: 'Unavailable',
      notSupportPaymentTips:
        'The payment method is not available at the moment. Please return to the selection page.',
      returnText: 'Return to selection',
      returnMerchant: 'Return to Merchant Page',
      cvvTips:
        'The CVC code is located on the back of your card on the right side of the white signature strip; it is always the last 3 digits for Visa and Mastercard. For American Express, it is 4 digits on the front of the card',
      holderNameTips: 'Full name on card',
      continue: 'Continue',
      validateCVVTitle: 'Security Verification',
      validateCVVDesc:
        'For your payment security, please verify your card’s CVC/CVV',
      // 订阅产品
      subscriptionProduct: 'Subscription Product',
      // 订阅费用
      subscriptionFee: 'Subscription Fee',
      // 更新成功
      updateSuccess: 'Update Success',
      // 更新失败
      updateFailed: 'Update Failed',
      // 次
      times: 'times',
    },
    dlocal: {
      amount: 'Amount',
      expirationDate: 'Expiration date',
      ticketNumber: 'Ticket number',
      ticketImage: 'Ticket image',
      downloadTicket: 'Download ticket',
      searchForShops: 'Search for shops',
      fullBoletoView: 'Full Boleto View',
      back: 'BACK',
      copySuccess: 'Copy',
      errorMsg: 'Timeout. Fail to get parameters.',
      instructionsTitle: 'Payment instruction',
      instructionsContentPIX:
        "ID Payment instructions:<br/>1. Replication transaction ID<br/>2. Use the ID to identify your PIX payments in your banking app or read the QR code on the bank's app.",
      instructionsContentRedpagos:
        'You will need to go to a redpagos store to make the payment.<br/>By saying your id card number and clarifying that you want to pay a dlocal bill you will be able to make the payment.',
      instructionsContentOXXO:
        '1. Go to the nearest oxxo store.<br/>2. Indicate in the box that you want to make an oxxopay payment..<br/>3. Dictates the reference number on this card to the cashier for typing.<br/>Directly on the sales screen..<br/>4. Make the corresponding payment with cash..<br/>5. When you confirm your payment, the cashier will give you a printed voucher. Here you can verify that it has been done correctly. Keep this proof of payment..<br/>6. For more information about your payment visit our help center',
      instructionsContentSPEI:
        '1. Immediate accreditation.<br/>2. Once the bank transfer has been received, dlocal will inform the deliver the product/service you are purchasing.<br/>3. If you have any payment problems, please visit our customer service portal.',
      instructionsContentSencillito:
        'Payment instruction in person:<br/>At Sencillito\'s place of business, you can make a payment by providing a reference number and making it clear that you want to pay the DLOCAL invoice.<br/>Online payment instruction:<br/>To make the payment you must enter Portal Sencillito, choose "dlocal" in the company search engine and enter the code indicated on the voucher 134216.',
      instructionsContentServipag:
        'Primero díctale al cajero el código de servicio:<br/>Si es YoPag: "company_id"<br/>Si es por caja:"company_id2"',
      bankDetailsTitle: 'Bank details',
      bankDetailsSPEI:
        'Log in to your online banking and transfer to the next account. Please note that cash payments are not possible.<br/>Destination bank:<span class="detail-value">{bank}</span><br/>CLABE: <span class="detail-value">{clabe}</span><br/>Beneficiary: <span class="detail-value">{beneficiary}</span><br/>Amount: <span class="detail-value">{amount} {currency}</span><br/>Payment concept: <span class="detail-value">{concept}</span><br/>Reference: <span class="detail-value">{reference}</span>',
      paymentTypeTitle: 'Type of payment',
      paymentTypeServipag:
        'Por favor, indique al cajero el código de servicio<br/>Si es Empresa_1: <span class="company">{company1}</span><br/>Si se Empresa_2: <span class="company">{company2}</span>',
    },
    palmpay: {
      amount: 'Amount',
      orderTips: 'Please transfer to the following account',
      numberTitle: 'Account Number(Only for this transaction)',
      copyBtnText: '',
      expireText: 'order expires in {time}',
      bankName: 'Bank Name',
    },
    date: {
      month: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
    },
    result: {
      success: 'Payment successful',
      failed: 'Payment failed',
      unknow: 'Paying',
      unknowTip: 'Please refresh the order status to see the result',
      jump: 'Returning to the merchant in <span class="hightlight">{time}s</span>',
      reason: 'Reason',
      processing: 'Processing',
      timeout: 'The page timed out, please re-initiate payment',
      invalid: 'Invalid transaction request',
      cancelled:
        'The payment application has been cancelled,<br/>please do not repeat the payment',
      emptyPaymentMethod:
        'There are no payment methods configured for this country.',
      error:
        'Error. Please check the transaction result on the order page of shopping website.',
    },
    validator: {
      input: 'Please enter a valid {field}',
      intallmentCardOrCountryNotSupport:
        'Installment payment is not supported by card type or country!',
    },
    error: {
      timeout: 'Network error, please try again later',
      cardNumber: 'Card information is incorrect',
    },
    country,
    state,
  },
  ...ElementUILocal,
};
